import React from 'react';
import propTypes from 'prop-types';
import CommissionsView from './CommissionsView/CommissionsView';
import { useParams } from 'react-router-dom';
import BonusView from './BonusView/BonusView';
import StatementsView from './StatementsView/StatementsView';
import OldStatementsView from './StatementsView/OldStatementsView';
import BookOfBusiness from './BookOfBusiness/BookOfBusiness';

function Commissions() {
  const { type } = useParams();
  // useEffect(() => {
  //   getAppointmentsViewData(setLocalLoader);
  // }, []);
  if (type === 'Commissions')
    return (
      <>
        <CommissionsView />
      </>
    );
  if (type === 'Statements')
    return (
      <>
        <StatementsView />
      </>
    );
  if (type === 'BookOfBusiness')
    return (
      <>
        <BookOfBusiness />
      </>
    );
  if (type === 'OldStatements')
    return (
      <>
        <OldStatementsView />
      </>
    );
  return (
    <>
      <BonusView />
    </>
  );
}

Commissions.propTypes = {
  carriers: propTypes.object,
  appointedCarriers: propTypes.object,
  getAppointmentsViewData: propTypes.func,
  setLocalLoader: propTypes.func,
  commissions: propTypes.object
};

export default Commissions;
