import { getInProgressStates } from 'helpers/appointments/appointments';
import { arrayDifference, ArrayToObject } from 'helpers/arrays';
import dateFactory from './dateFactory';

export function appointmentsObjectFactory(
  carriersObj,
  Requested_Appointments,
  zohoData
) {
  //Marketplace carriers array
  let marketplaceCarriers = Object.keys(carriersObj.Marketplace);
  let offMarketCarriers = Object.keys(carriersObj['Off-Market Health']);
  let lifeCarriers = Object.keys(carriersObj.Life);
  let medicareCarriers = Object.keys(carriersObj.Medicare);
  let groupCarriers = Object.keys(carriersObj.Group);

  //Adds the "In Progress" array to each requested appointment
  let requestedAppointments = { ...Requested_Appointments };
  let inProgressArray = [];
  let type = '';

  Object.values(requestedAppointments).map(requestedAppointment => {
    inProgressArray = [];

    let exclude = true;
    //Discard the non-state carriers
    if (marketplaceCarriers.includes(requestedAppointment.carrier)) {
      inProgressArray = getInProgressStates(requestedAppointment);
      //Removes all duplicated values
      inProgressArray = [...new Set(inProgressArray)];

      //Discards the currently approved states
      inProgressArray = arrayDifference(
        inProgressArray,
        zohoData[requestedAppointment.carrier.split(' ').join('_')]
      );
      type = 'Marketplace';
      exclude = carriersObj.Marketplace[requestedAppointment.carrier].exclude;
    } else if (offMarketCarriers.includes(requestedAppointment.carrier)) {
      type = 'Off-Market Health';
      exclude =
        carriersObj['Off-Market Health'][requestedAppointment.carrier].exclude;
    } else if (medicareCarriers.includes(requestedAppointment.carrier)) {
      type = 'Medicare';
      exclude = carriersObj.Medicare[requestedAppointment.carrier].exclude;
    } else if (lifeCarriers.includes(requestedAppointment.carrier)) {
      type = 'Life';
      carriersObj.Life[requestedAppointment.carrier].exclude;
    } else if (groupCarriers.includes(requestedAppointment.carrier)) {
      type = 'Group';
      carriersObj.Group[requestedAppointment.carrier].exclude;
    }

    //Adds the "In Progress" array & other fields to the current requested appointment
    requestedAppointments[requestedAppointment.carrier] = {
      ...requestedAppointments[requestedAppointment.carrier],
      created_time: dateFactory(
        requestedAppointments[requestedAppointment.carrier].created_time
      ),
      in_progress_date: requestedAppointments[requestedAppointment.carrier]
        .in_progress_date
        ? dateFactory(
            requestedAppointments[requestedAppointment.carrier].in_progress_date
          )
        : null,
      inProgressArray,
      type,
      name: requestedAppointment.carrier,
      exclude
    };
  });
  // requestedAppointments = Object.values(requestedAppointments).filter(
  //   requestedAppointment => !requestedAppointment.exclude
  // );
  return requestedAppointments;
}

export const mergeRepeatedCarriers = reqArr => {
  let checkedCarriers = [];
  let requests = [...reqArr];
  let newRequests = [];

  //Filters RA by Status
  requests = requests.filter(
    reqAppointment =>
      reqAppointment.appointment_status.includes('In Progress') ||
      reqAppointment.appointment_status === 'Not Started' ||
      reqAppointment.appointment_status.includes('OI')
  );
  if (requests.length > 0) {
    //Goes through the RA filtered by status
    for (let i = 0; i < reqArr.length; i++) {
      let currentRA;
      /*
    Verifies that is possible to have duplicated carriers
    by checking the length of the array
    */
      if (requests.length > 1) currentRA = requests.shift();
      /*
    If it's only 1 element and the carrier of the RA hasn't, 
    been checked, it adds it to the new RA's array and exits.
    */ else {
        currentRA = {};
        let lastCarrierName = requests[0].carrier;
        if (!checkedCarriers.includes(lastCarrierName))
          newRequests.push(requests[0]);
        break;
      }

      if (!checkedCarriers.includes(currentRA?.carrier)) {
        let requestsInfo = [];
        //Gets all the RA which have the same carrier as the current one
        let repeatedCarriers = requests.filter(
          request => request.carrier === currentRA.carrier
        );
        //Merges the RA info arrays from the repeated carriers
        repeatedCarriers.map(repeatedCarrier => {
          requestsInfo = [
            ...requestsInfo,
            ...repeatedCarrier.appointments_information
          ];
        });
        /*
      Substitutes the current RA info array by the 
      by the array which contains the info of all the RA with that same
      carrier
      */
        currentRA.appointments_information = [
          ...currentRA.appointments_information,
          ...requestsInfo
        ];
        //Adds in the modified RA into the RA's array
        newRequests.push(currentRA);
        //Adds the carrier name of the current RA to avoid double checking
        checkedCarriers.push(currentRA.carrier);
      }
    }
    return ArrayToObject(newRequests, 'carrier');
  } else return {};
};
