import React from 'react';

function Commissions() {
  return (
    <svg viewBox='0 0 16 15.998'>
      <g transform='translate(-3.996 -4)'>
        <path
          d='M13.2,20h-.28l-2.8-.6a1.24,1.24,0,0,1-.92-1.2v-5L4.4,8.719A1.04,1.04,0,0,1,4,7.84V5.2A1.2,1.2,0,0,1,5.2,4H18.8A1.2,1.2,0,0,1,20,5.2v3.04a1.12,1.12,0,0,1-.44.92L14.4,13.239V18.8A1.217,1.217,0,0,1,13.2,20Zm-2.4-2.16,2,.48V12.479l5.6-4.439V5.6H5.6V7.68l5.2,4.839Z'
          transform='translate(0)'
          fill='#9d9d9d'
        />
      </g>
    </svg>
  );
}

export default Commissions;
