import React from 'react';
import SecondaryPage from 'components/SecondaryPage/SecondaryPage';
import style from './registration.module.scss';
import LoginFormContainer from 'components/LoginFormContainer/LoginFormContainer';
import RegistrationForm from './RegistrationForm/RegistrationForm';

function Registration() {
  return (
    <SecondaryPage>
      <LoginFormContainer>
        <div className={style.text}>
          <h1>Want to Register?</h1>
          <p>Leave your information and we&apos;ll get back to you shortly</p>
        </div>
        <RegistrationForm />
      </LoginFormContainer>
    </SecondaryPage>
  );
}

export default Registration;
