export default function (impDate) {
  console.log(impDate);
  return {
    id: impDate.id,
    title: impDate.Name,
    subheader: impDate.Subheader_Text,
    textURl: impDate.URL,
    endDate: impDate.End_Date
  };
}
