import React, { useEffect, useRef } from 'react';
import propTypes from 'prop-types';

function useOutsideAlerter(ref, onClick) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClick();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

export default function OutsideAlerter({ children, onClick, className = '' }) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, onClick);

  return (
    <div ref={wrapperRef} className={className}>
      {children}
    </div>
  );
}

OutsideAlerter.propTypes = {
  children: propTypes.element,
  onClick: propTypes.func,
  className: propTypes.string
};
