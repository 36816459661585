import { CLEAN_NOTIFICATIONS, GET_NOTIFICATIONS } from 'constants/types';

const INITIAL_STATE = {
  notifications: {}
};

function leadsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload
      };
    case CLEAN_NOTIFICATIONS:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export default leadsReducer;
