import React from 'react';
import style from './confirmationForm.module.scss';
import Button from 'components/Button/Button';
import { buttonStyling } from 'constants/enum';
import propTypes from 'prop-types';

function ConfirmationForm({ onConfirmed, onClose }) {
  return (
    <div className={style.confirmationForm}>
      <p>
        You&apos;re about to close a Lead. This action cannot be undone and it
        will impact your metrics.
      </p>
      <div>
        <Button text='Close' onClick={onConfirmed} />
        <Button
          text='Cancel'
          outlined
          className={`${buttonStyling.Closed} ${buttonStyling.hover}`}
          onClick={onClose}
        />
      </div>
    </div>
  );
}

ConfirmationForm.propTypes = {
  onConfirmed: propTypes.func,
  onClose: propTypes.func
};

export default ConfirmationForm;
