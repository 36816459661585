import React from 'react';

function Files() {
  return (
    <svg viewBox='0 0 16.09 13.165'>
      <g transform='translate(-2 -6)'>
        <path
          d='M8.326,7.463l1.792,1.755.4.439h6.107V17.7H3.463V7.463H8.326M8.948,6H2.731A.731.731,0,0,0,2,6.731v11.7a.731.731,0,0,0,.731.731H17.359a.731.731,0,0,0,.731-.731V8.926a.731.731,0,0,0-.731-.731H11.142Z'
          fill='#9d9d9d'
        />
      </g>
    </svg>
  );
}

export default Files;
