import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideAlertModal } from 'state/actions/actions';
import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import { Stack } from '@mui/material';
import sanitizeHtml from 'sanitize-html';

function AlertModal() {
  const alert = useSelector(state => state.site.alertModal);
  console.log('------------------------');
  console.log(alert);
  console.log('------------------------');
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(hideAlertModal());
  };

  const sanitize = dirty => ({
    __html: sanitizeHtml(dirty)
  });

  return (
    <Modal
      alert
      active={alert.active}
      title={alert.title}
      size={alert.size}
      footer={
        <Button
          fill
          type='primaryLight'
          text={alert.buttonText}
          onClick={handleCloseModal}
        />
      }
      closeModal={handleCloseModal}
    >
      <Stack sx={{ whiteSpace: 'break-spaces' }}>
        <div
          dangerouslySetInnerHTML={sanitize(
            alert.message?.replace(
              /(https?:\/\/[^\s]+)/g,
              '<a href="$1" target="_blank" >$1</a>'
            )
          )}
        ></div>
      </Stack>
    </Modal>
  );
}

export default AlertModal;
