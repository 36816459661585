import React from 'react';
import style from './loginFormContainer.module.scss';
import styleSunshine from './loginFormContainerSunshine.module.scss';
import propTypes from 'prop-types';
import images from 'constants/images';
import companyName from 'constants/companyNames';

function LoginFormContainer({ children }) {
  let styleSelected = '';
  switch (companyName.short) {
    case 'O\'Neill':
      styleSelected = style;
      break;
    case 'Sunshine':
      styleSelected = styleSunshine;
      break;
  }
  return (
    <div className={styleSelected.login}>
      <div className={styleSelected.formContainer}>
        <img src={images.brand.fullLogo} alt={companyName.short + ' logo'} />
        {children}
      </div>
    </div>
  );
}

LoginFormContainer.propTypes = {
  children: propTypes.node
};

export default LoginFormContainer;
