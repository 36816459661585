import React from 'react';
import Button from 'components/Button/Button';
import propTypes from 'prop-types';
import style from './actionItemsFormModal.module.scss';
import { buttonStyling } from 'constants/enum';
import Link from 'components/svg/icons/Link';
import { useSelector, useDispatch } from 'react-redux';
import { updateActionItem } from 'state/actions/actions';
import Modal from 'components/Modal/Modal';

function ActionItemsFormModal({ actionItemId, active, setModalState }) {
  const dispatch = useDispatch();
  const actionItem = useSelector(
    state => state.dashboard.actionItems[actionItemId]
  );

  const onSubmit = e => {
    e.preventDefault();
    let status;
    actionItem.confirmedBy === 'Self'
      ? (status = 'Closed')
      : (status = 'In Review');
    dispatch(updateActionItem(handleCloseModal, { ...actionItem, status }));
  };

  const handleCloseModal = () => {
    setModalState({ id: actionItemId, active: false });
  };

  return (
    <Modal
      active={active}
      size={'small'}
      closeModal={handleCloseModal}
      className={style.modalActionItems}
      title={actionItem.title}
      footer={
        <>
          {actionItem.status === 'Open' && (
            <Button text='Submit' onClick={onSubmit} type='primaryLight' />
          )}
          <Button
            text={actionItem.status === 'Open' ? 'Cancel' : 'Close'}
            type='default'
            outlined
            onClick={handleCloseModal}
          />
        </>
      }
    >
      <div className={style.modalForm}>
        <div className={style.dates}>
          <span>
            <p className={style.fieldName}>Created Date</p>
            {actionItem.created}
          </span>
          {actionItem.due && (
            <span>
              <p className={style.fieldName}>Due Date</p>
              {actionItem.due}
            </span>
          )}
        </div>
        <p className={style.fieldName}>Status</p>
        <div className={style.status}>
          <Button
            size='small'
            rounded={'large'}
            text={actionItem.status}
            className={buttonStyling[actionItem.status]}
            outlined
          />
          <Button
            size='small'
            rounded={'large'}
            text={actionItem.confirmedBy}
            className={buttonStyling[actionItem.confirmedBy]}
          />
        </div>
        <p className={style.fieldName}>Description</p>
        <p className={style.description}>{actionItem.description}</p>
        {actionItem.externalLink && (
          <a
            className={style.externalLink}
            href={`//${actionItem.externalLink}`}
            rel='noreferrer noopener'
            target='_blank'
          >
            <p className={style.fieldName}>External Link</p>
            <Link />
          </a>
        )}
      </div>
    </Modal>
  );
}

ActionItemsFormModal.propTypes = {
  actionItemId: propTypes.string,
  active: propTypes.bool,
  setModalState: propTypes.func,
  closeModal: propTypes.func
};

export default ActionItemsFormModal;
