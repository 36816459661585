import endpoints from 'constants/endpoints';
import { GET_DASHBOARD_DATA,UPDATE_LOADING_CARD_STATE } from 'constants/types';
import { ArrayToObject } from 'helpers/arrays';
import actionItemFactory from 'helpers/factory/actionItemFactory';
import announcementFactory from 'helpers/factory/announcementFactory';
import noteFactory from 'helpers/factory/noteFactory';
import { safePostCall } from 'helpers/firebaseSetup';
import { getAppointmentsViewData } from './appointments';
import { siteLoaded } from './site';
import importantDatesFactory from 'helpers/factory/importantDatesFactory';
import ownerFactory from 'helpers/factory/ownerFactory';

export const getDashboardData =
  (setLocalLoader, cancelTokenSource = {}) =>
  async (dispatch, getState) => {
    var zohoId = getState().user.zohoId;
    if (
      !getState()?.dashboard.announcements ||
      !getState()?.dashboard.notes ||
      !getState()?.dashboard.actionItems ||
      !getState()?.dashboard.importantDates ||
      !getState()?.dashboard.owner ||
      !getState()?.appointments.carriers.Marketplace.length
    ) {
      dispatch({
        type: UPDATE_LOADING_CARD_STATE,
        payload: true
      });
      setLocalLoader(true);
      safePostCall(
        endpoints.awsProxy.safePost,
        {
          zohoId,
          endpoint: endpoints.zoho.getDashboardData
        },
        {
          cancelToken: cancelTokenSource.token
        }
      )
        .then(res => {
          if (res) {
            let response = JSON.parse(res.data.details.output);
            //Formats the action Items an makes an array with them
            /*let actionItemsArray = response.actionItems.map(actionItem =>
                actionItemFactory(actionItem)
              ),
              //Creates an object with the action Items with the action item id as the key for easy access
              actionItemsObj = ArrayToObject(actionItemsArray);*/

            let notesArray = response.notes.map(note => noteFactory(note)),
              notesObj = ArrayToObject(notesArray);

            let impDatesArray = response.importantDates.map(impDate => importantDatesFactory(impDate));
       
            let ownerArray = response.owner.map(owner => ownerFactory(owner));
            let ownerObj = ownerArray[0]; 
            dispatch({
              type: GET_DASHBOARD_DATA,
              payload: {
                announcements: response.announcements.map(announcement =>
                  announcementFactory(announcement)
                ),
                notes: notesObj,
                importantDates: impDatesArray,
                owner:ownerObj
              }
            });
            dispatch(siteLoaded());
            //Gets the active appointments
            dispatch(
              getAppointmentsViewData(setLocalLoader, cancelTokenSource,false)
            );
          }
        })
        .catch(error => console.log(error));
    } else {
      dispatch(siteLoaded());
      setLocalLoader(false);
    }
  };