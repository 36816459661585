import React from 'react';
import propTypes from 'prop-types';
import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import style from './StateBasedExchangeRequestedModal.module.scss';
import { STATE_LIST_CERT } from 'constants/enum';
import sanitizeHtml from 'sanitize-html';

const StateBasedExchangeRequestModals = ({
  appointments,
  openConfirmationModal,
  openInfoModal,
  setOpenConfirmationModal,
  setOpenInfoModal,
  onSendRequest,
  onSendNotRequiredStates
}) => {
  const nonRequiredStates = appointments.filter(state => {
    return !STATE_LIST_CERT.some(state_cert => state_cert.state === state);
  });

  const sanitize = dirty => ({
    __html: sanitizeHtml(dirty)
  });

  return (
    <>
      <Modal
        active={openConfirmationModal}
        title={'State Based Exchange Requests'}
        size={'large'}
        closeModal={() => setOpenConfirmationModal(false)}
        footer={
          <div className={style.btnOptions}>
            <Button
              text={'Yes, I am certified for all states listed above'}
              type='primaryLight'
              outlined
              fill={true}
              rounded={'large'}
              onClick={() => onSendRequest(appointments)}
            />
            <Button
              text={'No, please tell me how to get certified'}
              type='danger'
              outlined
              fill={true}
              rounded={'large'}
              onClick={() => setOpenInfoModal(true)}
            />
            <Button
              text={'Cancel and adjust state requests'}
              type='default'
              outlined
              fill={true}
              rounded={'large'}
              onClick={() => setOpenConfirmationModal(false)}
            />
          </div>
        }
      >
        <>
          <p className={style.labelImportant}>
            Are you certified? You must be certified with the state exchange in
            order for an appointment to be processed.
          </p>
          <p>Requested states requiring certification:</p>
          {STATE_LIST_CERT.map((state, index) =>
            appointments.includes(state.state) ? (
              <div key={`state_${index}`}>{`- ${state.state}`}</div>
            ) : (
              ''
            )
          )}
        </>
      </Modal>
      {/* Modal instructions */}
      <Modal
        active={openInfoModal}
        title={'State Based Exchange Requests Instructions:'}
        size={'large'}
        closeModal={() => setOpenInfoModal(false)}
      >
        {STATE_LIST_CERT.map(
          (state, index) =>
            appointments.includes(state.state) && (
              <div
                key={`state_instruction_${index}`}
                className={style.stateInstruction}
              >
                <div className={style.labelImportant}>{`${state.state}:`}</div>
                {state.instructions.map((instruction, index_instruction) => (
                  <div
                    key={`instruction_${index_instruction}`}
                    dangerouslySetInnerHTML={sanitize(instruction)}
                  />
                ))}
              </div>
            )
        )}

        <hr />

        <div className={style.btnOptions}>
          {nonRequiredStates.length > 0 && (
            <>
              <span className={style.labelImportant}>
                Do you want to send the request for only the non-state based
                exchange requests?
              </span>
              {nonRequiredStates.map((state, index) => (
                <div key={`state_instruction_${index}`}>
                  <div className={style.labelImportant}>{`- ${state}`}</div>
                </div>
              ))}

              <p>
                <b>
                  ***Note: Once approved for each state, you must come back to
                  the portal and request the appointments for that state.
                </b>
              </p>
              <Button
                text={'Yes, send request for non-state based exchange requests'}
                type='primaryLight'
                outlined
                fill={true}
                rounded={'large'}
                onClick={() => onSendNotRequiredStates(nonRequiredStates)}
              />
            </>
          )}

          <Button
            text={'Cancel and complete the certification first'}
            type='default'
            outlined
            fill={true}
            rounded={'large'}
            onClick={() => setOpenInfoModal(false)}
          />
        </div>
      </Modal>
    </>
  );
};

StateBasedExchangeRequestModals.propTypes = {
  appointments: propTypes.array,
  openConfirmationModal: propTypes.bool,
  openInfoModal: propTypes.bool,
  setOpenConfirmationModal: propTypes.func,
  setOpenInfoModal: propTypes.func,
  onSendRequest: propTypes.func,
  onSendNotRequiredStates: propTypes.func
};

export default StateBasedExchangeRequestModals;
