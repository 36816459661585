import axios from 'axios';
// import firebase from 'firebase/app';
import firebase from 'firebase/compat/app';
import { LogOut } from 'state/actions/actions';
import store from 'state/store/store';
import endpoints from 'constants/endpoints';
import allcompanyconfig from './../companyconfig.json';

function createFirebaseApp() {
  const firebaseConfig = allcompanyconfig.firebaseConfig;
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  } else {
    // if already initialized, use that one
    firebase.app();
  }
}

export default createFirebaseApp;

export function safePostCall(endpoint = '', data = {}, config = {}) {
  let user = firebase.auth().currentUser;
  if (user) {
    return user
      .getIdToken(true)
      .then(idToken => {
        return axios.post(endpoint, data, {
          ...config,
          headers: {
            ...config?.headers,
            authorization: `auth ${idToken}`,
            token: `auth ${idToken}`
          }
        });
      })
      .catch(error => {
        console.log(error);
        return null;
      });
  } else {
    store.dispatch(LogOut());
    return Promise.resolve({
      data: {
        details: {
          output: ''
        }
      }
    });
  }
}

export function postApiCall(endpoint = '', data = {}, config = {}) {
  return axios.post(endpoint, data, {
    ...config,
    headers: {
      ...config?.headers
    }
  });
}

export function safeAWSCallGet(route = '', parameters = {}, testing = false) {
  let user = firebase.auth().currentUser;
  if (user) {
    if (testing) {
      const objToUrlParams = someObj =>
        `?${Object.keys(someObj)
          .map(key => `${key}=${someObj[key]}`)
          .join('&')}`;
      return user
        .getIdToken(true)
        .then(idToken => {
          return axios({
            method: 'GET',
            url: `http://localhost:3000${route}${objToUrlParams(parameters)}`,
            data: parameters,
            headers: {
              authorization: `auth ${idToken}`
            }
          });
        })
        .catch(error => {
          console.log(error);
          return null;
        });
    }
    return user
      .getIdToken(true)
      .then(idToken => {
        const headers = {};
        headers[idToken ? 'authorization' : 'api_key'] = idToken
          ? `token ${idToken}`
          : api_key;
        const paramsForUrl = `?${Object.keys(parameters)
          .map(paramKey => `${paramKey}=${parameters[paramKey]}`)
          .join('&')}`; 
        return axios({
          method: 'GET',
          url: `${allcompanyconfig.firebaseConfig.awsApiBaseUrl}${route}${paramsForUrl}`,
          headers: headers
        });
      })
      .catch(error => {
        console.log(error);
        return null;
      });
  } else {
    store.dispatch(LogOut());
    return Promise.resolve({
      data: {
        details: {
          output: ''
        }
      }
    });
  }
}

export function safeAWSCallPost(route = '', parameters = {}, testing = false) {
  let user = firebase.auth().currentUser;
  if (user) {
    if (testing) {
      return user
        .getIdToken(true)
        .then(idToken => {
          return axios({
            method: 'POST',
            url: `http://localhost:3000${route}`,
            data: parameters,
            headers: {
              authorization: `auth ${idToken}`
            }
          });
        })
        .catch(error => {
          console.log(error);
          return null;
        });
    }
    return user
      .getIdToken(true)
      .then(idToken => {
        const headers = {};
        headers[idToken ? 'authorization' : 'api_key'] = idToken
          ? `token ${idToken}`
          : api_key;
        return axios({
          method: 'POST',
          url: `${allcompanyconfig.firebaseConfig.awsApiBaseUrl}${route}`,
          data: parameters,
          headers: headers
        });
      })
      .catch(error => {
        console.log(error);
        return null;
      });
  } else {
    store.dispatch(LogOut());
    return Promise.resolve({
      data: {
        details: {
          output: ''
        }
      }
    });
  }
}