export function thousandsSeparator(numberString) {
  return numberString.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function stringTrimmer(string, length = 15) {
  if (string.length >= length) {
    let formattedString = string.substring(0, length - 2);
    return `${formattedString}...`;
  } else return string;
}

export const removeBooleanStrings = inputObj => {
  // If an object has some boolean values as strings
  Object.keys(inputObj).forEach(k => {
    if (typeof inputObj[k] !== 'string') return;
    if (String(inputObj[k]).toLowerCase() === 'true') inputObj[k] = true;
    if (String(inputObj[k]).toLowerCase() === 'false') inputObj[k] = false;
  });
};
