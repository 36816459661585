import React, { useState } from 'react';
import Input from 'components/Input/Input';
import style from './registrationForm.module.scss';
import Button from 'components/Button/Button';
import RegistrationFormValidator from './RegistrationFormValidator';
import { useDispatch, useSelector } from 'react-redux';
import { askForAUserRegistration } from 'state/actions/actions';
import { useNavigate } from 'react-router-dom';

function RegistrationForm() {
  // Redux
  const isSendingInformation = useSelector(
    state => state.user.isSendingInformation
  );
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    errors: {
      name: '',
      email: ''
    }
  });

  const onInputChange = e => {
    let target = e.target;
    setFormState({
      ...formState,
      [target.name]: target.value,
      errors: { ...formState.errors, [target.name]: '' }
    });
  };

  const onSubmit = e => {
    e.preventDefault();
    const { errors, isValid } = RegistrationFormValidator(formState);
    if (!isValid) setFormState({ ...formState, errors });
    else {
      dispatch(
        askForAUserRegistration(formState.email, formState.name, navigate)
      );
    }
  };

  return (
    <form className={style.genericForm}>
      <Input
        label='Name'
        placeholder='Enter your name'
        name='name'
        type='text'
        onChange={onInputChange}
        value={formState.name}
        error={formState.errors.name}
      />
      <Input
        label='Email'
        placeholder='Enter your email'
        name='email'
        type='email'
        onChange={onInputChange}
        value={formState.email}
        error={formState.errors.email}
      />
      <Button
        isLoading={isSendingInformation}
        disabled={isSendingInformation}
        text='Send information'
        rounded={'large'}
        type='primaryLight'
        fill
        onClick={onSubmit}
      />
    </form>
  );
}

export default RegistrationForm;
