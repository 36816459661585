import React from 'react';
import style from './carrierCard.module.scss';
import propTypes from 'prop-types';
import { stringTrimmer } from 'helpers/strings';

function CarrierCard({ name, type, active, setActive = () => {} }) {
  return (
    <div
      className={`${style.carrierCard} ${
        active === name ? style.active : null
      }`}
      onClick={() => setActive({ name, type })}
    >
      <span>{stringTrimmer(name, 17)}</span>
    </div>
  );
}

CarrierCard.propTypes = {
  name: propTypes.string,
  active: propTypes.string,
  type: propTypes.string,
  setActive: propTypes.func
};

export default CarrierCard;
