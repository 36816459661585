import withLocalLoader from 'components/HOCs/withLocalLoader';
import { connect } from 'react-redux';
import CarrierResources from './CarrierResources';
import { getAppointmentsViewData } from 'state/actions/actions';
import { getCarrierFaqs } from 'state/actions/faqs';

const mapStateToProps = ({ appointments, user, faqs}) => ({
  user: user.zohoData,
  carriers: appointments.carriers,
  appointedCarriers: appointments.appointedCarriers,
  carrierFaqs: faqs.carrierFaqsArray,
  carrierLoader:faqs.carrierLoader
  
});

export default connect(mapStateToProps, { getAppointmentsViewData,getCarrierFaqs })(
  withLocalLoader(CarrierResources, true, true, 'Carrier Resources')
);
