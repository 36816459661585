import React from 'react';

function Videos() {
  return (
    <svg viewBox='0 0 16 10.909'>
      <g transform='translate(-2 -9)'>
        <path
          d='M17.709,9l-.218.073-3.855,2.436V9.727A.727.727,0,0,0,12.909,9H2.727A.727.727,0,0,0,2,9.727v9.455a.727.727,0,0,0,.727.727H12.909a.727.727,0,0,0,.727-.727V17.4l3.855,2.436.218.073c.182,0,.291-.145.291-.4V9.4C18,9.145,17.891,9,17.709,9Zm-5.527,9.455H3.455v-8h8.727Zm4.364-.945-2.909-1.818V13.218L16.545,11.4Z'
          fill='#9d9d9d'
        />
      </g>
    </svg>
  );
}

export default Videos;
