import endpoints from 'constants/endpoints';
import { GET_MARKETING_ANNOUNCEMENTS } from 'constants/types';
import { marketingAnnouncementFactory } from 'helpers/factory/marketingAnnouncementFactory';
import { safePostCall } from 'helpers/firebaseSetup';

export const getMarketingAnnouncements =
  (setLocalLoader = () => {}, cancelTokenSource = {}) =>
  (dispatch, getState) => {
    let currentAnnouncements = getState().marketingAnnouncements.announcements;
    if (!currentAnnouncements.length) {
      setLocalLoader(true);
      safePostCall(
        endpoints.awsProxy.safePost,
        {
          endpoint: endpoints.zoho.getMarketingAnnouncements
        },
        {
          cancelToken: cancelTokenSource.token
        }
      )
        .then(announcementsRes => {
          let announcementsArray = JSON.parse(
            `[${announcementsRes.data.details.output}]`
          );

          announcementsArray = announcementsArray.map(announcement =>
            marketingAnnouncementFactory(announcement)
          );

          dispatch({
            type: GET_MARKETING_ANNOUNCEMENTS,
            payload: announcementsArray
          });
          setLocalLoader(false);
        })
        .catch(error => {
          console.log(error);
          if (!(error.message === 'API Call Canceled')) setLocalLoader(false);
        });
    } else setLocalLoader(false);
  };
