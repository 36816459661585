import {
  SHOW_ALERT,
  SHOW_ALERT_MODAL,
  HIDE_ALERT,
  HIDE_ALERT_MODAL,
  IS_LOADING_GLOBAL,
  IS_LOADED_GLOBAL,
  CLEAN_SITE,
  TOGGLE_SIDEBAR
} from 'constants/types';

const INITIAL_STATE = {
  loadingGlobal: false,
  loadingLocal: true,
  sidebarExpanded: false,
  alert: {
    active: false,
    message: '',
    code: ''
  },
  alertModal: {
    active: false,
    message: '',
    title: '',
    size: 'medium',
    buttonText: 'Done'
  }
};

function siteReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case IS_LOADING_GLOBAL:
      return { ...state, loadingGlobal: true };
    case IS_LOADED_GLOBAL:
      return { ...state, loadingGlobal: false };
    case SHOW_ALERT:
      return {
        ...state,
        alert: { ...action.payload, active: true }
      };
    case SHOW_ALERT_MODAL:
      return {
        ...state,
        alertModal: { ...state.alertModal, ...action.payload, active: true }
      };
    case HIDE_ALERT:
      return {
        ...state,
        alert: { ...state.alert, active: false }
      };
    case HIDE_ALERT_MODAL:
      return {
        ...state,
        alertModal: { ...state.alertModal, active: false }
      };
    case CLEAN_SITE:
      return INITIAL_STATE;
    case TOGGLE_SIDEBAR:
      if (action.payload === 'toggle') {
        if (!state.sidebarExpanded) {
          localStorage.setItem(
            'menuState',
            JSON.stringify(!state.sidebarExpanded)
          );
          return { ...state, sidebarExpanded: !state.sidebarExpanded };
        }
        return state;
      } else {
        localStorage.setItem('menuState', JSON.stringify(action.payload));
        return { ...state, sidebarExpanded: action.payload };
      }

    default:
      return state;
  }
}

export default siteReducer;
