//Functions for state-carriers
//Returns the array of states confirmed
export function getConfirmedAppointments(carrierName, agent) {
  let carrierNoSpaces = carrierName.split(' ').join('_');
  if (agent[carrierNoSpaces]) {
    return {
      length: agent[carrierNoSpaces].length,
      data: agent[carrierNoSpaces]
    };
  }
  return { length: 0, data: [] };
}

//Function for true-or-false carriers
//Returns "Approved", "Not Requested" or "Pending"
export function getAppointmentStatus(
  carrierName,
  agent,
  requestedAppointments
) {
  let carrierNoSpaces = carrierName.split(' ').join('_');
  if (agent[carrierNoSpaces]) return 'Confirmed';
  else if (!requestedAppointments[carrierName]) return 'Not Requested';
  else if (requestedAppointments[carrierName].Appointment_Status !== 'Denied') {
    return 'Pending';
  }
}

export function getInProgressStates(ReqAppointment) {
  //Gets all the states for the carrier that are "In progress"
  let inProgress = ReqAppointment.appointments_information.filter(
    appointment =>
      appointment.Appointment_Status === 'Not started' ||
      appointment.Appointment_Status === 'In progress' || 
      appointment.Appointment_Status.includes('In Progress') ||
      appointment.Appointment_Status.includes('In progress')
  );
  return inProgress.map(
    inProgressAppointment => inProgressAppointment.Appointment_State_1
  );
}

export const concatInProgressCarriers = (
  appointedCarriers,
  requestedAppointments
) => {
  let concatenatedCarriers = { ...appointedCarriers };
  let requestedAppointmentsArray = Object.values(requestedAppointments);
  // console.log(requestedAppointmentsArray);

  requestedAppointmentsArray
    .filter(requested => requested.type !== '')
    .filter(requested => !requested.exclude)
    .map(requestedAppointment => {
      if (
        !Object.keys(appointedCarriers[requestedAppointment.type]).includes(
          requestedAppointment.carrier
        )
      ) {
        concatenatedCarriers = {
          ...concatenatedCarriers,
          [requestedAppointment.type]: {
            ...concatenatedCarriers[requestedAppointment.type],
            [requestedAppointment.carrier]: requestedAppointment
          }
        };
      }
    });
  return concatenatedCarriers;
};
