import React from 'react';
import PropTypes from 'prop-types';
import style from './FAQsCard.module.scss';
import Card from 'components/Card/Card';

const FAQsCard = ({ title, questions, onClick, selectedQuestion }) => {
  return (
    <div className={style.card}>
      <Card
        withHr={true}
        title={title}
        fill
        type='default'
      >
        <div className={style.containter}>
          <ul className={style.questionList}>
            {questions.map((question, index) => (
              <li
                key={index}
                className={`${style.questionItem} ${selectedQuestion === question ? style.selected : ''}`}
                onClick={() => onClick(question)}
              >
                <span>{question.question}</span>
              </li>
            ))}
          </ul>
        </div>
      </Card>
    </div>
  );
};

FAQsCard.propTypes = {
  title: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClick: PropTypes.func.isRequired,
  selectedQuestion: PropTypes.object,
};

export default FAQsCard;
