import React from 'react';
import Button from 'components/Button/Button';
import style from './contactCard.module.scss';
import propTypes from 'prop-types';
import { Phone } from '@mui/icons-material';

function ContactCard({ tel, title, hasButton = true }) {
  return (
    <div className={style.contactCard}>
      <h3>{title}</h3>
      <div>
        <a href={`tel:${tel}`}>
          {hasButton ? (
            <Button
              text='call'
              size='small'
              type='primary'
              outlined
              icon={<Phone />}
            />
          ) : null}
        </a>
        <span>{tel}</span>
      </div>
    </div>
  );
}

ContactCard.propTypes = {
  title: propTypes.string,
  tel: propTypes.string,
  hasButton: propTypes.bool
};

export default ContactCard;
