import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import style from './CollapsibleList.module.scss';
import { KeyboardArrowDown, MoreVert } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import DropDownMenu from 'components/DropDownMenu/DropDownMenu';
import DropDownMenuItem from 'components/DropDownMenu/DropDownMenuItem/DropDownMenuItem';
import Button from 'components/Button/Button';
import { Link } from 'react-router-dom';

const TITLE_COLOR = {
  primary: style.titlePrimary,
  secondary: style.titleSecondary
};

const CollapsibleSection = ({
  id,
  title,
  titleColor = 'primary',
  options,
  optionsArray,
  subtitle,
  arrowRight = false,
  expanded = false,
  highlightFirst = false,
  children
}) => {
  const [isExpanded, setExpanded] = useState(false);
  useEffect(() => {
    setExpanded(expanded);
  }, [expanded]);
  return (
    <li className={`${style.section} ${style.mainSection}`}>
      <input
        type='checkbox'
        checked={isExpanded ? 'checked' : ''}
        readOnly
        id={id}
        onClick={() => setExpanded(!isExpanded)}
      />

      <label htmlFor={id}>
        <div className={`${arrowRight ? style.arrowRight : ''}`}>
          {!arrowRight && (
            <div className={style.arrow}>
              <Tooltip title='Expand'>
                <KeyboardArrowDown />
              </Tooltip>
            </div>
          )}
          <div className={style.texts}>
            <span
              className={`${style.title} ${TITLE_COLOR[titleColor]} ${
                highlightFirst ? style.firstParent : null
              }`}
            >
              {title}
            </span>
            {subtitle && <span className={style.subtitle}>{subtitle}</span>}
          </div>
        </div>
        <div className={style.options}>
          <div className={style.optionsLg}>
            {optionsArray
              ? optionsArray.map((option, index) => (
                  <Link key={'option_' + index} to={option.to}>
                    <Button
                      type='transparent'
                      size='xsmall'
                      rounded={'large'}
                      text={option.text}
                      outlined
                      icon={option.icon}
                    />
                  </Link>
                ))
              : options}
          </div>
          {optionsArray && (
            <div className={style.optionsSm}>
              <DropDownMenu
                title='Options'
                menuTrigger={
                  <Tooltip title='Options'>
                    <div>
                      <Button
                        size='medium'
                        type='transparent'
                        outlined
                        icon={<MoreVert />}
                      />
                    </div>
                  </Tooltip>
                }
              >
                {optionsArray.map((option, index) => (
                  <DropDownMenuItem
                    key={'option_' + index}
                    text={option.text}
                    icon={option.icon}
                    to={option.to}
                  />
                ))}
              </DropDownMenu>
            </div>
          )}
        </div>
        {arrowRight && (
          <div className={style.arrow}>
            <Tooltip title='Expand'>
              <KeyboardArrowDown />
            </Tooltip>
          </div>
        )}
      </label>
      <ul className={arrowRight ? style.arrowRight : ''}>{children}</ul>
    </li>
  );
};

CollapsibleSection.propTypes = {
  id: propTypes.string,
  title: propTypes.string,
  titleColor: propTypes.string,
  options: propTypes.element,
  optionsArray: propTypes.array,
  showOptionsOnHover: propTypes.bool,
  subtitle: propTypes.string,
  arrowRight: propTypes.bool,
  expanded: propTypes.bool,
  highlightFirst: propTypes.bool | undefined,
  children: propTypes.node
};

export default CollapsibleSection;
