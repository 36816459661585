import getCompanyVariables from 'helpers/getCompanyVariables';

const company = getCompanyVariables();
let endpoints = {};
let images = {};
if (company === 'oneill') {
  endpoints = {
    awsProxy: {
      safePost: 'https://oneillmarketingapi.com/scripts/SafePortalProxy',
      genericPost: 'https://oneillmarketingapi.com/scripts/GenericPortalProxy'
    },
    zoho: {
      updateAgentWithPortalPassword:
        'https://www.zohoapis.com/crm/v2/functions/updaterecordwithportalpass/actions/execute?auth_type=apikey&zapikey=1003.4bb618f595dfca087f0d3f510d076ca3.78f38d5911bba445b80aea9f7dc2b34f',
      updatePortalNote:
        'https://www.zohoapis.com/crm/v2/functions/manageportalnotes/actions/execute?auth_type=apikey&zapikey=1003.4bb618f595dfca087f0d3f510d076ca3.78f38d5911bba445b80aea9f7dc2b34f',
      updateActionItem:
        'https://www.zohoapis.com/crm/v2/functions/manageactionitems/actions/execute?auth_type=apikey&zapikey=1003.4bb618f595dfca087f0d3f510d076ca3.78f38d5911bba445b80aea9f7dc2b34f',
      getDashboardData:
        'https://www.zohoapis.com/crm/v2/functions/getdashboarddata/actions/execute?auth_type=apikey&zapikey=1003.4bb618f595dfca087f0d3f510d076ca3.78f38d5911bba445b80aea9f7dc2b34f',
      getAgent:
        'https://www.zohoapis.com/crm/v2/functions/getagent/actions/execute?auth_type=apikey&zapikey=1003.4bb618f595dfca087f0d3f510d076ca3.78f38d5911bba445b80aea9f7dc2b34f',
      getCarriersAndAppointments:
        'https://www.zohoapis.com/crm/v2/functions/getcarriersandappointments/actions/execute?auth_type=apikey&zapikey=1003.4bb618f595dfca087f0d3f510d076ca3.78f38d5911bba445b80aea9f7dc2b34f',
      getMarketingAnnouncements:
        'https://www.zohoapis.com/crm/v2/functions/getmarketingannouncements/actions/execute?auth_type=apikey&zapikey=1003.4bb618f595dfca087f0d3f510d076ca3.78f38d5911bba445b80aea9f7dc2b34f',
      manageLeadsForAgent:
        'https://www.zohoapis.com/crm/v2/functions/manageleadsforagent/actions/execute?auth_type=apikey&zapikey=1003.4bb618f595dfca087f0d3f510d076ca3.78f38d5911bba445b80aea9f7dc2b34f',
      // Commissions
      getCommissions:
        'https://www.zohoapis.com/crm/v2/functions/portal_commissions/actions/execute?auth_type=apikey&zapikey=1003.4bb618f595dfca087f0d3f510d076ca3.78f38d5911bba445b80aea9f7dc2b34f',
      getBonus:
        'https://www.zohoapis.com/crm/v2/functions/portal_bonus/actions/execute?auth_type=apikey&zapikey=1003.4bb618f595dfca087f0d3f510d076ca3.78f38d5911bba445b80aea9f7dc2b34f',
      downloadCommissionsDataLocal:
        'http://localhost:5001/o-neill-marketing-portal/us-central1/runCommissionStatement',
      downloadCommissionsData:
        'https://us-central1-o-neill-marketing-portal.cloudfunctions.net/runCommissionStatement',
      // others
      sendEmailsAgentInfo:
        'https://www.zohoapis.com/crm/v2/functions/portal_send_agent_emails/actions/execute?auth_type=apikey&zapikey=1003.4bb618f595dfca087f0d3f510d076ca3.78f38d5911bba445b80aea9f7dc2b34f',
      getNotifications:
        'https://www.zohoapis.com/crm/v2/functions/portal_notifications/actions/execute?auth_type=apikey&zapikey=1003.4bb618f595dfca087f0d3f510d076ca3.78f38d5911bba445b80aea9f7dc2b34f',
      getSharedFoldersAndUserPhoto:
        'https://www.zohoapis.com/crm/v2/functions/portal_get_public_folder_url/actions/execute?auth_type=apikey&zapikey=1003.4bb618f595dfca087f0d3f510d076ca3.78f38d5911bba445b80aea9f7dc2b34f',
      uploadProfilePic:
        'https://www.zohoapis.com/crm/v2/functions/portal_save_agent_photo/actions/execute?auth_type=apikey&zapikey=1003.4bb618f595dfca087f0d3f510d076ca3.78f38d5911bba445b80aea9f7dc2b34f',
      sendPurchaseMoreLeadsForm:
        'https://www.zohoapis.com/crm/v2/functions/portal_purchase_leads/actions/execute?auth_type=apikey&zapikey=1003.4bb618f595dfca087f0d3f510d076ca3.78f38d5911bba445b80aea9f7dc2b34f',
      // Appointments
      addRequestedAppointments:
        'https://www.zohoapis.com/crm/v2/functions/addrequested_appointmentstates/actions/execute?auth_type=apikey&zapikey=1003.4bb618f595dfca087f0d3f510d076ca3.78f38d5911bba445b80aea9f7dc2b34f',
      updateSpecificAppointmentStatus:
        'https://www.zohoapis.com/crm/v2/functions/portal_updatespecificappointment_agentstatus/actions/execute?auth_type=apikey&zapikey=1003.4bb618f595dfca087f0d3f510d076ca3.78f38d5911bba445b80aea9f7dc2b34f',
      // Agency management
      getAgentsTree:
        'https://www.zohoapis.com/crm/v2/functions/recursive_endpoint/actions/execute?auth_type=apikey&zapikey=1003.4bb618f595dfca087f0d3f510d076ca3.78f38d5911bba445b80aea9f7dc2b34f',
      getSpecificAgencyData:
        'https://www.zohoapis.com/crm/v2/functions/portal_get_specific_agency_data/actions/execute?auth_type=apikey&zapikey=1003.4bb618f595dfca087f0d3f510d076ca3.78f38d5911bba445b80aea9f7dc2b34f',
      updateAgencyFormPreferences:
        'https://www.zohoapis.com/crm/v2/functions/portal_updatehidefields/actions/execute?auth_type=apikey&zapikey=1003.4bb618f595dfca087f0d3f510d076ca3.78f38d5911bba445b80aea9f7dc2b34f',
      sendAgencyFormEmail:
        'https://www.zohoapis.com/crm/v2/functions/portal_sendemail_to_each_agentagency/actions/execute?auth_type=apikey&zapikey=1003.4bb618f595dfca087f0d3f510d076ca3.78f38d5911bba445b80aea9f7dc2b34f',
      sendEditAgencyInfoFormToAgent:
        'https://www.zohoapis.com/crm/v2/functions/portalsendeditagencyinfolink/actions/execute?auth_type=apikey&zapikey=1003.4bb618f595dfca087f0d3f510d076ca3.78f38d5911bba445b80aea9f7dc2b34f',
      logOnboardingEmails:
        'https://www.zohoapis.com/crm/v2/functions/log_onboarding_email_api/actions/execute?auth_type=apikey&zapikey=1003.4bb618f595dfca087f0d3f510d076ca3.78f38d5911bba445b80aea9f7dc2b34f',
      getFAQS:
        'https://www.zohoapis.com/crm/v2/functions/portalgetfaqs/actions/execute?auth_type=apikey&zapikey=1003.4bb618f595dfca087f0d3f510d076ca3.78f38d5911bba445b80aea9f7dc2b34f',
      getCarrierFAQS:
        'https://www.zohoapis.com/crm/v2/functions/portalgetcarrierresources/actions/execute?auth_type=apikey&zapikey=1003.4bb618f595dfca087f0d3f510d076ca3.78f38d5911bba445b80aea9f7dc2b34f'
    },
    aws: {
      getAgencyTree: '/agencies/tree',
      getSpecificAgencyData: '/agencies',
      selectedAgencies: '/agencies/selected',
      selectedAgenciesAgents: '/agencies/selectedagents',
      getAppointmentsReport: '/agents/appointments/report',
      getAppointmentsCount: '/agents/appointments/count',
      downloadCommissionsData: '/callidusstatements/bulk',
      comissionsPeriods: '/callidusstatements/periods',
      downloadBookOfBusinessData: '/callidusBooksofbusinessObtainer/bulkk',
      downloadBookOfBusinessDataDownload:
        '/callidusbooksofbusinessObtainer/download',
      downloadBookOfBusinessDataDelete:
        '/callidusbooksofbusinessObtainer/delete',
      BookOfBussinessLastUpdateDate: '/callidusbooksofbusiness/lastupdate',
      saveAgencyFields: '/agencies/updatefields'
    }
  };
}
if (company === 'sunshine') {
  endpoints = {
    awsProxy: {
      safePost: 'https://sunshinelifehealthapi.com/scripts/SafePortalProxy',
      genericPost:
        'https://sunshinelifehealthapi.com/scripts/GenericPortalProxy'
    },
    zoho: {
      updateAgentWithPortalPassword:
        'https://www.zohoapis.com/crm/v2/functions/updaterecordwithportalpass/actions/execute?auth_type=apikey&zapikey=1003.03473fc2674e4dc4394a218e1e99770d.f23e8b7458bd42c05bcc4d3acbaf7b29',
      updatePortalNote:
        'https://www.zohoapis.com/crm/v2/functions/manageportalnotes/actions/execute?auth_type=apikey&zapikey=1003.03473fc2674e4dc4394a218e1e99770d.f23e8b7458bd42c05bcc4d3acbaf7b29',
      updateActionItem:
        'https://www.zohoapis.com/crm/v2/functions/manageactionitems/actions/execute?auth_type=apikey&zapikey=1003.03473fc2674e4dc4394a218e1e99770d.f23e8b7458bd42c05bcc4d3acbaf7b29',
      getDashboardData:
        'https://www.zohoapis.com/crm/v2/functions/getdashboarddata/actions/execute?auth_type=apikey&zapikey=1003.03473fc2674e4dc4394a218e1e99770d.f23e8b7458bd42c05bcc4d3acbaf7b29',
      getAgent:
        'https://www.zohoapis.com/crm/v2/functions/getagent/actions/execute?auth_type=apikey&zapikey=1003.03473fc2674e4dc4394a218e1e99770d.f23e8b7458bd42c05bcc4d3acbaf7b29',
      getCarriersAndAppointments:
        'https://www.zohoapis.com/crm/v2/functions/getcarriersandappointments/actions/execute?auth_type=apikey&zapikey=1003.03473fc2674e4dc4394a218e1e99770d.f23e8b7458bd42c05bcc4d3acbaf7b29',
      getMarketingAnnouncements:
        'https://www.zohoapis.com/crm/v2/functions/getmarketingannouncements/actions/execute?auth_type=apikey&zapikey=1003.03473fc2674e4dc4394a218e1e99770d.f23e8b7458bd42c05bcc4d3acbaf7b29',
      manageLeadsForAgent:
        'https://www.zohoapis.com/crm/v2/functions/manageleadsforagent/actions/execute?auth_type=apikey&zapikey=1003.03473fc2674e4dc4394a218e1e99770d.f23e8b7458bd42c05bcc4d3acbaf7b29',
      // Commissions
      getCommissions:
        'https://www.zohoapis.com/crm/v2/functions/portal_commissions/actions/execute?auth_type=apikey&zapikey=1003.03473fc2674e4dc4394a218e1e99770d.f23e8b7458bd42c05bcc4d3acbaf7b29',
      getBonus:
        'https://www.zohoapis.com/crm/v2/functions/portal_bonus/actions/execute?auth_type=apikey&zapikey=1003.03473fc2674e4dc4394a218e1e99770d.f23e8b7458bd42c05bcc4d3acbaf7b29',
      downloadCommissionsDataLocal:
        'http://localhost:5001/o-neill-marketing-portal/us-central1/runCommissionStatement',
      downloadCommissionsData:
        'https://us-central1-o-neill-marketing-portal.cloudfunctions.net/runCommissionStatement',
      // others
      sendEmailsAgentInfo:
        'https://www.zohoapis.com/crm/v2/functions/portal_send_agent_emails/actions/execute?auth_type=apikey&zapikey=1003.03473fc2674e4dc4394a218e1e99770d.f23e8b7458bd42c05bcc4d3acbaf7b29',
      getNotifications:
        'https://www.zohoapis.com/crm/v2/functions/portal_notifications/actions/execute?auth_type=apikey&zapikey=1003.03473fc2674e4dc4394a218e1e99770d.f23e8b7458bd42c05bcc4d3acbaf7b29',
      getSharedFoldersAndUserPhoto:
        'https://www.zohoapis.com/crm/v2/functions/portal_get_public_folder_url/actions/execute?auth_type=apikey&zapikey=1003.03473fc2674e4dc4394a218e1e99770d.f23e8b7458bd42c05bcc4d3acbaf7b29',
      uploadProfilePic:
        'https://www.zohoapis.com/crm/v2/functions/portal_save_agent_photo/actions/execute?auth_type=apikey&zapikey=1003.03473fc2674e4dc4394a218e1e99770d.f23e8b7458bd42c05bcc4d3acbaf7b29',
      sendPurchaseMoreLeadsForm:
        'https://www.zohoapis.com/crm/v2/functions/portal_purchase_leads/actions/execute?auth_type=apikey&zapikey=1003.03473fc2674e4dc4394a218e1e99770d.f23e8b7458bd42c05bcc4d3acbaf7b29',
      // Appointments
      addRequestedAppointments:
        'https://www.zohoapis.com/crm/v2/functions/addrequested_appointmentstates/actions/execute?auth_type=apikey&zapikey=1003.03473fc2674e4dc4394a218e1e99770d.f23e8b7458bd42c05bcc4d3acbaf7b29',
      updateSpecificAppointmentStatus:
        'https://www.zohoapis.com/crm/v2/functions/portal_updatespecificappointment_agentstatus/actions/execute?auth_type=apikey&zapikey=1003.03473fc2674e4dc4394a218e1e99770d.f23e8b7458bd42c05bcc4d3acbaf7b29',
      // Agency management
      getAgentsTree:
        'https://www.zohoapis.com/crm/v2/functions/recursive_endpoint/actions/execute?auth_type=apikey&zapikey=1003.03473fc2674e4dc4394a218e1e99770d.f23e8b7458bd42c05bcc4d3acbaf7b29',
      getSpecificAgencyData:
        'https://www.zohoapis.com/crm/v2/functions/portal_get_specific_agency_data/actions/execute?auth_type=apikey&zapikey=1003.03473fc2674e4dc4394a218e1e99770d.f23e8b7458bd42c05bcc4d3acbaf7b29',
      updateAgencyFormPreferences:
        'https://www.zohoapis.com/crm/v2/functions/portal_updatehidefields/actions/execute?auth_type=apikey&zapikey=1003.03473fc2674e4dc4394a218e1e99770d.f23e8b7458bd42c05bcc4d3acbaf7b29',
      sendAgencyFormEmail:
        'https://www.zohoapis.com/crm/v2/functions/portal_sendemail_to_each_agentagency/actions/execute?auth_type=apikey&zapikey=1003.03473fc2674e4dc4394a218e1e99770d.f23e8b7458bd42c05bcc4d3acbaf7b29',
      sendEditAgencyInfoFormToAgent:
        'https://www.zohoapis.com/crm/v2/functions/portalsendeditagencyinfolink/actions/execute?auth_type=apikey&zapikey=1003.03473fc2674e4dc4394a218e1e99770d.f23e8b7458bd42c05bcc4d3acbaf7b29',
      logOnboardingEmails:
        'https://www.zohoapis.com/crm/v2/functions/log_onboarding_email_api/actions/execute?auth_type=apikey&zapikey=1003.03473fc2674e4dc4394a218e1e99770d.f23e8b7458bd42c05bcc4d3acbaf7b29',
      getFAQS:
        'https://www.zohoapis.com/crm/v2/functions/portalgetfaqs/actions/execute?auth_type=apikey&zapikey=1003.03473fc2674e4dc4394a218e1e99770d.f23e8b7458bd42c05bcc4d3acbaf7b29',
      getCarrierFAQS:
        'https://www.zohoapis.com/crm/v2/functions/portalgetcarrierresources/actions/execute?auth_type=apikey&zapikey=1003.03473fc2674e4dc4394a218e1e99770d.f23e8b7458bd42c05bcc4d3acbaf7b29'
    },
    aws: {
      getAgencyTree: '/agencies/tree',
      getSpecificAgencyData: '/agencies',
      selectedAgencies: '/agencies/selected',
      selectedAgenciesAgents: '/agencies/selectedagents',
      getAppointmentsReport: '/agents/appointments/report',
      getAppointmentsCount: '/agents/appointments/count',
      downloadCommissionsData: '/callidusstatements/bulk',
      comissionsPeriods: '/callidusstatements/periods',
      downloadBookOfBusinessData: '/callidusBooksofbusinessObtainer/bulkk',
      downloadBookOfBusinessDataDownload:
        '/callidusbooksofbusinessObtainer/download',
      downloadBookOfBusinessDataDelete:
        '/callidusbooksofbusinessObtainer/delete',
      BookOfBussinessLastUpdateDate: '/callidusbooksofbusiness/lastupdate',
      saveAgencyFields: '/agencies/updatefields'
    }
  };
}

export default endpoints;
