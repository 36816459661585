import React from 'react';

function Home() {
  return (
    <svg viewBox='0 0 16.16 16.967'>
      <path
        d='M12.079,4.02l6.463,5.9v7.433H15.714v-5.7a.808.808,0,0,0-.808-.808H9.251a.808.808,0,0,0-.808.808v5.7H5.615V9.918l6.463-5.9m0-2.02a.444.444,0,0,0-.283.121L4.242,8.948A.808.808,0,0,0,4,9.554v9.412h6.06v-6.5H14.1v6.5h6.06V9.554a.808.808,0,0,0-.242-.606L12.361,2.121A.444.444,0,0,0,12.079,2Z'
        transform='translate(-3.999 -2)'
        fill='#9d9d9d'
      />
    </svg>
  );
}

export default Home;
