import React from 'react';

function CommissionRates() {
  return (
    <svg viewBox='0 0 15.226 15.945'>
      <path
        id='Path_90'
        data-name='Path 90'
        d='M12.422,11.06h-5.8a.725.725,0,1,1,0-1.45h5.8a.725.725,0,1,1,0,1.45Zm-5.8,1.45a.725.725,0,1,0,0,1.45h2.9a.725.725,0,1,0,0-1.45Zm8.7-1.812a.725.725,0,0,0-.725.725V16.5H4.45V4.174H8.073a.725.725,0,1,0,0-1.45H3.725A.725.725,0,0,0,3,3.45V17.22a.725.725,0,0,0,.725.725h11.6a.725.725,0,0,0,.725-.725v-5.8A.725.725,0,0,0,15.321,10.7ZM18,10.48a.689.689,0,0,1-1.015,0L15.14,8.632h0a3.769,3.769,0,0,1-1.993.616A3.66,3.66,0,0,1,10.574,8.16H6.624a.725.725,0,1,1,0-1.45H9.7a3.588,3.588,0,0,1-.181-1.087,3.624,3.624,0,0,1,7.248,0,3.769,3.769,0,0,1-.58,1.993h-.036L18,9.465a.689.689,0,0,1,0,1.015ZM11.878,6.711a.326.326,0,0,1,.362-.362h1.45V5.986H12.6a.725.725,0,0,1-.725-.725V4.9a.725.725,0,0,1,.725-.725h.181V3.486a2.174,2.174,0,0,0,0,4.276V7.073h-.544a.326.326,0,0,1-.362-.362Zm3.443-1.087a2.174,2.174,0,0,0-1.812-2.138v.689h.544a.326.326,0,0,1,.362.362.326.326,0,0,1-.362.362H12.6v.362H13.69a.725.725,0,0,1,.725.725v.362a.725.725,0,0,1-.725.725h-.181v.689l.58-.181.217-.109a.036.036,0,0,1,.036-.036l.254-.181a.036.036,0,0,1,.036-.036L14.85,7h0l.181-.29h0l.072-.145A1.993,1.993,0,0,0,15.321,5.624Z'
        transform='translate(-3 -2)'
        fill='#959595'
      />
    </svg>
  );
}

export default CommissionRates;
