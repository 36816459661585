import React from 'react';

function MyAppointments() {
  return (
    <svg viewBox='0 0 17.909 14.653'>
      <g transform='translate(-290 -374)'>
        <path
          d='M18.281,7.628v11.4H3.628V7.628H18.281M19.095,6H2.814A.814.814,0,0,0,2,6.814V19.839a.814.814,0,0,0,.814.814H19.095a.814.814,0,0,0,.814-.814V6.814A.814.814,0,0,0,19.095,6Z'
          transform='translate(288 368)'
          fill='#9d9d9d'
        />
        <path
          d='M10.814,14a.814.814,0,0,0-.814.814v6.512a.814.814,0,1,0,1.628,0V14.814A.814.814,0,0,0,10.814,14Z'
          transform='translate(283.256 363)'
          fill='#9d9d9d'
        />
        <path
          d='M18.814,14a.814.814,0,0,0-.814.814v6.512a.814.814,0,1,0,1.628,0V14.814A.814.814,0,0,0,18.814,14Z'
          transform='translate(278.512 363)'
          fill='#9d9d9d'
        />
        <path
          d='M26.814,14a.814.814,0,0,0-.814.814v6.512a.814.814,0,1,0,1.628,0V14.814A.814.814,0,0,0,26.814,14Z'
          transform='translate(273.77 363)'
          fill='#9d9d9d'
        />
        <path
          d='M34.814,14a.814.814,0,0,0-.814.814v6.512a.814.814,0,1,0,1.628,0V14.814A.814.814,0,0,0,34.814,14Z'
          transform='translate(269.023 363)'
          fill='#9d9d9d'
        />
      </g>
    </svg>
  );
}

export default MyAppointments;
