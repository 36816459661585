import React from 'react';
import style from './loader.module.scss';
import sunshineStyle from './loaderSunshine.module.scss';
import propTypes from 'prop-types';
import FullLogoAnimated from './FullLogoAnimated';
import SunshineLoader from './sunshineLoader';
import companyName from '../../constants/companyNames';

function Loader({ global, className}) {
  if (companyName.short === 'Sunshine') {
    return (
      <div className={`${sunshineStyle.loader} ${global && sunshineStyle.global} ${className}`}>
      {/* <img src={images.brand.fullLogo} alt='' /> */}
        <SunshineLoader />
      </div> 
  );
  } 
  if (companyName.short === 'O\'Neill') {
    return(
    <div className={`${style.loader} ${global && style.global} ${className}`}>
    {/* <img src={images.brand.fullLogo} alt='' /> */}
      <FullLogoAnimated />
    </div>
    ); 
  }
  
}

Loader.propTypes = {
  global: propTypes.bool,
  className: propTypes.string
};

export default Loader;
