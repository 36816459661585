import React, { useEffect, useState } from 'react';
import withLocalLoader from 'components/HOCs/withLocalLoader';
import PropTypes from 'prop-types';
import Card from 'components/Card/Card';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField
} from '@mui/material';
import Button from 'components/Button/Button';
import { PlayArrow } from '@mui/icons-material';
import AutocompleteInput from 'components/Autocomplete/AutocompleteInput';
import {
  getAppointmentsViewData,
  downloadCommissionsData_old,
  getSpecificAgencyData,
  getSelectedAgenciesAgents
} from 'state/actions/actions';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { generateListOfPeriods_Old } from 'helpers/commissions/commissions';
import { USER_TYPES } from 'constants/enum';
import { CircularProgress } from '@mui/material';

const INITIAL_VALUES = {
  periods: [],
  carriers: [],
  agencies: [],
  agents: [],
  orderBy: 'Carrier',
  includeAllCarriers: true
};

const StatementsView = ({ setLocalLoader, localLoader }) => {
  //Hoooks
  const dispatch = useDispatch();
  const cancelTokenSource = axios.CancelToken.source();
  // States from redux
  const { carriersArray } = useSelector(state => state.appointments);
  const { agencyInformation } = useSelector(
    state => state.agencyManagement.agencyData
  );
  const { zohoData } = useSelector(state => state.user);
  const { isDownloadingReport } = useSelector(state => state.commissions);
  const currentAgenciesAgentsDict = useSelector(
    state => state.agencyManagement.comissions_tab_agents
  );
  // Local states
  const [formData, setformData] = useState(INITIAL_VALUES);
  const [listOfPeriods, setListOfPeriods] = useState([]);
  const [preSelectedMainAgentOnce, setPreSelectedMainAgentOnce] =
    useState(false);
  const [
    userHasManuallySelectedAgenciesOrAgents,
    setUserHasManuallySelectedAgenciesOrAgents
  ] = useState(false);
  // Mulstiselect options
  const carriersOptions = React.useMemo(() => {
    return carriersArray
      .filter(option => option.AgencyBloc_CarrierID !== null)
      .map(option => {
        return {
          type: option.type,
          name: option.name,
          id: option.AgencyBloc_CarrierID
        };
      })
      .filter(carrier => carrier.type);
  }, [carriersArray]);
  const agenciesOptions = React.useMemo(() => {
    // Preselects agency
    if (agencyInformation?.id?._value && agencyInformation?.name?._value) {
      setformData(prevState => ({
        ...prevState,
        ['agencies']: [
          {
            id: agencyInformation?.id?._value,
            name: agencyInformation?.name?._value
          }
        ]
      }));
      // Gets the agents from that agency
      dispatch(
        getSelectedAgenciesAgents(
          setLocalLoader,
          currentAgenciesAgentsDict,
          [agencyInformation?.id?._value],
          cancelTokenSource
        )
      );
    }

    // Preselects agent
    // setformData(prevState => ({
    //   ...prevState,
    //   agents: [
    //     {
    //       id: agencyInformation?.id?._value,
    //       name: agencyInformation?.name?._value
    //     }
    //   ]
    // }));
    return (
      agencyInformation.Downline_Agencies_Three?.concat([
        agencyInformation
      ])?.map(agency => ({
        id: agency.id._value,
        name: agency.name._value
      })) || []
    );
  }, [agencyInformation.Downline_Agencies_Three]);
  const mainAgentsOptions = React.useMemo(() => {
    const mainOptions =
      zohoData.NPN !== null &&
      zohoData.NPN !== 0 &&
      zohoData.NPN !== '' &&
      zohoData.NPN !== '0' &&
      zohoData.NPN !== 1 &&
      zohoData.NPN !== '1' &&
      zohoData.NPN.toString().length > 4 &&
      zohoData.NPN.toString().length <= 9
        ? [
            {
              type: 'Main Agent',
              name: zohoData.First_Name + ' ' + zohoData.Last_Name,
              npn: zohoData.NPN,
              Agency_Name: 'Main Agent',
              Agency_Id: zohoData.Account_Name?.id
            }
          ]
        : [];
    return mainOptions;
  }, [zohoData]);
  const agentsOptions = React.useMemo(() => {
    const selectedAgenciesAgents = formData.agencies
      .map(selected_agency => currentAgenciesAgentsDict[selected_agency.id])
      .filter(x => x)
      .flat()
      .map(option => ({
        type: 'Downline Agent',
        name: option.First_Name + ' ' + option.Last_Name,
        npn: option.NPN,
        Agency_Id: option.Agency_Id,
        Agency_Name: option.Agency
      }))
      .filter(
        option =>
          option.npn !== null &&
          option.npn !== 0 &&
          option.npn !== '' &&
          option.npn !== '0' &&
          option.npn !== 1 &&
          option.npn !== '1' &&
          option.npn.toString().length > 4 &&
          option.npn.toString().length <= 9
      )
      .filter(option => option.npn !== zohoData.NPN);
    // Un-selects any previously selected agent that belogs to a no longer selected agency
    if (userHasManuallySelectedAgenciesOrAgents) {
      setformData(prevData => {
        return {
          ...prevData,
          agents: prevData.agents?.filter(a =>
            formData.agencies.map(agency => agency.id).includes(a.Agency_Id)
          )
        };
      });
    }

    return [...mainAgentsOptions, ...selectedAgenciesAgents];
  }, [formData.agencies, currentAgenciesAgentsDict]);
  // Effects
  // Gets data from backend
  useEffect(() => {
    if (zohoData.Position_at_Agency === USER_TYPES.ADMIN) {
      dispatch(getAppointmentsViewData(() => {}, cancelTokenSource));
      dispatch(
        getSpecificAgencyData(
          setLocalLoader,
          zohoData?.Account_Name?.id,
          cancelTokenSource
        )
      );
    } else {
      dispatch(getAppointmentsViewData(setLocalLoader, cancelTokenSource,true));
    }
    setListOfPeriods(generateListOfPeriods_Old);
    return () => {
      cancelTokenSource.cancel('API Call Canceled');
    };
  }, []);
  // Preselects main agent
  useEffect(() => {
    if (mainAgentsOptions?.length === 0) return;
    if (preSelectedMainAgentOnce) return;
    let optionsIncludeMainAgent = false;
    for (let i = 0; i < agentsOptions?.length; i++) {
      const singleAgentOption = agentsOptions[i];
      if (
        singleAgentOption.name === mainAgentsOptions[0]?.name &&
        singleAgentOption.npn === mainAgentsOptions[0]?.npn
      )
        optionsIncludeMainAgent = true;
    }
    if (!optionsIncludeMainAgent) return;
    // Preselects the logged in agent
    setformData(prevData => ({
      ...prevData,
      agents: mainAgentsOptions
    }));
    setPreSelectedMainAgentOnce(true);
  }, [agentsOptions]);

  // Event handlers
  // const handleChangeValue = (event, property) => {
  //   setformData(prevState => ({
  //     ...prevState,
  //     [property]: event.target.value
  //   }));
  // };

  const handleChangeSelectValue = (values, property) => {
    if (property === 'agencies') {
      dispatch(
        getSelectedAgenciesAgents(
          setLocalLoader,
          currentAgenciesAgentsDict,
          values.map(a => a.id),
          cancelTokenSource
        )
      );
      if (!userHasManuallySelectedAgenciesOrAgents) {
        setUserHasManuallySelectedAgenciesOrAgents(true);
      }
    }
    if (property === 'agents' && !userHasManuallySelectedAgenciesOrAgents) {
      setUserHasManuallySelectedAgenciesOrAgents(true);
    }
    setformData(prevState => ({
      ...prevState,
      [property]: values
    }));
  };

  const handleRunReport = () => {
    dispatch(downloadCommissionsData_old(formData, carriersOptions));
  };

  const handleChangeIncludeAllCarriers = () => {
    setformData(prevState => ({
      ...prevState,
      includeAllCarriers: !prevState.includeAllCarriers,
      carriers: []
    }));
  };

  const handleResetValues = () => {
    // const initialValues = {
    //   ...INITIAL_VALUES,
    //   agents: [
    //     {
    //       type: 'Main Agent',
    //       name: zohoData.First_Name + ' ' + zohoData.Last_Name,
    //       npn: zohoData.NPN
    //     }
    //   ]
    // };
    setformData(INITIAL_VALUES);
  };

  return (
    <Card
      fill
      container
      title='Run Commission Statements (Legacy version)'
      titleType='primary'
    >
      <h4>
        The statement for the selected period will be downloaded as a CSV after
        hitting {'Run report'}.
      </h4>
      <FormGroup>
        <Stack mb={2}>
          <AutocompleteInput
            multiple
            options={listOfPeriods}
            id='monthandyear'
            value={formData.periods}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            getOptionLabel={option => option.name}
            onValueChanges={e => handleChangeSelectValue(e, 'periods')}
            renderInput={params => (
              <TextField
                {...params}
                label={'Period'}
                placeholder={'Select periods'}
                error={formData.periods.length === 0}
                helperText={
                  formData.periods.length === 0
                    ? 'You need to select at least 1 period'
                    : ''
                }
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
          />
        </Stack>
        <Stack mb={2}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChangeIncludeAllCarriers}
                checked={formData.includeAllCarriers}
              />
            }
            label={'Include All Carriers'}
          />
        </Stack>
        <Stack mb={3}>
          <AutocompleteInput
            multiple
            loading={localLoader}
            options={carriersOptions
              .sort((a, b) => a.name.localeCompare(b.name))
              .sort((a, b) => -b.type.localeCompare(a.type))}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            groupBy={option => option.type}
            getOptionLabel={option => option.name}
            id='carriers'
            value={formData.carriers}
            onValueChanges={e => handleChangeSelectValue(e, 'carriers')}
            disabled={formData.includeAllCarriers}
            renderInput={params => (
              <TextField
                {...params}
                label={
                  formData.includeAllCarriers ? 'All Carrier(s)' : 'Carrier(s)'
                }
                placeholder={'Select carriers'}
                error={
                  formData.carriers.length === 0 && !formData.includeAllCarriers
                }
                helperText={
                  formData.carriers.length === 0 && !formData.includeAllCarriers
                    ? 'You need to select at least 1 carrier'
                    : ''
                }
                InputLabelProps={{
                  shrink: !formData.includeAllCarriers
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {localLoader ? (
                        <CircularProgress color='inherit' size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  )
                }}
              />
            )}
          />
        </Stack>
        {zohoData.Position_at_Agency === USER_TYPES.ADMIN && (
          <Stack mb={3}>
            <AutocompleteInput
              multiple
              loading={localLoader}
              loadingBlocksOptions={true}
              options={agenciesOptions.sort((a, b) =>
                a.name.localeCompare(b.name)
              )}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              getOptionLabel={option => option.name}
              id='agencies'
              value={formData.agencies}
              onValueChanges={e => {
                handleChangeSelectValue(e, 'agencies');
              }}
              // disabled={formData.includeAllCarriers}
              renderInput={params => (
                <TextField
                  {...params}
                  label={
                    formData.includeAllCarriers
                      ? 'Selected agency(s)'
                      : 'Agency(s)'
                  }
                  placeholder={'Select agencies'}
                  // error={
                  //   formData.carriers.length === 0 && !formData.includeAllCarriers
                  // }
                  helperText={
                    formData.agencies?.length === 0
                      ? 'You need to select at least 1 agency'
                      : ''
                  }
                  // InputLabelProps={{
                  //   shrink: !formData.includeAllCarriers
                  // }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {localLoader ? (
                          <CircularProgress color='inherit' size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              )}
            />
          </Stack>
        )}
        {zohoData.Position_at_Agency === USER_TYPES.ADMIN && (
          <Stack mb={3}>
            <AutocompleteInput
              label={'agents'}
              multiple
              selectAll
              loading={localLoader}
              options={localLoader ? [] : agentsOptions}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              groupBy={option => option.Agency_Name}
              getOptionLabel={option => option.name}
              id='downlineagents'
              value={localLoader ? [] : formData.agents}
              onValueChanges={e => handleChangeSelectValue(e, 'agents')}
              renderInput={params => (
                <TextField
                  {...params}
                  label={'Downline agents'}
                  placeholder={'Select agents'}
                  error={formData.agents.length === 0}
                  helperText={
                    formData.agents.length === 0
                      ? 'You need to select at least 1 agent'
                      : ''
                  }
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {localLoader ? (
                          <CircularProgress color='inherit' size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              )}
              selectAllByGroup={true}
            />
          </Stack>
        )}
        {/* <Stack mb={3}>
          <AutocompleteInput
            label={'Order by'}
            placeholder={'Type email'}
            options={['Carrier', 'Policyholder']}
            disableSearch
            id='grouped-by'
            value={formData.orderBy}
            onValueChanges={e => handleChangeValue(e, 'orderBy')}
            error={!formData.orderBy}
          />
        </Stack> */}

        <Stack direction={'row'} spacing={2}>
          <Button
            text={'Run report'}
            type={'primaryDarkened'}
            rounded={'large'}
            icon={<PlayArrow />}
            onClick={handleRunReport}
            disabled={
              !formData.orderBy ||
              formData.agents.length === 0 ||
              (formData.carriers.length === 0 &&
                !formData.includeAllCarriers) ||
              formData.periods.length === 0 ||
              isDownloadingReport
            }
            isLoading={isDownloadingReport}
          />
          <Button
            text={'Reset form'}
            type={'default'}
            outlined
            rounded={'large'}
            // icon={<Clear />}
            onClick={handleResetValues}
          />
        </Stack>
      </FormGroup>
    </Card>
  );
};

StatementsView.propTypes = {
  localLoader: PropTypes.bool,
  setLocalLoader: PropTypes.func
};

export default withLocalLoader(
  StatementsView,
  true,
  true,
  'Commissions',
  'Statements'
);
