import React from 'react';
import PropTypes from 'prop-types';
import DateComponent from './DateComponent';
import style from './ImportantDate.module.scss';
import Card from 'components/Card/Card';

function ImportantDates ({dates = [{}], loading}){
    return(
    <Card
      withHr={true}
      fill
      title='Important Dates'
      type='default'
      loading={loading}
    >
    {dates.length > 0 ? <div className={style.container}>
      {dates.map(date => (
        <DateComponent
          key={date.id}
          title={date.title}
          description={date.subheader}
          url={date.textURl}
          endDate={date.endDate}
        />
      ))}
    </div> : 
    <span className={style.span}>No Important Dates available.</span> }
    </Card>
    );
}

ImportantDates.propTypes = {
    loading: PropTypes.bool,
    dates: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        startDate: PropTypes.string,
      })
    ),
  };

export default ImportantDates;