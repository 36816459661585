import React from 'react';
import propTypes from 'prop-types';
import styles from '../sidebar.module.scss';
import styleSunshine from '../sidebarSunshine.module.scss';
import companyName from 'constants/companyNames';


const MenuGroup = ({ title, divider = true, children }) => {
  let styleSelected = '';
  switch (companyName.short) {
    case 'O\'Neill':
      styleSelected = styles;
      break;
    case 'Sunshine':
      styleSelected = styleSunshine;
      break;
  }
  return (
    <ul className={`${styleSelected.menuGroup} ${!divider ? styleSelected.noDivider : ''}`}>
      <li className={`${styleSelected.menuGroupTitle} menuGroupTitle`}>{title}</li>
      {children}
    </ul>
  );
};

MenuGroup.propTypes = {
  children: propTypes.any,
  title: propTypes.string,
  divider: propTypes.bool
};

export default MenuGroup;
