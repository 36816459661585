import React from 'react';
// import MaterialTable from 'material-table';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import Button from 'components/Button/Button';
import style from './actionItemsTable.module.scss';
import propTypes from 'prop-types';
import { useEffect } from 'react';
import { useState, useMemo } from 'react';
import { buttonStyling } from 'constants/enum';
import { secondaryButton } from 'helpers/tableSpecialCells/tableSpecialCells';

function ActionItemsTable({ actionItems = [], showModal, localLoader }) {
  const [tableData, setTableData] = useState({ pageSize: 0, data: [] });

  useEffect(() => {
    const pageSize = actionItems.length > 10 ? 10 : actionItems.length;
    setTableData({ pageSize, data: [...actionItems] });
  }, [actionItems]);

  const columns = useMemo(
    () => [
      {
        header: 'Created',
        accessorKey: 'created',
        Cell: rowProps => expiredDate(rowProps.row.original, 'created'),
      },
      {
        header: 'Due',
        accessorKey: 'due',
        Cell: rowProps => expiredDate(rowProps.row.original, 'due'),
      },
      {
        header: 'Title',
        accessorKey: 'title',
        Cell: rowProps => expiredDate(rowProps.row.original, 'title'),
      },
      {
        header: 'Confirmed By',
        accessorKey: 'confirmedBy',
        Cell: rowProps => buttonGen(rowProps.row.original, 'confirmedBy'),
      },
      {
        header: 'Status',
        accessorKey: 'status',
        Cell: rowProps => buttonGen(rowProps.row.original, 'status'),
      },
      {
        header: 'Options',
        accessorKey: 'details',
        Cell: rowProps =>
          secondaryButton('Details', () => showModal(rowProps.row.original.id)),
      }
    ],
    [],
  );
  const table = useMaterialReactTable({
    columns,
    data: [...tableData.data],
    enableSorting: false,
    enableColumnActions: false,
    renderEmptyRowsFallback: () => 
      <h4
              style={{
                color: 'grey',
                textAlign: 'center',
                fontWeight: '400'
              }}
            >
              No Action items
          </h4>
  });
          

  return (
    <MaterialReactTable table={table} /> //other more lightweight MRT sub components also available
  );
}

ActionItemsTable.propTypes = {
  actionItems: propTypes.array,
  showModal: propTypes.func,
  localLoader: propTypes.bool
};

export default ActionItemsTable;

//Functions for special rendering in table cells
function buttonGen(rowProps, row) {
  const text = rowProps[row];
  return (
    <Button
      fill
      size='xsmall'
      rounded={'large'}
      className={`${style.tableButton} ${buttonStyling[text]} `}
      outlined
      text={text}
    />
  );
}

function expiredDate(rowProps, row) {
  const text = rowProps[row];
  return (
    <>
      {row === 'due' ? (
        <span className={`${style.date} `}>
          {text ? text : '--/--/---'}
          {rowProps.overDue && rowProps.status !== 'Closed' && (
            <div className={style.expired}>
              {rowProps.overDue && rowProps.status !== 'Closed'
                ? 'Expired'
                : ' '}
            </div>
          )}
        </span>
      ) : (
        <span className={`${style.date} `}>
          {text}
          <div></div>
        </span>
      )}
    </>
  );
}
