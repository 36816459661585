import MarketingView from './MarketingView';
import { connect } from 'react-redux';
import { getMarketingAnnouncements } from 'state/actions/actions';
import withLocalLoader from 'components/HOCs/withLocalLoader';

const mapStateToProps = ({ marketingAnnouncements }) => ({
  announcements: marketingAnnouncements.announcements
});

export default connect(mapStateToProps, { getMarketingAnnouncements })(
  withLocalLoader(MarketingView, true, true, 'Marketing')
);
