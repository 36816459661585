import axios from 'axios';
import Card from 'components/Card/Card';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './AgencySettings.module.scss';
import Button from 'components/Button/Button';
import { ArrowBack, Business } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { SendEditAgencyInfoFormToAgent } from 'state/actions/actions';
import OnboardingFormManagement from './OnboardingFormManagement/OnboardingFormManagement';
import AgencyInformation from './AgencyInformation/AgencyInformation';
import AgencyBankInformation from './AgencyBankInformation/AgencyBankInformation';

const AgencySettings = ({
  agencyData,
  setLocalLoader,
  getSpecificAgencyData,
  UpdateAgencyFormPreferences,
  SendAgencyFormEmail
}) => {
  const agencyInformation = agencyData.agencyInformation;

  const cancelTokenSource = axios.CancelToken.source();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id) getSpecificAgencyData(setLocalLoader, id, cancelTokenSource);
    else navigate('/agencies');

    return () => {
      cancelTokenSource.cancel('API Call Canceled');
    };
  }, []);

  return (
    <div className={styles.agencySettings}>
      <div>
        <Button
          type='secondary'
          size='xsmall'
          icon={<ArrowBack />}
          text={'Go to dashboard'}
          onClick={() => navigate(`/agencies/${id}`)}
        />
      </div>
      {/* <Card title='Agency Details' titleType='primary'>
        <div className={styles.agencyDetails}>
          <div className={styles.icon}>
            <Business />
          </div>
          <div>
            <p className={styles.agencyName}>
              {agencyInformation.Account_Name}
            </p>
          </div>
        </div>
      </Card> */}
      <div className={styles.agencyName}>
        {agencyInformation.Account_Name} 
      </div>
      <OnboardingFormManagement
        UpdateAgencyFormPreferences={UpdateAgencyFormPreferences}
        SendAgencyFormEmail={SendAgencyFormEmail}
      />
      <AgencyBankInformation
        agencyInformation={agencyInformation}
        SendEditAgencyInfoFormToAgent={SendEditAgencyInfoFormToAgent}
      />
      <AgencyInformation
        agencyInformation={agencyInformation}
        //SendEditAgencyInfoFormToAgent={SendEditAgencyInfoFormToAgent}
      />
    </div>
  );
};
AgencySettings.propTypes = {
  agencyData: PropTypes.object,
  zohoData: PropTypes.object,
  setLocalLoader: PropTypes.func,
  getSpecificAgencyData: PropTypes.func,
  UpdateAgencyFormPreferences: PropTypes.func,
  SendAgencyFormEmail: PropTypes.func
};

export default AgencySettings;
