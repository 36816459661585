import {
  GET_CARRIER_FAQS,
  GET_FAQS,
  CLEAR_CARRIER_FAQS,
  SET_CARRIER_FAQS_LOADER
} from 'constants/types';

const INITIAL_STATE = {
  faqsArray: [],
  carrierFaqsArray:[],
  carrierLoader:false
};

function siteReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_FAQS:
      return { ...state, faqsArray: action.payload };
    case GET_CARRIER_FAQS:
      return { ...state, carrierFaqsArray: action.payload};
    case CLEAR_CARRIER_FAQS:
      return{ ...state, carrierFaqsArray: []};
    case SET_CARRIER_FAQS_LOADER:
      return{ ...state, carrierLoader: action.payload};
    default:
      return state;
  }
}

export default siteReducer;
