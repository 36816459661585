import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRouteAuth = () => {
  const authenticated = useSelector(
    state => state.authentication.authenticated
  );
  const zohoId = useSelector(state => state.user.zohoId);
  return authenticated && zohoId ? <Navigate to={'/'} replace /> : <Outlet />;
};

export default PrivateRouteAuth;
