import { Edit } from '@mui/icons-material';
import Button from 'components/Button/Button';
import Card from 'components/Card/Card';
import Modal from 'components/Modal/Modal';
import SendEmailConfirmationForm from 'components/SendEmailConfirmationForm/SendEmailConfirmationForm';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../AgencySettings.module.scss';

const AgencyInformation = ({
  agencyInformation,
  SendEditAgencyInfoFormToAgent
}) => {
  const [modalState, setModalState] = useState(false);
  return (
    <React.Fragment>
      <Card
        title='Agency Information'
        titleType='primary'
      >
        <div className={styles.agencyInformation}>
          <div>
            <p>Business/Agency specific link:</p>
            <span>
              {agencyInformation.Business_Agency_Specific_Link
                ? agencyInformation.Business_Agency_Specific_Link
                : '---'}
            </span>
          </div>

          <div>
            <p>Exclude downline agents from OM communications:</p>
            <span>
              {agencyInformation.Exclude_downline_agents_from_OM_Comunications
                ? agencyInformation.Exclude_downline_agents_from_OM_Comunications
                : '---'}
            </span>
          </div>
          {/* <div>
            <p>Agent emails to be excluded:</p>
            <span>
              {agencyInformation.Exclude_downline_agents_from_OM_Comunications
                ? agencyInformation.Exclude_downline_agents_from_OM_Comunications
                : '---'}
            </span>
          </div> */}
          <div>
            <p>Hide Bonus data from showing to downline agents:</p>
            <span>
              {agencyInformation.Hide_Bonus_Rates_from_Agents ? 'Yes' : 'No'}
            </span>
          </div>
          <div>
            <p>Hide commission data from showing to downline agents:</p>
            <span>
              {agencyInformation.Hide_Commission_Rates_from_Agents
                ? 'Yes'
                : 'No'}
            </span>
          </div>
          <div>
            <p>Specific requests:</p>
            <span>
              {agencyInformation.Exclude_downline_agents_from_OM_Comunications
                ? agencyInformation.Exclude_downline_agents_from_OM_Comunications
                : '---'}
            </span>
          </div>
          <div>
            <p>
              Pay downline agents directly or to Business/Agency specific link:
            </p>
            <span>
              {agencyInformation.Pay_downline_agents_directly_or_to_Business_Agency
                ? agencyInformation.Pay_downline_agents_directly_or_to_Business_Agency
                : '---'}
            </span>
          </div>
          <div>
            <p>How will agents be contracted:</p>
            <span>
              {agencyInformation.How_will_agents_be_contracted
                ? agencyInformation.How_will_agents_be_contracted
                : '---'}
            </span>
          </div>
          <div>
            <p>Downline commission rates:</p>
            <span>
              {agencyInformation.Downline_commission_rates
                ? agencyInformation.Downline_commission_rates
                : '---'}
            </span>
          </div>
          <div>
            <p>Medicare email ok:</p>
            <span>{agencyInformation.Medicare_email_ok ? 'Yes' : 'No'}</span>
          </div>
        </div>
      </Card>
      <Modal
        active={modalState}
        size={'auto'}
        closeModal={() => setModalState(false)}
      >
        <SendEmailConfirmationForm
          closeModal={() => setModalState(false)}
          modalText={
            'We will send you an email with links to securely update agency Banking information. Please click "Send" below.'
          }
          dispatchFunction={SendEditAgencyInfoFormToAgent}
        />
      </Modal>
    </React.Fragment>
  );
};
AgencyInformation.propTypes = {
  agencyInformation: PropTypes.object,
  SendEditAgencyInfoFormToAgent: PropTypes.func
};

export default AgencyInformation;
