import {
  CLEAN_DASHBOARD,
  CREATE_NOTE,
  DELETE_NOTE,
  GET_DASHBOARD_DATA,
  UPDATE_ACTION_ITEM,
  UPDATE_NOTE
} from 'constants/types';

const INITIAL_STATE = {
  announcements: undefined,
  notes: undefined,
  actionItems: undefined,
  importantDates: undefined,
  owner:undefined
};

function siteReducer(state = INITIAL_STATE, action) {
  let newNotes;
  switch (action.type) {
    case GET_DASHBOARD_DATA:
      return { ...state, ...action.payload };
    case DELETE_NOTE:
      newNotes = { ...state.notes };
      delete newNotes[action.payload];
      return {
        ...state,
        notes: { ...newNotes }
      };
    case UPDATE_NOTE:
      return {
        ...state,
        notes: { ...state.notes, [action.payload.id]: action.payload }
      };
    case CREATE_NOTE:
      let date = new Date();
      let month = date.getUTCMonth() + 1;
      return {
        ...state,
        notes: {
          [action.payload.id]: {
            ...action.payload,
            date: `${date.getUTCFullYear()}-${
              month < 10 ? '0' + month : month
            }-${date.getUTCDate()}`
          },
          ...state.notes
        }
      };
    case UPDATE_ACTION_ITEM:
      return {
        ...state,
        actionItems: {
          ...state.actionItems,
          [action.payload.id]: action.payload
        }
      };
    case CLEAN_DASHBOARD:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export default siteReducer;
