import endpoints from 'constants/endpoints';
import { GET_LEADS, UPDATE_LEAD } from 'constants/types';
import { ArrayToObject } from 'helpers/arrays';
import { leadFactory } from 'helpers/factory/leadFactory';
import { safePostCall } from 'helpers/firebaseSetup';
import { showAlert } from './site';
import { refreshUser } from './actions';

export const getLeads =
  (setLocalLoader, cancelTokenSource = {}) =>
  async (dispatch, getState) => {
    let zohoId = getState().user.zohoId;
    let leads = getState().leads.leads;
    await dispatch(refreshUser());

    if (!Object.keys(leads).length) {
      let hasMoreLeads = true;
      let isExited = false;
      for (let i = 1; i < Infinity; i++) {
        await safePostCall(
          endpoints.awsProxy.safePost,
          {
            endpoint: endpoints.zoho.manageLeadsForAgent,
            zohoId,
            page: i,
            itemsPerPage: 200,
            leadId: false
          },
          {
            cancelToken: cancelTokenSource.token
          }
        )
          .then(leadsRes => {
            leadsRes = JSON.parse(`[${leadsRes.data.details.output}]`);
            if (!(leadsRes.length > 0)) hasMoreLeads = false;
            else {
              let formattedLeads = leadsRes.map(lead => leadFactory(lead));
              dispatch({
                type: GET_LEADS,
                payload: ArrayToObject(formattedLeads)
              });
            }
          })
          .catch(error => {
            console.log(error);
            isExited = true;
          });
        if (!hasMoreLeads) {
          setLocalLoader(false);
          break;
        }
        if (isExited) break;
      }
    } else {
      setLocalLoader(false);
    }
  };

export const ModifyLead =
  (leadId, note, status, closeModal, setLoading) => async dispatch => {
    setLoading(true);
    safePostCall(endpoints.awsProxy.safePost, {
      endpoint: endpoints.zoho.manageLeadsForAgent,
      leadId,
      note,
      status,
      isClosed: status.includes('Close')
    })
      .then(response => {
        console.log(response);
        dispatch({
          type: UPDATE_LEAD,
          payload: { leadId, note, status, isClosed: status.includes('Close') }
        });
        closeModal();
        dispatch(
          showAlert({ message: 'Lead Modified Successfully', code: 'success' })
        );
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  };
