import React from 'react';
// import MaterialTable from 'material-table';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import style from './AgentsTable.module.scss';
import propTypes from 'prop-types';
import { useEffect, useState, useMemo } from 'react';
import { secondaryButton } from 'helpers/tableSpecialCells/tableSpecialCells';
import { useSelector } from 'react-redux';
import { Check, Clear } from '@mui/icons-material';

function AgentsTable({ agents }) {
  const [tableData, setTableData] = useState({ pageSize: 0, data: [] });
  const zohoData = useSelector(state => state.user.zohoData);

  useEffect(() => {
    const pageSize = agents.length > 100 ? 100 : agents.length;
    setTableData({
      pageSize,
      data: [...agents]
    });
  }, [agents]);

  const handleViewAsAgent = agentID => {
    window.open(`/?va=${agentID}`, 'blank');
  };
  // return <div>La tabla de agentes?</div>;
  /// ============================
  const headerStyle = (name) => <div style={{ size: '5px', height: '48px', display: 'flex', alignItems: 'center'}}>{name}</div>;
  const columns = useMemo(
    () => [
      {
        header: 'View as agent',
        accessorKey: 'viewasagent',
        grow: false,
        size: 109,
        Cell: rowProps => {
          return secondaryButton(
            'view',
            () => handleViewAsAgent(rowProps.row.original.id),
            style.button,
            rowProps.row.original.id === zohoData.id ? true : false
          );
        },
        Header: headerStyle('View as agent')
      },
      {
        header: 'Name',
        accessorKey: 'First_Name',
        grow: false,
        size: 220,
        Cell: rowProps =>
          cellContainer(rowProps.row.original.First_Name + ' ' + rowProps.row.original.Last_Name),
        Header: headerStyle('Name')
      },
      {
        header: 'NPN',
        accessorKey: 'NPN',
        grow: false,
        size: 100,
        Cell: rowProps => cellContainer(rowProps.row.original.NPN),
        Header: headerStyle('NPN')
      },
      {
        header: 'Phone',
        accessorKey: 'Phone',
        grow: false,
        size: 100,
        Cell: rowProps => cellContainer(rowProps.row.original.Phone),
        Header: headerStyle('Phone')
      },
      {
        header: 'Email',
        accessorKey: 'Email',
        grow: false,
        size: 100,
        Cell: rowProps => cellContainer(rowProps.row.original.Email),
        Header: headerStyle('Email')
      },
      {
        header: 'Agency',
        accessorKey: 'Agency',
        grow: false,
        size: 200,
        Cell: rowProps => cellContainer(rowProps.row.original.Agency),
        Header: headerStyle('Agency')
      },
      {
        header: 'Position',
        accessorKey: 'Position_at_Agency',
        grow: false,
        size: 100,
        Cell: rowProps =>
          cellContainer(rowProps.row.original.Position_at_Agency || ''),
        Header: headerStyle('Position')
      },
      {
        header: 'Onboarding Completed?',
        accessorKey: 'Initial_Onboarding_Form_Completed',
        grow: false,
        size: 100,
        Cell: rowProps =>
          cellContainerCehckMark(
            rowProps.row.original.Initial_Onboarding_Form_Completed
          ),
        Header: headerStyle('Onboarding Completed?')
      }
    ],
    [],
  );
  console.log('tableData.data');
  console.log(tableData.data);
  const dataForTable = React.useMemo(() => [...tableData.data],[tableData.data]);
  const table = useMaterialReactTable({
    columns,
    data: dataForTable,
    enableSorting: false,
    enableColumnActions: false,
    initialState: {
      pagination: {
        pageSize: tableData.pageSize > 0 ? tableData.pageSize : 100, // Default page size 100
      },
    },
    renderEmptyRowsFallback: () => 
      <h2
        style={{
          color: 'grey',
          textAlign: 'center',
          fontWeight: '400',
          fontSize: 14
        }}
      >
        No agents related to this agency
      </h2>
  });
  
  return (
    <MaterialReactTable table={table} /> 
  );

}

AgentsTable.propTypes = {
  agents: propTypes.array
};

export default AgentsTable;

const cellContainer = text => {
  return <span className={style.cellContainer}>{text}</span>;
};

const cellContainerCehckMark = completed => {
  return (
    <div className={style.checkMark}>
      {completed ? <Check color={'success'} /> : <Clear color='warning' />}
    </div>
  );
};
