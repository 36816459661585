import React from 'react';

function Link() {
  return (
    <svg viewBox='0 0 10.861 10.849'>
      <g
        id='Group_423'
        data-name='Group 423'
        transform='translate(-802.306 -651)'
      >
        <path
          id='Path_50'
          data-name='Path 50'
          d='M6.655,24.293a2.712,2.712,0,0,1-1.9-.787,2.658,2.658,0,0,1,0-3.8l1.953-1.953a2.658,2.658,0,0,1,3.8,0,.542.542,0,0,1-.759.759,1.627,1.627,0,0,0-2.278,0L5.516,20.469a1.611,1.611,0,1,0,2.278,2.278l1.221-1.275a.542.542,0,0,1,.759.759L8.554,23.507A2.712,2.712,0,0,1,6.655,24.293Z'
          transform='translate(798.348 637.556)'
          fill='#0c9dfa'
        />
        <path
          id='Path_51'
          data-name='Path 51'
          d='M21.507,11.323a2.712,2.712,0,0,1-1.9-.787.542.542,0,0,1,.759-.759,1.627,1.627,0,0,0,2.278,0L24.6,7.824a1.611,1.611,0,1,0-2.278-2.278L21.1,6.821a.542.542,0,0,1-.759-.759l1.221-1.275a2.685,2.685,0,1,1,3.8,3.8l-1.953,1.953A2.712,2.712,0,0,1,21.507,11.323Z'
          transform='translate(787.022 647)'
          fill='#0c9dfa'
        />
      </g>
    </svg>
  );
}

export default Link;
