import React from 'react';

function OMProducts() {
  return (
    <svg viewBox='0 0 16 14.545'>
      <g transform='translate(-2 -4)'>
        <path
          d='M17.273,6.909h-4V4.727A.727.727,0,0,0,12.545,4H7.455a.727.727,0,0,0-.727.727V6.909h-4A.727.727,0,0,0,2,7.636V17.818a.727.727,0,0,0,.727.727H17.273A.727.727,0,0,0,18,17.818V7.636A.727.727,0,0,0,17.273,6.909ZM8.182,5.455h3.636V6.909H8.182Zm8.364,2.909v2.327A13.745,13.745,0,0,1,10,12.4a13.745,13.745,0,0,1-6.545-1.709V8.364ZM3.455,17.091V12.327a14.509,14.509,0,0,0,5.818,1.491v1.273h1.455V13.818a14.509,14.509,0,0,0,5.818-1.491v4.764Z'
          fill='#9d9d9d'
        />
      </g>
    </svg>
  );
}

export default OMProducts;
