import React, { useState } from 'react';
import Table from './ActionItemsTable/ActionItemsTable';
import style from './actionItems.module.scss';
import Tabs from 'components/Tabs/Tabs';
import { useSelector } from 'react-redux';
import propTypes from 'prop-types';
import { useEffect } from 'react';
import ActionItemsFormModal from './ActionItemsFormModal/ActionItemsFormModal';
import axios from 'axios';
import Card from 'components/Card/Card';

function ActionItems({ getDashboardData, setLocalLoader, ...props }) {
  const actionItems = { ...useSelector(state => state.dashboard.actionItems) };
  const [modalState, setModalState] = useState({ active: false, id: '' });
  const cancelTokenSource = axios.CancelToken.source();

  const showModal = id => {
    setModalState({ active: true, id });
  };

  useEffect(() => {
    getDashboardData(setLocalLoader, cancelTokenSource);
    return () => {
      cancelTokenSource.cancel('API Call Canceled');
    };
  }, []);

  return (
    <Card container fill title='Action Items'>
      <>
        <Tabs size='small' headers={['Open', 'Complete']}>
          <div className={style.tableContainer}>
            <Table
              actionItems={
                actionItems &&
                Object.values(actionItems).filter(
                  actionItem => actionItem.status !== 'Closed'
                )
              }
              showModal={showModal}
              {...props}
            />
          </div>
          <div className={style.tableContainer}>
            <Table
              actionItems={
                actionItems &&
                Object.values(actionItems).filter(
                  actionItem => actionItem.status === 'Closed'
                )
              }
              showModal={showModal}
              {...props}
            />
          </div>
        </Tabs>

        {modalState.id && (
          <ActionItemsFormModal
            actionItemId={modalState.id}
            active={modalState.active}
            setModalState={setModalState}
          />
        )}
      </>
    </Card>
  );
}

ActionItems.propTypes = {
  getDashboardData: propTypes.func,
  setLocalLoader: propTypes.func,
  localLoader: propTypes.bool
};

export default ActionItems;
