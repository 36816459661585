import {
  CLEAN_APPOINTMENTS,
  GET_APPOINTED_CARRIERS,
  GET_CARRIERS,
  SET_CARRIERS_ARRAY,
  SET_STATEMENTS_CARRIERS_ARRAY,
  GET_REQUESTED_APPOINTMENTS,
  IS_SENDING_REQUEST_APPOINTMENTS,
  IS_UPDATING_REQUEST_APPOINTMENTS,
  GET_STATEMENT_PERIODS,
  SET_REQUESTED_CARRIER_NAME,
  UPDATE_LOADING_CARD_STATE
} from 'constants/types';

const INITIAL_STATE = {
  carriers: {
    Marketplace: [],
    'Off-Market Health': [],
    Medicare: [],
    Life: [],
    Group: []
  },
  carriersArray: [],
  satementsCarriersArray: [],
  requestedAppointments: {},
  requestedAppointmentsArray: [],
  appointedCarriers: {
    Marketplace: [],
    'Off-Market Health': [],
    Medicare: [],
    Life: [],
    Group: []
  },
  isSendingRequestAppointments: false,
  isUpdatingRequestAppointments: false,
  requestedCarrierName:'',
  statementPeriods: [],
  loadingCard:true
};

function appointmentsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_CARRIERS:
      return { ...state, carriers: action.payload };
    case SET_CARRIERS_ARRAY:
      return { ...state, carriersArray: action.payload };
    case SET_STATEMENTS_CARRIERS_ARRAY:
      return { ...state, satementsCarriersArray: action.payload };
    case GET_REQUESTED_APPOINTMENTS:
      return {
        ...state,
        requestedAppointments: action.payload.requestedAppointments,
        requestedAppointmentsArray: action.payload.requestedAppointmentsArray
      };
    case GET_APPOINTED_CARRIERS:
      return { ...state, appointedCarriers: action.payload };
    case CLEAN_APPOINTMENTS:
      return INITIAL_STATE;
    case IS_SENDING_REQUEST_APPOINTMENTS:
      return { ...state, isSendingRequestAppointments: action.payload };
    case IS_UPDATING_REQUEST_APPOINTMENTS:
      return { ...state, isUpdatingRequestAppointments: action.payload };
    case GET_STATEMENT_PERIODS:
      return { ...state, statementPeriods: action.payload };
      case SET_REQUESTED_CARRIER_NAME:
      return { ...state, requestedCarrierName: action.payload };
      case UPDATE_LOADING_CARD_STATE:
        return { ...state, loadingCard: action.payload };
    default:
      return state;
  }
}

export default appointmentsReducer;
