import endpoints from 'constants/endpoints';
import { GET_BONUS } from 'constants/types';
import { safePostCall } from 'helpers/firebaseSetup';

export const getBonus = (carrierName, loader) => (dispatch, getState) => {
  let bonus = getState().bonus.bonus;
  if (!bonus[carrierName]) {
    loader({ isActive: true, isHidden: false });
    safePostCall(endpoints.awsProxy.safePost, {
      endpoint: endpoints.zoho.getBonus,
      carrierName
    })
      .then(bonusRes => {
        if (
          !bonusRes.data.details.output.includes('Error') &&
          !bonusRes.data.details.output.includes("Couldn't find a bonus")
        ) {
          let bonus = JSON.parse(`[${bonusRes.data.details.output}]`);
          dispatch({ type: GET_BONUS, payload: { carrierName, bonus } });
          loader({ isActive: false, isHidden: false });
        } else loader({ isActive: false, isHidden: true });
      })
      .catch(error => {
        {
          console.log(error);
          loader({ isActive: false, isHidden: true });
        }
      });
  } else loader({ isActive: false, isHidden: false });
};
