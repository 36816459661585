export const generateListOfPeriods = () => {
  const date = new Date();
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth() + 1;
  const arrayOfDates = [];
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  for (let i = currentYear; i > currentYear - 10; i--) {
    for (let month = 11; month >= 0; month--) {
      if (month < currentMonth || currentYear != i) {
        arrayOfDates.push({
          name: `${months[month]} - ${i}`,
          month: month + 1,
          year: i
        });
      }
    }
  }
  return arrayOfDates;
};

export const generateListOfPeriods_Old = () => {
  const currentYear = 2023;
  const currentMonth = 6;
  const arrayOfDates = [];
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  for (let i = currentYear; i > currentYear - 10; i--) {
    for (let month = 11; month >= 0; month--) {
      if (month < currentMonth || currentYear != i) {
        arrayOfDates.push({
          name: `${months[month]} - ${i}`,
          month: month + 1,
          year: i
        });
      }
    }
  }
  return arrayOfDates;
};
