import React, { useEffect } from 'react';
import style from './commissionsView.module.scss';
import Button from 'components/Button/Button';
import MainSectionCarriers from 'components/MainSectionCarriers/MainSectionCarriers';
import Modal from 'components/Modal/Modal';
import Tabs from 'components/Tabs/Tabs';
import CommissionsTable from '../CommissionsTable/CommissionsTable';
import CommissionCard from '../CommissionCard/CommissionCard';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import withLocalLoader from 'components/HOCs/withLocalLoader';
import { getAppointmentsViewData } from 'state/actions/actions';
import propTypes from 'prop-types';
import axios from 'axios';
import Card from 'components/Card/Card';
import { OpenInNew } from '@mui/icons-material';

function CommissionsView({ setLocalLoader, ...props }) {
  const dispatch = useDispatch();
  const [active, setActive] = useState('');
  const { carriers, appointedCarriers } = useSelector(
    state => state.appointments
  );
  const { commissions } = useSelector(state => state.commissions);

  const cancelTokenSource = axios.CancelToken.source();
  const [floatingTabs, setFloatingTabs] = useState(false);

  useEffect(() => {
    dispatch(getAppointmentsViewData(setLocalLoader, cancelTokenSource,true));
    return () => {
      cancelTokenSource.cancel('API Call Canceled');
    };
  }, []);

  const handleOnClick = url => {
    window.open(url, 'blank');
  };

  const handleScroll = e => {
    if (e.target.scrollTop > 180) {
      setFloatingTabs(true);
    } else {
      setFloatingTabs(false);
    }
  };

  return (
    <Card
      onScrollCapture={handleScroll}
      container
      fill
      title='Commission Rates'
      options={
        <>
          <Button
            type='secondary'
            size='small'
            rounded={'large'}
            text='Go to AgencyBloc'
            icon={<OpenInNew />}
            onClick={() => handleOnClick('https://app.agencybloc.com/')}
          />
          {/* <Button
            type='primaryLight'
            size='small'
            rounded={'large'}
            text='Request Appointments'
            onClick={() =>
              handleOnClick(
                `https://forms.oneillmarketing.net/oneillmarketing/form/IndividualAppointmentRequestForm/formperma/cJer2y2EfRB6W8F6Al74YPryxKlkdl1kzBnw1hH1eik?npnField98221=${
                  user.NPN_Text_Field
                }&ffm=${user.ffm_ms[0] && user.ffm_ms[0]}&ahip=${
                  user.AHIP[0] && user.AHIP[0]
                }`
              )
            }
          /> */}
        </>
      }
    >
      <Tabs
        floatingHeader={floatingTabs}
        headers={['Appointed Carriers', 'All Carriers']}
        className={style.tabs}
      >
        <MainSectionCarriers
          CardNoStates={CommissionCard}
          Card={CommissionCard}
          carriers={appointedCarriers}
          active={active}
          setActive={setActive}
          {...props}
        />
        <MainSectionCarriers
          CardNoStates={CommissionCard}
          Card={CommissionCard}
          carriers={carriers}
          active={active}
          setActive={setActive}
          {...props}
        />
      </Tabs>
      <Modal
        active={active}
        title={active}
        size={'auto'}
        isLoading={!commissions[active]}
        closeModal={() => setActive('')}
      >
        <CommissionsTable commissions={commissions[active]} />
      </Modal>
    </Card>
  );
}

CommissionsView.propTypes = {
  setLocalLoader: propTypes.func,
  localLoader: propTypes.bool
};

export default withLocalLoader(
  CommissionsView,
  true,
  true,
  'Commissions',
  'Commissions Rates'
);
