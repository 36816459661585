import React, { useEffect, useRef } from 'react';
import style from './tabs.module.scss';
import propTypes from 'prop-types';
import { useState } from 'react';
import Button from 'components/Button/Button';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

const TabSizes = {
  small: style.small,
  medium: ''
};

function Tabs({
  headers = [],
  size = 'medium',
  children,
  floatingHeader,
  className,
  bottomLine,
}) {
  const [activeTab, setActiveTab] = useState(1);
  const [scrollLeftDisabled, setDisableScrollLeft] = useState(true);
  const [scrollRightDisabled, setDisableScrollRight] = useState(false);
  const [scrollable, setScrollable] = useState(false);
  const headersRef = useRef(null);

  const handleScrollCaptureLeft = () => {
    if (headersRef.current) {
      const headersScrollWidth = headersRef.current.scrollWidth;
      const headerScrollLeft = headersRef.current.scrollLeft;
      const headersOffsetWidth = headersRef.current.offsetWidth;
      if (headerScrollLeft > 0) {
        if (headersScrollWidth - headersOffsetWidth === headerScrollLeft)
          setDisableScrollRight(true);
        else setDisableScrollRight(false);

        setDisableScrollLeft(false);
      } else {
        if (headersScrollWidth - headersOffsetWidth === headerScrollLeft) {
          setDisableScrollRight(true);
          setScrollable(false);
        } else {
          setDisableScrollRight(false);
          setScrollable(true);
        }
        setDisableScrollLeft(true);
      }
    }
  };

  useEffect(() => {
    handleScrollCaptureLeft();
  }, [headersRef]);

  useEffect(() => {
    window.addEventListener('resize', handleScrollCaptureLeft);
    return () => {
      window.removeEventListener('resize', handleScrollCaptureLeft);
    };
  });

  const handleScrollLeft = () => {
    if (headersRef.current) {
      headersRef.current.scrollLeft -= 150;
    }
  };
  const handleScrollRight = () => {
    if (headersRef.current) {
      headersRef.current.scrollLeft += 150;
    }
  };
  return (
    <div className={`${style.tabs}  ${className}`}>
      <div
        className={`${style.headersContainer} ${style.headersContainerFloating} ${
          scrollable ? style.scrollable : ''
        } ${floatingHeader ? style.floatingHeader : ''}`}
      >
       <div
          ref={headersRef}
          onScrollCapture={handleScrollCaptureLeft}
          className={`${style.headers} ${style.floatingHeaders} ${TabSizes[size]}  tabsHeader`}
        >
          {headers.map((header, i) => (
            <span
              key={i}
              className={`${style.header} ${activeTab === i && style.active}`}
              onClick={() => setActiveTab(i)}
            >
              {header}
            </span>
          ))}
        </div>
      </div>
      <div
        className={`${style.headersContainer} ${
          scrollable ? style.scrollable : ''
        }`}
      >
        <div
          className={`${style.scrollLeft} ${
            scrollLeftDisabled ? style.disabled : ''
          }`}
        >
          <Button
            icon={<ChevronLeft />}
            size={'small'}
            rounded={'large'}
            type={'transparent'}
            onClick={handleScrollLeft}
          />
        </div>
        <div
          ref={headersRef}
          onScrollCapture={handleScrollCaptureLeft}
          className={`${style.headers} ${TabSizes[size]}  tabsHeader`}
        >
          {headers.map((header, i) => (
            <span
              key={i}
              className={`${style.header} ${activeTab === i && style.active}`}
              onClick={() => setActiveTab(i)}
            >
              {header}
            </span>
          ))}
        </div>
        <div
          className={`${style.scrollRight} ${
            scrollRightDisabled ? style.disabled : ''
          }`}
        >
          <Button
            icon={<ChevronRight />}
            size={'small'}
            rounded={'large'}
            type={'transparent'}
            onClick={handleScrollRight}
          />
        </div>
      </div>
        {bottomLine === true ? (
          <hr/>
        ) : null
      }
      <div>
        {React.Children.map(children, (child, i) => (
          <>
            {activeTab === i ? (
              <span key={i} className={`${style.body} `}>
                {child}
              </span>
            ) : null}
          </>
        ))}
      </div>
    </div>
  );
}

Tabs.propTypes = {
  headers: propTypes.array,
  size: propTypes.string,
  children: propTypes.array,
  className: propTypes.string,
  floatingHeader: propTypes.bool,
  bottomLine: propTypes.bool,
};

export default Tabs;
