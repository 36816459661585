import React from 'react';
import style from './noteCard.module.scss';
import propTypes from 'prop-types';
import { stringTrimmer } from 'helpers/strings';

function NoteCard({ title, date, description, ...noteCardProps }) {
  return (
    <div className={style.noteCard} {...noteCardProps}>
      <div className={style.header}>
        <span>{title && stringTrimmer(title, 150)}</span>
        <span>{date}</span>
      </div>
      <p>{description && stringTrimmer(description, 250)}</p>
    </div>
  );
}

NoteCard.propTypes = {
  title: propTypes.string,
  date: propTypes.string,
  description: propTypes.string
};

export default NoteCard;
