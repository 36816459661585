import Button from 'components/Button/Button';
import TextArea from 'components/Input/TextArea';
import React from 'react';
import { useState } from 'react';
import style from './createNoteForm.module.scss';
import validateNote from './createNoteValidator';
import propTypes from 'prop-types';
import { useEffect } from 'react';
import {
  createPortalNote,
  updatePortalNote,
  deletePortalNote
} from 'state/actions/actions';
import { useDispatch } from 'react-redux';
import Modal from 'components/Modal/Modal';

function CreateNoteForm({ closeModal, title, description, id, date, active }) {
  const dispatch = useDispatch();
  const [formState, setFormState] = useState({
    title: '',
    description: '',
    errors: { title: '', description: '' }
  });
  const isNew = id ? false : true;

  useEffect(() => {
    //Set the state of the inputs with the note clicked (props)
    setFormState({
      ...formState,
      title: title,
      description: description,
      errors: { title: '', description: '' }
    });
  }, [title, description]);

  const onChange = e => {
    const target = e.target;
    setFormState({
      ...formState,
      [target.name]: target.value,
      errors: { ...formState.errors, [target.name]: '' }
    });
  };

  const onSubmit = () => {
    //Check if there's errors
    const { errors, isValid } = validateNote(formState);
    if (!isValid) setFormState({ ...formState, errors });
    //Send the updated note to the endpoint
    else {
      if (isNew) {
        dispatch(
          createPortalNote(closeModal, {
            title: formState.title,
            description: formState.description
          })
        );
      } else {
        dispatch(
          updatePortalNote(closeModal, {
            title: formState.title,
            description: formState.description,
            id,
            date
          })
        );
      }
    }
  };

  const deleteNote = () => {
    dispatch(deletePortalNote(closeModal, id));
  };

  return (
    <Modal
      active={active}
      closeModal={closeModal}
      title={id ? 'Edit note' : 'Create note'}
      footer={
        <>
          <div className={style.buttonContainer}>
            {id && <Button text='Delete' type='danger' onClick={deleteNote} />}
            <Button
              text={isNew ? 'Create' : 'Save'}
              onClick={onSubmit}
              type='primaryLight'
              disabled={
                title === formState.title && description === formState.description
              }
            />
          </div>
        </>
      }
    >
      <div className={style.formContainer}>
        <form>
          <TextArea
            label='Note Title'
            className={style.input}
            name='title'
            value={formState.title}
            onChange={onChange}
            error={formState.errors.title}
            type='text'
            maxLength={255}
            placeholder='Note title'
            autoFocus
          />
          <TextArea
            label='Description'
            className={style.textarea}
            name='description'
            value={formState.description}
            onChange={onChange}
            error={formState.errors.description}
            type='text'
            maxLength={2000}
            placeholder='Add a description'
          />
        </form>
      </div>
    </Modal>
  );
}

CreateNoteForm.propTypes = {
  active: propTypes.bool,
  closeModal: propTypes.func,
  title: propTypes.string,
  description: propTypes.string,
  id: propTypes.string,
  date: propTypes.string
};

export default CreateNoteForm;
