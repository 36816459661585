export default function getStatesLicenseNumbers(user) {
  return states.filter(state => {
    if (state === 'Texas') {
      return user[`${state}_License_Informatio`];
    } else {
      return user[`${state}_License_Number`];
    }
  });
}

let states = [
  'Alabama',
  'Alaska',
  'American Samoa',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'D_C',
  'Delaware',
  'Florida',
  'Georgia',
  'Guam',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachussetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New_Hampshire',
  'New_Jersey',
  'New_Mexico',
  'New_York',
  'North_Carolina',
  'North_Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rode_Island',
  'South_Carolina',
  'South_Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West_Virginia',
  'Wisconsin',
  'Wyoming'
];
