import {
  IS_UPDATING_PREFERENCES,
  SET_AGENCIES_TREE,
  SET_AGENCY_DATA,
  RESET_AGENCY_DATA,
  IS_SENDING_ONBOARDING_FORM,
  SET_MULTIPLE_AGENCIES_DATA,
  RESET_MULTIPLE_AGENCIES_DATA,
  SET_AGENCY_DASHBOARD_AGENTS_DATA,
  RESET_AGENCY_DASHBOARD_AGENTS_DATA,
  SET_AGENTS_APPOINTMENTS_COUNT,
  SET_COMISSIONS_TAB_AGENTS
} from 'constants/types';

export const AGENCY_DATA_INITIAL_STATE = {
  Account_Name: 'Agency name',
  Agency_Email: '---',
  Phone: '---',
  NPN: '---',
  Agent: {
    name: '---'
  },
  Pending_Count: 0,
  // preferences
  Hide_ACA: false,
  Hide_ACA_Other: false,
  Hide_Annuity: false,
  Hide_Disability: false,
  Hide_Group: false,
  Hide_Life: false,
  Hide_Long_term_Health: false,
  Hide_Medicare: false,
  Hide_Off_Market: false,
  Hide_Bonus_Rates_from_Agents: false,
  Hide_Commission_Rates_from_Agents: false,
  Hide_O_Neill_Insurance_Email_Creation: false,
  // agency information
  Business_Agency_Specific_Link: null,
  Exclude_downline_agents_from_OM_Comunications: '',
  Agent_Emails_to_be_excluded: null,
  Specific_Requests: null,
  Pay_downline_agents_directly_or_to_Business_Agency: null,
  How_will_agents_be_contracted: null,
  Downline_commission_rates: null,
  Medicare_email_ok: false
};

const INITIAL_STATE = {
  agencyData: {
    agencyInformation: AGENCY_DATA_INITIAL_STATE,
    agents_per_agency: []
  },
  comissions_tab_agents: {},
  agenciesTree: [],
  isUpdatingPreferences: false,
  isSendingOnboardingForm: false,
  multipleAgenciesData: [],
  agencyDashboardAgents: [],
  Confirmed_Appointments_Count: 0
};

function agencyManagementReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_AGENCIES_TREE:
      return {
        ...state,
        agenciesTree: action.payload
      };
    case SET_AGENCY_DATA:
      return {
        ...state,
        agencyData: action.payload
      };
    case RESET_AGENCY_DATA:
      return {
        ...state,
        agencyData: INITIAL_STATE.agencyData
      };
    case IS_UPDATING_PREFERENCES:
      return {
        ...state,
        isUpdatingPreferences: action.payload
      };
    case IS_SENDING_ONBOARDING_FORM:
      return {
        ...state,
        isSendingOnboardingForm: action.payload
      };
    case SET_MULTIPLE_AGENCIES_DATA:
      return {
        ...state,
        multipleAgenciesData: action.payload
      };
    case RESET_MULTIPLE_AGENCIES_DATA:
      return {
        ...state,
        multipleAgenciesData: []
      };
    case SET_AGENCY_DASHBOARD_AGENTS_DATA:
      return {
        ...state,
        agencyDashboardAgents: action.payload
      };
    case RESET_AGENCY_DASHBOARD_AGENTS_DATA:
      return {
        ...state,
        agencyDashboardAgents: []
      };
    case SET_AGENTS_APPOINTMENTS_COUNT:
      return {
        ...state,
        Confirmed_Appointments_Count: action.payload
      };
    case SET_COMISSIONS_TAB_AGENTS:
      return {
        ...state,
        comissions_tab_agents: action.payload
      };
    default:
      return state;
  }
}

export default agencyManagementReducer;
