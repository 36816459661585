/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import Close from 'components/svg/icons/Close';
import ReactDom from 'react-dom';
import style from './modal.module.scss';
import LoadingContent from 'components/Loader/LoadingContante/LoadingContent';

const ModalSizes = {
  auto: style.modalAuto,
  small: style.modalSmall,
  medium: style.modalMedium,
  large: style.modalLarge,
  larger: style.modalLarger
};

function Modal({
  active,
  closeModal,
  className,
  title,
  footer,
  isLoading,
  size = 'medium',
  children,
  alert = false
}) {
  useEffect(() => {
    const close = e => {
      if (e.keyCode === 27) {
        closeModal();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);
  if (!active) return null;

  return ReactDom.createPortal(
    <div className={`${style.modal} ${active && style.active} ${className}`}>
      <div className={style.backdrop} />
      <div
        className={`${style.modalCard} ${ModalSizes[size]} ${
          alert ? style.alert : ''
        } `}
      >
        <div className={style.modalHeader}>
          <div className={style.title}>{title}</div>
          <button className={style.closeButton} onClick={closeModal}>
            <Close />
          </button>
        </div>

        <div
          className={`${style.modalContent} ${
            isLoading ? style.isLoading : ''
          }`}
        >
          {isLoading ? <LoadingContent /> : children}
        </div>
        {footer && <div className={style.modalFooter}>{footer}</div>}
      </div>
    </div>,
    document.getElementById('modalPortal')
  );
}

export default Modal;
