import React from 'react';
import Button from 'components/Button/Button';
import style from './carrierResources.module.scss';
import propTypes from 'prop-types';
import { useState } from 'react';
import ContactCard from './ContactCard/ContactCard';
import HiddenSideSection from 'views/MyAppointments/HiddenSideSection/HiddenSideSection';
import { useEffect } from 'react';
import CarrierCard from './CarrierCard/CarrierCard';
import MainSectionCarriers from '../../components/MainSectionCarriers/MainSectionCarriers';
import Tabs from 'components/Tabs/Tabs';
import axios from 'axios';
import Card from 'components/Card/Card';
import { ArrowBack, OpenInNew } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import CompanyName from 'constants/companyNames';
import Accordion from '../../components/Accordion/Accordion';

function CarrierResources({
  carriers,
  appointedCarriers,
  carrierFaqs,
  getAppointmentsViewData,
  getCarrierFaqs,
  setLocalLoader,
  localLoader,
  carrierLoader
}) {

  const [message, setMessage] = useState('');
  const [active, setActive] = useState({
    name: '',
    type: ''
  });
  const [currentCarrier, setCurrentCarrier] = useState({});
  const cancelTokenSource = axios.CancelToken.source();
  const [floatingTabs, setFloatingTabs] = useState(false);

  useEffect(() => {
    getAppointmentsViewData(setLocalLoader, cancelTokenSource,true);
    return () => {
      cancelTokenSource.cancel('API Call Canceled');
    };
  }, []);


  useEffect(() => {
    if (active.type) {
      setMessage('No information available for this carrier');
      setCurrentCarrier(carriers[active.type][active.name]);
    }
  }, [active.type, active.name]);
  
  useEffect(() => {
    if (currentCarrier.id) {
      console.log(carrierLoader);
      getCarrierFaqs(true, cancelTokenSource, currentCarrier.id);
      return () => {
        cancelTokenSource.cancel('API Call Canceled');
      };
    }
  }, [currentCarrier]);
  

  const handleOpenLink = url => {
    window.open(url, 'blank');
  };

  const handleScroll = e => {
    // console.log(e.target.scrollTop);
    if (e.target.scrollTop > 180) {
      setFloatingTabs(true);
    } else {
      setFloatingTabs(false);
    }
  };

  const handleResetActiveCarrier = () => {
    setActive({
      name: '',
      type: ''
    });
  };
  return (
    <div
      className={`${style.carrierResources} ${
        active.name ? style.isActive : ''
      }`}
    >
      <div className={style.carrierResourcesItem}>
        <Card
          fill
          title='Carrier Resources'
          onScrollCapture={handleScroll}
          // options={
          //   <Button
          //     size='small'
          //     type='primaryLight'
          //     rounded={'large'}
          //     text={'Request Appointments'}
          //     onClick={() =>
          //       handleOpenLink(
          //         `https://forms.oneillmarketing.net/oneillmarketing/form/IndividualAppointmentRequestForm/formperma/cJer2y2EfRB6W8F6Al74YPryxKlkdl1kzBnw1hH1eik?npnField98221=${
          //           user.NPN_Text_Field
          //         }&ffm=${user.ffm_ms[0] && user.ffm_ms[0]}&ahip=${
          //           user.AHIP[0] && user.AHIP[0]
          //         }`
          //       )
          //     }
          //   />
          // }
        >
          <Tabs
            floatingHeader={floatingTabs}
            headers={['Appointed Carriers', 'All Carriers']}
          >
            <MainSectionCarriers
              carriers={appointedCarriers}
              active={active.name}
              setActive={setActive}
              Card={CarrierCard}
              CardNoStates={CarrierCard}
              localLoader={localLoader}
              message={
                `No appointed carriers - Click on the "All Carriers" tab above to view all ${CompanyName.short} carriers`
              }
            />
            <MainSectionCarriers
              carriers={carriers}
              active={active.name}
              setActive={setActive}
              Card={CarrierCard}
              CardNoStates={CarrierCard}
              localLoader={localLoader}
            />
          </Tabs>
        </Card>
      </div>
      <div className={style.detailsItem}>
        {active.type &&
        (currentCarrier.carrierBrokerPortal ||
          currentCarrier.brokerSupportNumber ||
          currentCarrier.memberSupportNumber ||
          currentCarrier.description ||
          currentCarrier.enrollmentPortal ||
          currentCarrier.omWebsiteLink) ? (
          <Card
            fill
            title={active.name}
            leftOptions={
              <Button
                size='small'
                type='default'
                icon={<ArrowBack />}
                rounded='large'
                outlined
                onClick={handleResetActiveCarrier}
              />
            }
            options={
              currentCarrier.website && (
                <Button
                  size='xsmall'
                  type='secondary'
                  icon={<OpenInNew />}
                  rounded={'large'}
                  text={'Website'}
                  onClick={() => handleOpenLink(currentCarrier.website)}
                />
              )
            }
          >
            <section className={style.sideSection}>
              <div className={style.description}>
                <h2>Description</h2>
                {currentCarrier.description ? (
                  <p>{currentCarrier.description}</p>
                ) : (
                  'No description available'
                )}
              </div>

              {(currentCarrier.brokerSupportNumber ||
                currentCarrier.memberSupportNumber) && (
                <div className={style.contactInfo}>
                  <h2>Contact Information</h2>
                  <div>
                    {currentCarrier.brokerSupportNumber && (
                      <ContactCard
                        title='Broker Support Phone Number'
                        tel={currentCarrier.brokerSupportNumber}
                      />
                    )}
                    {currentCarrier.memberSupportNumber && (
                      <ContactCard
                        title='Member Support Phone Number'
                        tel={currentCarrier.memberSupportNumber}
                      />
                    )}
                    {currentCarrier.Broker_Support_Email && (
                      <ContactCard
                        title='Broker Support Email'
                        tel={currentCarrier.Broker_Support_Email}
                        hasButton={false}
                      />
                    )}
                    {currentCarrier.Broker_Commission_Email && (
                      <ContactCard
                        title='Broker Commission Email'
                        tel={currentCarrier.Broker_Commission_Email}
                        hasButton={false}
                      />
                    )}
                  </div>
                </div>
              )}
              <div className={style.externalLinks}>
                <h2>External links</h2>
                {currentCarrier.carrierBrokerPortal && (
                  <div>
                    <span>Broker portal</span>
                    <Button
                      type='secondary'
                      size='xsmall'
                      icon={<OpenInNew />}
                      text='Website'
                      rounded={'large'}
                      onClick={() =>
                        handleOpenLink(currentCarrier.carrierBrokerPortal)
                      }
                    />
                  </div>
                )}
                {currentCarrier.enrollmentPortal && (
                  <div>
                    <span>Enrollment portal</span>
                    <Button
                      type='secondary'
                      size='xsmall'
                      icon={<OpenInNew />}
                      text='Website'
                      rounded={'large'}
                      onClick={() =>
                        handleOpenLink(currentCarrier.enrollmentPortal)
                      }
                    />
                  </div>
                )}
                {currentCarrier.omWebsiteLink && (
                  <div>
                    <span>Website Link</span>
                    <Button
                      type='secondary'
                      size='xsmall'
                      icon={<OpenInNew />}
                      text='Website'
                      rounded={'large'}
                      onClick={() =>
                        handleOpenLink(currentCarrier.omWebsiteLink)
                      }
                    />
                  </div>
                )}
                  <>
                    <h2>Carrier FAQ&apos;s</h2>
                    <Accordion
                      ItemsArray={carrierFaqs && carrierFaqs.length > 0 ? carrierFaqs : []}
                      loading={carrierLoader}
                      tittle='Carrier FAQ&apos;s'
                    />
                  </>
              </div>
            </section>
          </Card>
        ) : (
          <>
            <HiddenSideSection text={message ? message : null} />
            {active.name && (
              <div className={style.closeButton}>
                <Tooltip title={'Close'}>
                  <div>
                    <Button
                      size='xsmall'
                      type='default'
                      icon={<ArrowBack />}
                      text={'Close'}
                      rounded='large'
                      outlined
                      onClick={handleResetActiveCarrier}
                    />
                  </div>
                </Tooltip>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

CarrierResources.propTypes = {
  carriers: propTypes.object,
  appointedCarriers: propTypes.object,
  carrierFaqs:propTypes.object,
  user: propTypes.object,
  getAppointmentsViewData: propTypes.func,
  getCarrierFaqs:propTypes.func,
  setLocalLoader: propTypes.func,
  localLoader: propTypes.bool,
  carrierLoader:propTypes.bool
};

export default CarrierResources;
