import Dropdown from 'components/Dropdown/Dropdown';
import React from 'react';
import style from '../sidebar.module.scss';
import styleSunshine from '../sidebarSunshine.module.scss';
import MenuGroup from '../MenuGroup/MenuGroup';
import MenuLink from '../MenuLink/MenuLink';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { USER_TYPES } from 'constants/enum';
import getCompanyVariables from 'helpers/getCompanyVariables';

const company = getCompanyVariables();

const SidebarContent = ({ activePage }) => {
  let styleSelected = '';
  const { type } = useParams();
  const zohoData = useSelector(state => state.user.zohoData);
  const showBookOfBusinessTab = zohoData.Book_of_Business_Tab === 'Show';
  const agentNPN = zohoData.NPN;
  switch (company) {
    case 'oneill':
      styleSelected = style;
      break;
    case 'sunshine':
      styleSelected = styleSunshine;
      break;

  }
  return (
    <div className={styleSelected.innerContainer}>
      <MenuGroup title='Dashboards'>
        <li>
          <MenuLink
            text='Home'
            icon='home'
            to='/'
            active={activePage === 'Home'}
          />
        </li>
        {company === 'oneill' ? <li>
          <MenuLink
            text='Carrier Partners'
            icon='marketing'
            to='/OMOfferings'
            active={activePage === 'OMOfferings'}
          />
        </li>: <li></li>}
        {/*<li>
          <MenuLink
            text='2024 ACA Guide'
            icon='marketing'
            to='/ACA2024'
            active={activePage === 'ACA2024'}
          />
       </li>*/}
      </MenuGroup>
      <MenuGroup title='Agent'>
      <li>
          <MenuLink
            text='My Profile'
            icon='agentInfo'
            to='/agentInfo'
            active={activePage === 'Agent Info'}
          />
        </li>
        {company == 'sunshine'? 
          <li>
          <MenuLink
                text='File Upload'
                icon='upload'
                to={`https://forms.zohopublic.com/sunshinelifehealthadvisors/form/FormUpload/formperma/GEa4stU9T5t0ce2TFm64Ou7qJL2l1iF8efi2zy26IZ8?npn=${agentNPN}`} 
                target='_blank'
                rel='noreferrer'
                external
                active={activePage === 'File Uploads'}
              />
          </li>:null}
        <li>
          <MenuLink
            text='Carrier Appointments'
            icon='carrierResources'
            to='/myAppointments'
            active={activePage === 'My Appointments'}
          />
        </li>
        <li>
          <MenuLink
            text='Carriers Resources'
            icon='myAppointments'
            to='/carrierResources'
            active={activePage === 'Carrier Resources'}
          />
       </li>
       {company === 'oneill' ? (
  (zohoData.Entire_Commissions_Tab === 'Force Show' ||
    (zohoData.Position_at_Agency === USER_TYPES.ADMIN &&
      zohoData.agencyData?.Parent_Account?.id === '4738157000010316014' &&
      zohoData.Entire_Commissions_Tab !== 'Hide') ||
    (!zohoData.Hide_Commission_Data &&
      zohoData.Entire_Commissions_Tab !== 'Hide' &&
      !zohoData.agencyData?.Hide_Commission_Rates_from_Agents)) && (
    <li>
      <Dropdown
        selected={activePage === 'Commissions'}
        mainComponent={
          <MenuLink
            key='commissionsHeader'
            text='Commissions'
            icon='commissions'
            onClick={e => {
              e.preventDefault();
            }}
            active={activePage === 'Commissions'}
          />
        }
      >
        <MenuLink
          key='statementsBody'
          text='Statements'
          icon='download'
          to='/commissions/Statements'
          active={type === 'Statements'}
        />
        {showBookOfBusinessTab ? (
          <MenuLink
            key='bookOfBusinessBody'
            text='Book of business'
            icon='download'
            to='/commissions/BookOfBusiness'
            active={type === 'BookOfBusiness'}
          />
        ) : null}

        <MenuLink
          key='commissionsBody'
          text='Rates'
          icon='commissionRates'
          to='/commissions/Commissions'
          active={type === 'Commissions'}
        />
        {(zohoData.Bonus_sub_tab === 'Force Show' ||
          (zohoData.Position_at_Agency === USER_TYPES.ADMIN &&
            zohoData.agencyData?.Parent_Account?.id ===
              '4738157000010316014' &&
            zohoData.Bonus_sub_tab !== 'Hide') ||
          (zohoData.Bonus_sub_tab !== 'Hide' &&
            !zohoData.agencyData?.Hide_Bonus_Rates_from_Agents)) && (
          <MenuLink
            key='bonusesBody'
            text='Bonuses'
            icon='bonus'
            to='/commissions/Bonus'
            active={type === 'Bonus'}
          />
        )}
        <MenuLink
          key='statementsBody'
          text='Old Pre-July 2023 Statements'
          icon='download'
          to='/commissions/OldStatements'
          active={type === 'OldStatements'}
        />
      </Dropdown>
    </li>
  )
) : null}
        {/* <li>
          <MenuLink
            text='Leads'
            icon='lead'
            to='/Leads'
            active={activePage === 'Leads'}
          />
        </li> */}
        {/*<li>
          <MenuLink
            text='Action Items'
            icon='actionItems'
            to='/actionItems'
            active={activePage === 'Action Items'}
          />
      </li>*/}
      </MenuGroup>
      {zohoData.Position_at_Agency === USER_TYPES.ADMIN &&
        zohoData.Account_Name && (
          <MenuGroup title='Agency'>
            <li>
              <MenuLink
                text='Agency management'
                icon='agency'
                to='/agencies'
                active={activePage === 'Agency Management'}
              />
            </li>
          </MenuGroup>
        )}
     {/*<MenuGroup title='Account'>
      </MenuGroup>*/}
      <MenuGroup title='General'>
      <li>
          <Dropdown
            selected={activePage === 'Files'}
            mainComponent={
              <MenuLink
                key='filesHeader'
                text='Resources'
                icon='files'
                active={activePage === 'Files'}
                onClick={e => {
                  e.preventDefault();
                }}
              />
            }
          >
            <MenuLink
              key='PublicFolder'
              text='Public Folder'
              icon='web'
              to='/files/Public'
              active={type === 'Public'}
            />
            <MenuLink
              key='PersonalFolder'
              text='Personal Folder'
              icon='agentInfo'
              to='/files/Personal'
              active={type === 'Personal'}
            />
          </Dropdown>
        </li>
       <li>
        <MenuLink
              text='FAQs'
              icon='info'
              to='/faqs'
              active={activePage === 'FAQs'}
            />
        </li>
        {/*<li>
          <MenuLink
            text='Marketing'
            icon='marketing'
            to='/marketing'
            active={activePage === 'Marketing'}
          />
        </li>
        <li>
          <MenuLink
            text='OM Products'
            icon='oMProducts'
            to='https://oneillmarketing.net/our-products/'
            target='_blank'
            rel='noreferrer'
            external
            active={activePage === 'OM Products'}
          />
        </li>*/}
        {company === 'oneill' ?
        <li>
          <Dropdown
            selected={activePage === 'Training'}
            mainComponent={
              <MenuLink
                key='Training'
                text='Training'
                icon='actionItems'
                active={activePage === 'Training'}
                onClick={e => {
                  e.preventDefault();
                }}
              />
            }
          >
            <MenuLink
              key='Register'
              text='Register'
              icon='web'
              target='_blank'
              rel='noreferrer'
              external
              to='https://forms.zohopublic.com/oneillmarketing/form/ONeillMarketingTrainingRegistration/formperma/iCv0XfEiM1jXUw9juDngkju8D0f5UrTnsbQGFBlqHfY'
              active={type === 'Public'}
            />
            <MenuLink
              target='_blank'
              rel='noreferrer'
              external
              key='Login'
              text='Login Here'
              icon='agentInfo'
              to='https://trainingcentral.litmos.com/account/login/?'
              active={type === 'Personal'}
            />
          </Dropdown></li>: <li></li>}
      </MenuGroup>
    </div>
  );
};
SidebarContent.propTypes = {
  activePage: PropTypes.string
};

export default React.memo(SidebarContent);
