import React from 'react';

function ActionItems() {
  return (
    <svg viewBox='0 0 16 15.273'>
      <circle cx='2' cy='2' r='2' transform='translate(5.642)' fill='#9d9d9d' />
      <path
        d='M17.273,20.421h-.727v-2.14a.72.72,0,0,0-.727-.713H10.727V16.5h2.545V15.07A7.54,7.54,0,0,0,10,14a7.54,7.54,0,0,0-3.273,1.07V16.5H9.273v1.07H4.182a.72.72,0,0,0-.727.713v2.14H2.727A.72.72,0,0,0,2,21.134v2.854a.72.72,0,0,0,.727.713H5.636a.72.72,0,0,0,.727-.713V21.134a.72.72,0,0,0-.727-.713H4.909V18.994H9.273v1.427H8.545a.72.72,0,0,0-.727.713v2.854a.72.72,0,0,0,.727.713h2.909a.72.72,0,0,0,.727-.713V21.134a.72.72,0,0,0-.727-.713h-.727V18.994h4.364v1.427h-.727a.72.72,0,0,0-.727.713v2.854a.72.72,0,0,0,.727.713h2.909A.72.72,0,0,0,18,23.988V21.134A.72.72,0,0,0,17.273,20.421ZM4.909,23.274H3.455V21.848H4.909Zm5.818,0H9.273V21.848h1.455Zm5.818,0H15.091V21.848h1.455Z'
        transform='translate(-2 -9.429)'
        fill='#9d9d9d'
      />
    </svg>
  );
}

export default ActionItems;
