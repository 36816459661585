import React from 'react';
import style from './secondaryPage.module.scss';
import propTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import Button from 'components/Button/Button';

function SecondaryPage({ children }) {
  const navigate = useNavigate();

  return (
    <div className={style.secondaryPage}>
      <div className={style.backArrow} onClick={() => navigate(-1)}>
        <Button
          text={'Go back'}
          type={'secondary'}
          size={'small'}
          icon={<ArrowBack />}
        />
      </div>
      {children}
    </div>
  );
}

SecondaryPage.propTypes = {
  children: propTypes.element
};

export default SecondaryPage;
