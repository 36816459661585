import React, { useRef, useState } from 'react';
import Button from 'components/Button/Button';
import style from './leadsFormModal.module.scss';
import { buttonStyling } from 'constants/enum';
import propTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ModifyLead } from 'state/actions/actions';
import Loader from 'components/Loader/Loader';
import Modal from 'components/Modal/Modal';
import ConfirmationForm from '../ConfirmationForm/ConfirmationForm';
import { TextField, Box } from '@mui/material';

function LeadsFormModal({ active, setModalState, ...leadData }) {
  const {
    id,
    name,
    dateOfBirth,
    phone,
    email,
    status,
    note,
    type,
    created,
    modified,
    responseTime,
    hasBeenClosed,
    sourcePortal,
    category
  } = leadData;

  const dispatch = useDispatch();
  const [formState, setFormState] = useState({
    status,
    note
  });
  const [confirmationModalState, setConfirmationModalState] = useState({
    active: false
  });
  const [loading, setLoading] = useState(false);
  const confirmed = useRef(false);

  const onChange = e => {
    let target = e.target;
    setFormState({ ...formState, [target.name]: target.value });
  };

  const onSubmit = () => {
    if (formState.status.includes('Closed') && !confirmed.current) {
      setConfirmationModalState({ ...confirmationModalState, active: true });
    } else {
      setLoading(true);
      dispatch(
        ModifyLead(
          id,
          formState.note,
          formState.status,
          setModalState,
          setLoading
        )
      );
    }
  };

  const onConfirmed = () => {
    confirmed.current = true;
    onSubmit();
  };

  return (
    <Modal
      size='medium'
      active={active}
      closeModal={setModalState}
      title={'Leads'}
      footer={
        <>
          <Button
            text='Save'
            className={`${buttonStyling["O'Neill"]} ${buttonStyling.hover}`}
            disabled={status === formState.status && note === formState.note}
            onClick={onSubmit}
          />
          <Button
            text='Cancel'
            outlined
            className={`${buttonStyling.Self} ${buttonStyling.hover}`}
            onClick={setModalState}
          />
        </>
      }
    >
      {loading ? (
        <Loader className={style.loader} />
      ) : (
        <>
          <div className={style.leadsModalForm}>
            <div className={style.inlineContainer}>
              <div>
                <span className={style.fieldName}>Name</span>
                <p>{name}</p>
              </div>
              <div>
                <span className={style.fieldName}>Date of Birth</span>
                <p>{dateOfBirth}</p>
              </div>
            </div>
            <div className={style.inlineContainer}>
              <div>
                <span className={style.fieldName}>Phone</span>
                <p>{phone}</p>
              </div>
              <div>
                <span className={style.fieldName}>Email</span>
                <p>{email}</p>
              </div>
            </div>
            <div className={style.separator}>
              <div className={style.inlineContainer}>
                <div>
                  <span className={style.fieldName}>Created Date</span>
                  <p>{created}</p>
                </div>
                <div>
                  <span className={style.fieldName}>Modified Date</span>
                  <p>{modified}</p>
                </div>
              </div>
              <div className={style.inlineContainer}>
                {sourcePortal && (
                  <div>
                    <span className={style.fieldName}>Source</span>
                    <p>{sourcePortal}</p>
                  </div>
                )}
                {category && (
                  <div>
                    <span className={style.fieldName}>Category</span>
                    <p>{category}</p>
                  </div>
                )}
              </div>
              {type && (
                <div className={style.dataDisplayer}>
                  <span className={style.fieldName}>Type</span>
                  <p>{type}</p>
                </div>
              )}
            </div>
            <div className={style.status}>
              <span className={style.fieldName}>Status</span>
              <select
                name='status'
                value={formState.status}
                onChange={onChange}
                disabled={hasBeenClosed || status.includes('Closed')}
              >
                {statusArray.map((statusItem, i) => (
                  <option key={statusItem + i} value={statusItem}>
                    {statusItem}
                  </option>
                ))}
                {(responseTime ||
                  (status.includes('Contact') && status !== 'Not Contacted') ||
                  status.includes('Closed')) && (
                  <>
                    <option value='Closed Won'>Closed Won</option>
                    <option value='Closed Lost'>Closed Lost</option>
                  </>
                )}
              </select>
            </div>

            <Box
              component='form'
              sx={{ '& .MuiTextField-root': { width: '100%' } }}
            >
              <TextField
                sx={{ '& .MuiInputBase-multiline': { p: '16.5px 14px' } }}
                id='notetext'
                label='Note text'
                name='note'
                multiline
                rows={4}
                value={formState.note}
                onChange={onChange}
                variant='outlined'
                margin='normal'
              />
            </Box>
            {/* <TextArea
              className={style.textArea}
              label='Note'
              name='note'
              value={formState.note}
              onChange={onChange}
            /> */}
          </div>
          <Modal
            active={confirmationModalState.active}
            className={style.confirmationModal}
            closeModal={() =>
              setConfirmationModalState({
                ...confirmationModalState,
                active: false
              })
            }
          >
            <ConfirmationForm
              onConfirmed={onConfirmed}
              onClose={() =>
                setConfirmationModalState({
                  ...confirmationModalState,
                  active: false
                })
              }
            />
          </Modal>
        </>
      )}
    </Modal>
  );
}

LeadsFormModal.propTypes = {
  active: propTypes.bool,
  setModalState: propTypes.func,
  id: propTypes.string,
  name: propTypes.string,
  dateOfBirth: propTypes.string,
  phone: propTypes.string,
  email: propTypes.string,
  status: propTypes.string,
  type: propTypes.string,
  note: propTypes.string,
  created: propTypes.string,
  modified: propTypes.string,
  responseTime: propTypes.number
};

export default LeadsFormModal;

let statusArray = ['Attempted To Contact', 'Contacted', 'Not Contacted'];
