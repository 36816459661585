import React from 'react';
import ReactDOM from 'react-dom';
import { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideAlert } from 'state/actions/actions';
import style from './alert.module.scss';

function Alert() {
  const alert = useSelector(state => state.site.alert);
  const dispatch = useDispatch();
  const timer = useRef(null);

  useEffect(() => {
    clearTimeout(timer.current);
    if (alert.active) {
      timer.current = setTimeout(() => {
        dispatch(hideAlert());
      }, 3000);
    }
  }, [alert.message, alert.active]);

  return ReactDOM.createPortal(
    <div
      className={`${style.alert} ${alert.active && style.active} ${
        style[alert.code]
      }`}
    >
      <span>{alert.message}</span>
    </div>,
    document.getElementById('loaderPortal')
  );
}

export default Alert;
