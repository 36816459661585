import {
  CLEAN_MARKETING_ANNOUNCEMENTS,
  GET_MARKETING_ANNOUNCEMENTS
} from 'constants/types';

const INITIAL_STATE = {
  announcements: []
};

function marketingAnnouncementsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_MARKETING_ANNOUNCEMENTS:
      return { ...state, announcements: action.payload };
    case CLEAN_MARKETING_ANNOUNCEMENTS:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export default marketingAnnouncementsReducer;
