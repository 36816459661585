import withLocalLoader from 'components/HOCs/withLocalLoader';
import { connect } from 'react-redux';
import {
  getSpecificAgencyData,
  UpdateAgencyFormPreferences,
  SendAgencyFormEmail
} from 'state/actions/actions';
import AgencySettings from './AgencySettings';

function mapStateToProps({ agencyManagement, user }) {
  return {
    agencyData: agencyManagement.agencyData,
    zohoData: user.zohoData
  };
}

export default connect(mapStateToProps, {
  getSpecificAgencyData,
  UpdateAgencyFormPreferences,
  SendAgencyFormEmail
})(withLocalLoader(AgencySettings, true, true, 'Agency Management'));
