import React from 'react';
import Button from 'components/Button/Button';
import style from './marketingCard.module.scss';
import propTypes from 'prop-types';

function MarketingCard({ title, description, url }) {
  const handleOpenLink = () => {
    window.open(url, 'blank');
  };

  return (
    <div className={style.marketingCard}>
      <div className={style.texts}>
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
      <Button text='Request now' onClick={handleOpenLink} />
    </div>
  );
}

MarketingCard.propTypes = {
  title: propTypes.string,
  description: propTypes.string,
  url: propTypes.string
};

export default MarketingCard;
