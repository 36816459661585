import React from 'react';
import propTypes from 'prop-types';
import style from './formWithEditButton.module.scss';
import Button from 'components/Button/Button';
import { EditOutlined } from '@mui/icons-material';

function FormWithEditButton({ children, className, onClick,uploadFiles }) {
  return (
    <div className={className}>
      <Button
        size='medium'
        type='primary'
        text={uploadFiles ? 'Upload' : 'Edit'}
        className={style.editButton}
        onClick={onClick}
        icon={<EditOutlined />}
      />
      {children}
    </div>
  );
}

FormWithEditButton.propTypes = {
  children: propTypes.element,
  onClick: propTypes.func,
  className: propTypes.string,
  uploadFiles:propTypes.bool
};

export default FormWithEditButton;
