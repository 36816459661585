import React from 'react';

function Edit() {
  return (
    <svg viewBox='0 0 20 20'>
      <path
        d='M23.692,11.389l-7.047-7.1a.95.95,0,0,0-1.4,0L4.3,15.238a1,1,0,0,0-.3.7v7.047a1,1,0,0,0,1,1h7.047a1,1,0,0,0,.7-.3l10.946-10.9a.95.95,0,0,0,0-1.4Zm-12.045,10.6H6V16.337l6.248-6.2,5.6,5.648Zm7.647-7.6L13.646,8.74l2.3-2.349,5.648,5.7Z'
        transform='translate(-4 -3.984)'
      />
    </svg>
  );
}

export default Edit;
