export default function (announcement) {
  return {
    title: announcement.Announcment_Title,
    body: announcement.Announcement_Body,
    image: announcement.Image,
    url: announcement.External_Link,
    objectFit: announcement.Image_Fit,
    showFade:announcement.Show_Background_Fade
  };
}
