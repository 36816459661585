import React, { useState } from 'react';
//HELPERS
import style from './loginForm.module.scss';
import loginFormValidator from './loginFormValidator';
//COMPONENTS
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
//EXTERNALS
// import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AuthenticationLogin } from 'state/actions/actions';

function LoginForm() {
  const isLoggingIn = useSelector(state => state.authentication.isLoggingIn);
  const dispatch = useDispatch();

  const [formState, setFormState] = useState({
    email: '',
    password: '',
    errors: {
      email: '',
      password: ''
    }
  });

  const onInputChange = e => {
    let target = e.target;
    setFormState({
      ...formState,
      [target.name]: target.value,
      errors: { ...formState.errors, [target.name]: '' }
    });
  };

  const onSubmit = e => {
    e.preventDefault();
    const { errors, isValid } = loginFormValidator(formState);
    console.log('--------------');
    console.log(errors);
    console.log(isValid);
    if (!isValid) {
      setFormState({ ...formState, errors });
      console.log('a');
    } else {
      console.log('b');
      dispatch(AuthenticationLogin(formState, setFormState));
    }
  };

  return (
    <form className={style.genericForm}>
      <Input
        name='email'
        label='E-mail'
        type='mail'
        error={formState.errors.email}
        placeholder='Enter your e-mail'
        onChange={onInputChange}
        autoFocus
        margin='dense'
      />
      <Input
        name='password'
        label='Password'
        type='password'
        error={formState.errors.password}
        placeholder='Enter your password'
        onChange={onInputChange}
        margin='dense'
      />
      <Button
        isLoading={isLoggingIn}
        disabled={isLoggingIn}
        text={'login'}
        type={'primary'}
        size={'medium'}
        rounded={'large'}
        fill
        className={style.formButton}
        onClick={onSubmit}
        margin='dense'
      />
    </form>
  );
}

export default LoginForm;
