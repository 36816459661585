import endpoints from 'constants/endpoints';
import { GET_NOTIFICATIONS } from 'constants/types';
import { notificationsObjectFactory } from 'helpers/factory/notificationFactory';
import { safePostCall } from 'helpers/firebaseSetup';

export const getNotifications = () => (dispatch, getState) => {
  let notifications = getState().notifications.notifications;
  if (!(Object.keys(notifications).length > 0)) {
    safePostCall(endpoints.awsProxy.safePost, {
      endpoint: endpoints.zoho.getNotifications
    }).then(notificationsRes => {
      console.log('============');
      console.log(notificationsRes);
      notificationsRes = JSON.parse(
        `[${notificationsRes.data.details.output}]`
      );
      if (notificationsRes.length > 0)
        dispatch({
          type: GET_NOTIFICATIONS,
          payload: notificationsObjectFactory(notificationsRes)
        });
      else
        dispatch({
          type: GET_NOTIFICATIONS,
          payload: { empty: 'Empty' }
        });
    });
  }
};
