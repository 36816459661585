import { ArrayToObject } from 'helpers/arrays';

export function carrierFactory(carrier, zohoData) {
  let exclude = false;
  if (
    (zohoData.agencyData.Hide_ACA && carrier.PrimaryCarrier === true) ||
    (zohoData.agencyData.Hide_ACA_Other && carrier.PrimaryCarrier === false)
  ) {
    exclude = true;
  } else if (
    (carrier.Appointment_Type === 'Life' &&
      zohoData.agencyData.Hide_Long_term_Health &&
      carrier.Name.includes('Long')) ||
    (carrier.Appointment_Type === 'Life' &&
      zohoData.agencyData.Hide_Disability &&
      carrier.Name.includes('Disability')) ||
    (carrier.Appointment_Type === 'Life' &&
      zohoData.agencyData.Hide_Annuity &&
      carrier.Name.includes('Annuity'))
  ) {
    exclude = true;
  }

  return {
    id: carrier.id,
    name: carrier.Name,
    type: carrier.Appointment_Type,
    description: carrier.Description,
    Callidus_Carrier_Names: carrier?.Callidus_Carrier_Names?.split(',')?.map(
      c => c.trim()
    ),
    carrierBrokerPortal: carrier.Carrier_Broker_Portal,
    carrierFAQ:carrier.Carrier_FAQ,
    enrollmentPortal: carrier.Enrollment_Portal,
    brokerSupportNumber: carrier.Broker_Support_Number,
    memberSupportNumber: carrier.Member_Support_Number,
    Broker_Support_Email: carrier.Broker_Support_Email,
    Broker_Commission_Email: carrier.Broker_Commission_Email,
    omWebsiteLink: carrier.OM_Website_Link,
    website: carrier.Website,
    AgencyBloc_CarrierID: carrier.AgencyBloc_CarrierID,
    statesOffered: carrier.States_Offered,
    primaryCarrier: carrier.PrimaryCarrier,
    customNoteNewAppointment: carrier.Custom_Note_New_Appointment,
    customNoteTransferDirectwithCarrier:
      carrier.Custom_Note_Transfer_Direct_with_Carrier,
    customNoteTransferThroughAnotherAgency:
      carrier.Custom_Note_Transfer_Through_Another_Agency,
    exclude
  };
}

export function carriersObjectFactory(carriers) {
  //Filters carriers by type
  let Marketplace = carriers.filter(carrier => carrier.type === 'Marketplace');

  let OffMarketHealth = carriers.filter(
    carrier => carrier.type === 'Off-Market Health'
  );

  let Life = carriers.filter(carrier => carrier.type === 'Life');
  let Medicare = carriers.filter(carrier => carrier.type === 'Medicare');
  let Group = carriers.filter(carrier => carrier.type === 'Group');
  //Build the carriers object for redux state
  return {
    Marketplace: ArrayToObject(Marketplace, 'name'),
    'Off-Market Health': ArrayToObject(OffMarketHealth, 'name'),
    Life: ArrayToObject(Life, 'name'),
    Medicare: ArrayToObject(Medicare, 'name'),
    Group: ArrayToObject(Group, 'name')
  };
}

export function appointedCarriersObjectFactory(carriers, agentData) {
  let appointedCarriers = [];
  Object.values(carriers).map(carriersArray => {
    appointedCarriers.push(
      Object.values(carriersArray).filter(carrier => {
        if (
          typeof agentData[carrier.name.split(' ').join('_')] === 'object' &&
          agentData[carrier.name.split(' ').join('_')].length === 0
        )
          return false;
        else if (agentData[carrier.name.split(' ').join('_')]) return true;
        else return false;
      })
    );
  });

  return {
    Marketplace: ArrayToObject(appointedCarriers[0], 'name'),
    'Off-Market Health': ArrayToObject(appointedCarriers[1], 'name'),
    Life: ArrayToObject(appointedCarriers[2], 'name'),
    Medicare: ArrayToObject(appointedCarriers[3], 'name'),
    Group: ArrayToObject(appointedCarriers[4], 'name')
  };
}
