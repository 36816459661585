import {
  CLEAN_USER,
  GET_USER,
  REFRESH_USER,
  SET_VIEWING_AS,
  SET_USER_DATA,
  RESET_VIEWING_AS,
  IS_SENDING_INFORMATION
} from 'constants/types';

const INITIAL_STATE = {
  zohoData: {},
  firebaseData: {},
  zohoId: '',
  mainZohoId: '',
  viewAsAgent: null,
  isSendingInformation: false
};

function userReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        ...action.payload
      };
    case SET_USER_DATA:
      return {
        ...state,
        ...action.payload.data,
        mainZohoId: action.payload.mainZohoId
      };
    case CLEAN_USER:
      return INITIAL_STATE;
    case REFRESH_USER:
      return {
        ...state,
        // ...action.payload
        zohoData: action.payload
      };
    case SET_VIEWING_AS:
      return {
        ...state,
        viewAsAgent: action.payload,
        zohoId: action.payload
      };
    case RESET_VIEWING_AS:
      return {
        ...state,
        viewAsAgent: '',
        zohoId: action.payload
      };
    case IS_SENDING_INFORMATION:
      return {
        ...state,
        isSendingInformation: action.payload
      };
    default:
      return state;
  }
}

export default userReducer;
