import React from 'react';
import propTypes from 'prop-types';
import style from './CollapsibleList.module.scss';
import { Link } from 'react-router-dom';
import Button from 'components/Button/Button';
import DropDownMenu from 'components/DropDownMenu/DropDownMenu';
import { MoreVert } from '@mui/icons-material';
import DropDownMenuItem from 'components/DropDownMenu/DropDownMenuItem/DropDownMenuItem';
import { Tooltip } from '@mui/material';

const CollapsibleItem = ({
  title,
  options,
  optionsArray,
  isOnlyOne = false
}) => {
  if (isOnlyOne) {
    return (
      <li className={style.item}>
        <div>
          <b>{title}</b>
        </div>
        <div className={style.options}>
          <div className={style.optionsLg2}>
            {optionsArray ? (
              optionsArray.map((option, index) => (
                <Link key={'option_' + index} to={option.to} replace>
                  <Button
                    type='primaryLight'
                    size='xsmall'
                    rounded={'large'}
                    text={option.text}
                    outlined
                    icon={option.icon}
                  />
                </Link>
              ))
            ) : (
              <div>{options}</div>
            )}
          </div>
          {optionsArray && (
            <div className={style.optionsSm}>
              <DropDownMenu
                title='Options'
                menuTrigger={
                  <Tooltip title='Options'>
                    <div>
                      <Button
                        size='medium'
                        type='transparent'
                        outlined
                        icon={<MoreVert />}
                      />
                    </div>
                  </Tooltip>
                }
              >
                {optionsArray.map((option, index) => (
                  <DropDownMenuItem
                    key={'option_item_' + index}
                    text={option.text}
                    icon={option.icon}
                    to={option.to}
                  />
                ))}
              </DropDownMenu>
            </div>
          )}
        </div>
      </li>
    );
  }
  return (
    <li className={style.item}>
      <div>{title}</div>
      <div className={style.options}>
        <div className={style.optionsLg}>
          {optionsArray ? (
            optionsArray.map((option, index) => (
              <Link key={'option_' + index} to={option.to} replace>
                <Button
                  type='transparent'
                  size='xsmall'
                  rounded={'large'}
                  text={option.text}
                  outlined
                  icon={option.icon}
                />
              </Link>
            ))
          ) : (
            <div>{options}</div>
          )}
        </div>
        {optionsArray && (
          <div className={style.optionsSm}>
            <DropDownMenu
              title='Options'
              menuTrigger={
                <Tooltip title='Options'>
                  <div>
                    <Button
                      size='medium'
                      type='transparent'
                      outlined
                      icon={<MoreVert />}
                    />
                  </div>
                </Tooltip>
              }
            >
              {optionsArray.map((option, index) => (
                <DropDownMenuItem
                  key={'option_item_' + index}
                  text={option.text}
                  icon={option.icon}
                  to={option.to}
                />
              ))}
            </DropDownMenu>
          </div>
        )}
      </div>
    </li>
  );
};

CollapsibleItem.propTypes = {
  title: propTypes.string,
  options: propTypes.element,
  optionsArray: propTypes.array,
  isOnlyOne: propTypes.bool
};

export default CollapsibleItem;
