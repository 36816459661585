import axios from 'axios';
import Card from 'components/Card/Card';
import React, { useEffect, useState } from 'react';
import propTypes, { object } from 'prop-types';
import {
  ArrowBack,
  BarChart,
  ChevronRight,
  ListAlt,
  Settings,
  Download,
  InfoSharp
} from '@mui/icons-material';
import style from './AgencyDashboard.module.scss';
import Button from 'components/Button/Button';
import AgentsTable from './AgentsTable/AgentsTable';
import AgentsTasksSection from 'components/TasksSection/AgentsTaskSection';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { CircularProgress, Stack, TextField } from '@mui/material';
import AutocompleteInput from 'components/Autocomplete/AutocompleteInput';
import { options } from 'sanitize-html';

const AgencyDashboard = ({
  agencyData,
  agencyDashboardAgents,
  Confirmed_Appointments_Count,
  setLocalLoader,
  localLoader,
  getSpecificAgencyData,
  downloadAppointmentsReport,
  getSelectedAgenciesAgentsAndAppointmentCount
}) => {
  // Axios
  const cancelTokenSource = axios.CancelToken.source();
  //Hooks
  const { id } = useParams();
  const navigate = useNavigate();
  
  useEffect(() => {
    const getGata = async () => {
      if (id) {
        await getSpecificAgencyData(setLocalLoader, id, cancelTokenSource);
      } else navigate('/agencies');
  
      // refreshUser(() => setLocalLoader(false), cancelTokenSource);
      return () => {
        cancelTokenSource.cancel('API Call Canceled');
      };
    };
    getGata();
  }, []);
  //States
  const [multiselectSelectedOptions, setmultiselectSelectedOptions] = useState(
    []
  );
  const multiselectAvailableOptions = React.useMemo(() => {
    if (agencyData?.agencyInformation.Downline_Agencies_Three) {
      // Sets main agency as default
      setmultiselectSelectedOptions([
        {
          name: agencyData.agencyInformation.name._value,
          value: agencyData.agencyInformation.id._value
        }
      ]);
      // Gets the agents of the default agency into the table
      getSelectedAgenciesAgentsAndAppointmentCount(
        setLocalLoader,
        [agencyData.agencyInformation.id._value],
        cancelTokenSource
      );
      // Returns member agencies as options
      return [
        agencyData.agencyInformation,
        ...agencyData.agencyInformation.Downline_Agencies_Three
      ].map(a => ({
        name: a?.name?._value,
        value: a?.id?._value
      }));
    }
    return [];
  }, [agencyData]);
  const [timesInputClosed, setTimesInputClosed] = useState(0);
  //Action handlers
  const handleChangeSelectValue = selectedOptions => {
    setmultiselectSelectedOptions(selectedOptions);
  };
  const handleUpdateTable = async () => {
    const selected_agencies_ids = multiselectSelectedOptions.map(
      option => option.value
    );
    await getSelectedAgenciesAgentsAndAppointmentCount(
      setLocalLoader,
      selected_agencies_ids,
      cancelTokenSource
    );
    setTimesInputClosed(timesInputClosed + 1);
  };
  const exportConfirmedAppointmentsHandler = () => {
    const selected_ids = multiselectSelectedOptions.map(option => option.value);
    downloadAppointmentsReport(setLocalLoader, selected_ids, cancelTokenSource);
  };
  return (
    <div className={style.dashboard}>
      <div className={style.agenciesItem}>
        <div className={style.memberAgencies}>
          <Link to={'/agencies'}>
            <Button
              text={'Agencies'}
              type={'secondary'}
              outlined
              size={'small'}
              rounded={'large'}
              icon={<ArrowBack />}
            />
          </Link>
          <div className={style.separator}>
            <ChevronRight />
          </div>
          {localLoader ? (
            <CircularProgress size={20} />
          ) : (
            <Button
              // key={`${agency.Member_Agency_ID}`}
              text={agencyData?.agencyInformation?.name?._value || ''}
              type={'default'}
              outlined
              size={'small'}
              rounded={'large'}
              // onClick={() => clckableRef.current.click()}
            />
          )}
        </div>
        <Stack mb={0} marginTop={4}>
          <AutocompleteInput
            insideButton={
              <Button
                text='Update Table'
                type='primaryLight'
                onClick={() => handleUpdateTable()}
              />
            }
            multiple
            // loading={localLoader}
            options={multiselectAvailableOptions}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            // groupBy={option => option.Member_Agency_Name}
            id='agencies'
            value={multiselectSelectedOptions}
            getOptionLabel={option => option.name}
            onValueChanges={e => handleChangeSelectValue(e)}
            disabled={false}
            renderInput={params => (
              <TextField
                {...params}
                label={
                  multiselectSelectedOptions?.length ===
                  agencyData?.agencyInformation.Member_Agencies?.length
                    ? 'All Agencies(s)'
                    : 'Agencies(s)'
                }
                placeholder={'Select agencies'}
                error={false}
                helperText={
                  multiselectSelectedOptions.length > 0 ? (
                    ''
                  ) : (
                    <span style={{ color: 'red' }}>
                      {'You need to select at least 1 agency'}
                    </span>
                  )
                }
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {localLoader ? (
                        <CircularProgress color='inherit' size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  )
                }}
              />
            )}
            timesInputClosed={timesInputClosed}
          />
        </Stack>
      </div>
      <div className={style.infoItem}>
        <Card
          fill
          // adjust
          cardValues
          title={agencyData?.agencyInformation?.name?._value || ''}
          type='primaryDarkened'
          // icon={<Business />}
        >
          <div className={style.cardContent}>
            <span>
              Email:{' '}
              {agencyData.agencyInformation.Agency_Email
                ? agencyData.agencyInformation.Agency_Email
                : '---'}
            </span>
            <Link to={'settings'}>
              <Button
                text='More info'
                type={'default'}
                outlined
                size={'small'}
                rounded={'large'}
                icon={<InfoSharp />}
              />
            </Link>
          </div>
        </Card>
      </div>
      <div className={style.generalItem}>
        <Card
          fill
          type='primaryLight'
          // adjust
          cardValues
          title={'Selected Agents & Agencies'}
        >
          <div className={style.cardContent}>
            <span>Agents: {agencyDashboardAgents.length}</span>
            <span>Agencies: {multiselectSelectedOptions.length || 0}</span>
          </div>
        </Card>
      </div>
      <div className={style.appointmentsItem}>
        <Card
          fill
          //  adjust
          cardValues
          title='Appointments'
          type='primaryNavy'
        >
          {' '}
          <div className={style.appointmentsCard}>
            <div className={style.cardContent}>
              {/* <span>&nbsp;</span> */}
              <span>Confirmed: {Confirmed_Appointments_Count}</span>
              <Button
                // key={`${agency.Member_Agency_ID}`}
                text={'Export Confirmed Appointments'}
                type={'default'}
                outlined
                size={'small'}
                rounded={'large'}
                onClick={() => exportConfirmedAppointmentsHandler()}
                icon={<Download />}
              />
            </div>
          </div>
        </Card>
      </div>
      <div className={style.agentsItem}>
        <Card
          fill
          title='Agents list'
          middleSpace={false}
          titleOptionsSide={'* Updates made every hour'}
          options={
            <Link to={'settings'}>
              <Button
                size='medium'
                rounded={'large'}
                type='secondary'
                text={'Settings/Invites'}
                icon={<Settings />}
              />
            </Link>
          }
          loading={localLoader}
        >
          <div
            className={style.tableContainer}
            id={'agencyDashboardAgentsTableContainer'}
          >
            <AgentsTable agents={agencyDashboardAgents} />
          </div>
        </Card>
      </div>
    </div>
  );
};

AgencyDashboard.propTypes = {
  agencyData: propTypes.object,
  agencyDashboardAgents: propTypes.arrayOf(object),
  Confirmed_Appointments_Count: propTypes.number,
  refreshUser: propTypes.func,
  setLocalLoader: propTypes.func,
  localLoader: propTypes.bool,
  getSpecificAgencyData: propTypes.func,
  downloadAppointmentsReport: propTypes.func,
  getSelectedAgenciesAgentsAndAppointmentCount: propTypes.func,
  getAgenciesTree: propTypes.func
};

export default AgencyDashboard;
