import React from 'react';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import propTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import style from './Accordion.module.scss';
import parse from 'html-react-parser';

const AccordionComponent = ({ ItemsArray, loading }) => {
  const isYouTubeUrl = (url) => {
    return url.includes('youtube.com') || url.includes('youtu.be');
  };

  const getYouTubeId = (url) => {
    const match = url.match(/(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/)|youtu\.be\/|youtube\.com\/embed\/|youtube\.com\/v\/|youtube\.com\/watch\?v=|youtube\.com\/watch\?.+&v=|youtube\.com\/playlist\?list=)([a-zA-Z0-9_-]{11})/);
    return match ? match[1] : null;
  };

  return (
    <div className={style.MainContainer}>
      {loading ? (
        <CircularProgress size={30} color={'inherit'} />
      ) : (
        ItemsArray.filter(item => item.answer).map((item, index) => {

          const videoUrl = item.answerVideo;
          const isYouTube = videoUrl ? isYouTubeUrl(videoUrl) : null;
          const youTubeId = isYouTube ? getYouTubeId(videoUrl) : null;

          return (
            <MuiAccordion key={index} className={style.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <div className={style.question}>
                  {item.question}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className={style.answer}>
                  {parse(item.answer)}
                </div>
                {item.answerImg && (
                  <img
                    src={item.answerImg}
                    className={style.img}
                    alt="Answer related"
                  />
                )}
                {videoUrl ? (
                  isYouTube ? (
                    <iframe
                      width="100%"
                      height="100%"
                      src={`https://www.youtube.com/embed/${youTubeId}`}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  ) : (
                    <video width="100%" height="100%" controls>
                      <source
                        src={videoUrl}
                        type="video/mp4"
                      />
                    </video>
                  )
                ) : null}
              </AccordionDetails>
            </MuiAccordion>
          );
        })
      )}
    </div>
  );
};

AccordionComponent.propTypes = {
  ItemsArray: propTypes.array,
  loading: propTypes.bool,
};

export default AccordionComponent;
