import React from 'react';
import style from './DropDownMenuItem.module.scss';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';

function DropDownMenuItem({
  icon,
  text,
  className,
  active,
  to = '/',
  external,
  ...linkProps
}) {
  return (
    <>
      {external ? (
        <a
          title={text}
          className={`${style.menuItem} ${
            active ? style.active : undefined
          } ${className}`}
          href={to}
          target={'_blank'}
          rel='noreferrer'
          {...linkProps}
        >
          {icon}
          <span>{text}</span>
        </a>
      ) : (
        <Link
          title={text}
          className={`${style.menuItem} ${
            active ? style.active : undefined
          } ${className}`}
          to={to}
          {...linkProps}
        >
          {icon}
          <span>{text}</span>
        </Link>
      )}
    </>
  );
}

DropDownMenuItem.propTypes = {
  icon: propTypes.element,
  text: propTypes.string,
  active: propTypes.bool,
  external: propTypes.bool,
  to: propTypes.string,
  className: propTypes.string
};

export default DropDownMenuItem;
