import { Email } from '@mui/icons-material';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material';
import AutocompleteInput from 'components/Autocomplete/AutocompleteInput';
import Button from 'components/Button/Button';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../AgencySettings.module.scss';
import { SendAgencyFormEmail } from 'state/actions/agencyManagement';
import Tabs from 'components/Tabs/Tabs';
import { useDispatch, useSelector } from 'react-redux';
import { showAlert } from 'state/actions/actions';
import getCompanyVariables from 'helpers/getCompanyVariables';

const SignupAgents = ({ zohoData, Healthsherpa_Agency_Code }) => {
  const company = getCompanyVariables();
  //Misc
  const validateEmail = inputEmail => {
    const emailEmpty = emails.length === 0;
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return !emailEmpty && emailRegex.test(inputEmail);
  };
  const agencyManagement = useSelector(state => state.agencyManagement);
  const [emailTo, setEmailTo] = useState('1');

  const [typeOfForm, setTypeOfForm] = useState('downlineAgent');
  const [typeOfFormToAgents, setTypeOfFormToAgent] = useState('downlineAgent');

  // States for onboarding request
  const [emails, setEmails] = useState('');
  const [onboardingFirstName, setOnboardingFirstName] = useState('');
  const [onboardingLastName, setOnboardingLastName] = useState('');
  const [onboardingNPN, setOnboardingNPN] = useState('');
  // Handlers for oboarding request
  const handleOnboardingEmailChange = data => {
    if (validateEmail(data.target.value)) setOnboardingEmailFieldError(false);
    setEmails(data.target.value);
  };
  const handleOnboardingFirstNameChange = data => {
    setOnboardingFirstNameFieldError(false);
    setOnboardingFirstName(data.target.value);
  };
  const handleOnboardingLastNameChange = data => {
    setOnboardingLastNameFieldError(false);
    setOnboardingLastName(data.target.value);
  };
  const handleOboardingNPNChange = data => {
    setOnboardingNpnFieldError(false);
    setOnboardingNPN(data.target.value);
  };
  const emptyAllBoxes = () => {
    setEmails('');
    setOnboardingFirstName('');
    setOnboardingLastName('');
    setOnboardingNPN('');
  };

  const dispatch = useDispatch();

  const handleChangeEmailTo = ({ target }) => {
    setEmailTo(target.defaultValue);
  };

  const handleSelectFormType = ({ target }) => {
    setTypeOfForm(target.defaultValue);
  };

  const handleSelectFormTypeToAgents = ({ target }) => {
    setTypeOfFormToAgent(target.defaultValue);
  };

  const handleSendOnboardingForm = async (type, emailToType) => {
    // Validates fields, stops if any si empty
    // Sends email
    if (emailToType === '1') {
      await dispatch(SendAgencyFormEmail([zohoData.Email], type));
    } else {
      if (firstNameEmpty) setOnboardingFirstNameFieldError(true);
      if (lastNameEmpty) setOnboardingLastNameFieldError(true);
      if (npnEmpty) setOnboardingNpnFieldError(true);
      if (!validEmail) setOnboardingEmailFieldError(true);
      if (firstNameEmpty || lastNameEmpty || npnEmpty || !validEmail) return;
      const emailExist = agencyManagement.agencyDashboardAgents
        .map(x => x.Email)
        .includes(emails);
      const npnExist = agencyManagement.agencyDashboardAgents
        .map(x => x.NPN)
        .includes(onboardingNPN);
      if (emailExist || npnExist) {
        const msg = `This ${emailExist ? 'Email' : ''}${
          emailExist && npnExist ? ' and ' : ''
        }${npnExist ? 'NPN' : ''} already exists in this agency.`;
        dispatch(showAlert({ message: msg, code: 'error' }));
        return;
      }
      await dispatch(
        SendAgencyFormEmail(
          [emails],
          type,
          onboardingFirstName,
          onboardingLastName,
          onboardingNPN
        )
      );
      emptyAllBoxes();
    }
  };

  // Onboarding form fields behaviour
  const {
    validEmail,
    firstNameEmpty,
    lastNameEmpty,
    npnEmpty,
    onboardingInvitesDisabled
  } = React.useMemo(() => {
    const validEmail = validateEmail(emails);
    const firstNameEmpty = onboardingFirstName.length === 0;
    const lastNameEmpty = onboardingLastName.length === 0;
    const npnEmpty = onboardingNPN.length === 0;
    const isSendingForm = agencyManagement.isSendingOnboardingForm
      ? true
      : false;
    const onboardingInvitesDisabled = isSendingForm;
    return {
      validEmail,
      firstNameEmpty,
      lastNameEmpty,
      npnEmpty,
      onboardingInvitesDisabled
    };
  }, [
    emails,
    onboardingFirstName,
    onboardingLastName,
    onboardingNPN,
    agencyManagement.isSendingOnboardingForm
  ]);

  const [onboardingFirstNameFieldError, setOnboardingFirstNameFieldError] =
    useState(false);
  const [onboardingLastNameFieldError, setOnboardingLastNameFieldError] =
    useState(false);
  const [onboardingNpnFieldError, setOnboardingNpnFieldError] = useState(false);
  const [onboardingEmailFieldError, setOnboardingEmailFieldError] =
    useState(false);

  return (
    <React.Fragment>
      <h4>Step 2: Send Agent Onboarding Emails</h4>
      <FormControl sx={{ width: '100%' }}>
        <Tabs headers={['Email Myself', 'Email Agent']} bottomLine={true}>
          <div className={styles.emailTo}>
            <br />
            {company === 'oneill' ? (
               <>
                <span>Select Type of Onboarding Form:</span>
            <RadioGroup
              row
              name='email-to'
              defaultValue={typeOfForm}
              onChange={handleSelectFormType}
            >
              <FormControlLabel
                value='downlineAgent'
                control={<Radio />}
                label='Downline Agent (Default)'
              />
              <FormControlLabel
                value='downlineAgencyAdmin'
                control={<Radio />}
                label='Downline Agency Admin'
              />
            </RadioGroup>
            </>) : null}
            {typeOfForm === 'downlineAgencyAdmin' && (
              <p className={styles.warning}>
                Warning: Any individual that is provided this link will have
                admin privileges and access to the downline agency that they
                create.
              </p>
            )}
            {/* <p>
              Email yourself a secure link of your {"agency's "}
              <i>Agent Onboarding Form</i>.
            </p> */}
            {company === 'oneill' && !Healthsherpa_Agency_Code && (
              <p className={styles.warning}>
                Warning: You have not provided your Health Sherpa code in the
                field above, so any emails sent directly to agents will only
                provide them with the O&apos;Neill Marketing HS code.
              </p>
            )}
            <br />
            <Button
              disabled={agencyManagement.isSendingOnboardingForm}
              isLoading={agencyManagement.isSendingOnboardingForm}
              type='primaryDarkened'
              rounded={'large'}
              size={'medium'}
              text={'Email me a secure link'}
              icon={<Email />}
              onClick={() => handleSendOnboardingForm(typeOfForm, '1')}
            />
          </div>

          <>
            <div className={styles.emailTo}>
              <p>
                Send an email with the <i>Agent Onboarding Form</i> to one or
                many agents. Type the email(s) and press enter.
              </p>
              {/* <AutocompleteInput
                label={'Select or type agents emails *'}
                placeholder={'Type email'}
                multiple
                freeSolo
                value={emails}
                onValueChanges={handleOnboardingEmailChange}
          /> */}
              <div className={styles.onBoardingInputsContaier}>
                <TextField
                  id='newOboarding_FirstName'
                  label='First Name'
                  variant='outlined'
                  value={onboardingFirstName}
                  onChange={handleOnboardingFirstNameChange}
                  required
                  color={onboardingFirstNameFieldError ? 'error' : null}
                  error={onboardingFirstNameFieldError}
                />

                <TextField
                  id='newOboarding_LastName'
                  label='Last Name'
                  variant='outlined'
                  value={onboardingLastName}
                  onChange={handleOnboardingLastNameChange}
                  required
                  color={onboardingLastNameFieldError ? 'error' : null}
                  error={onboardingLastNameFieldError}
                />

                <TextField
                  id='newOboarding_NPN'
                  label='NPN'
                  variant='outlined'
                  value={onboardingNPN}
                  onChange={handleOboardingNPNChange}
                  required
                  color={onboardingNpnFieldError ? 'error' : null}
                  error={onboardingNpnFieldError}
                />

                <TextField
                  id='newOboarding_Email'
                  label='Email'
                  variant='outlined'
                  value={emails}
                  onChange={handleOnboardingEmailChange}
                  required
                  color={onboardingEmailFieldError ? 'error' : null}
                  error={onboardingEmailFieldError}
                />
              </div>
              {company === 'oneill' && !Healthsherpa_Agency_Code && (
                <p className={styles.warning}>
                  Warning: You have not provided your Health Sherpa code in the
                  field above, so any emails sent directly to agents will only
                  provide them with the O&apos;Neill Marketing HS code.
                </p>
              )}
              {/* <textarea placeholder='Special instructions'></textarea> */}
              <br />
              {company === 'oneill' ? (
               <>
                <span>Select Type of Onboarding Form:</span>
                <RadioGroup
                  row
                  name="email-to"
                  defaultValue={typeOfFormToAgents}
                  onChange={handleSelectFormTypeToAgents}
                >
                  <FormControlLabel
                    value="downlineAgent"
                    control={<Radio />}
                    label="Downline Agent (Default)"
                  />
                  <FormControlLabel
                    value="downlineAgencyAdmin"
                    control={<Radio />}
                    label="Downline Agency Admin"
                  />
                </RadioGroup>
              </>
            ) : null}
              {typeOfFormToAgents === 'downlineAgencyAdmin' && (
                <p className={styles.warning}>
                  Warning: Any individual that is provided this link will have
                  admin privileges and access to the downline agency that they
                  create.
                </p>
              )}
            </div>
            <Button
              fill
              disabled={onboardingInvitesDisabled}
              isLoading={agencyManagement.isSendingOnboardingForm}
              type='primaryDarkened'
              rounded={'large'}
              size={'medium'}
              text={'Email Onboarding form to Agent'}
              icon={<Email />}
              onClick={() => handleSendOnboardingForm(typeOfFormToAgents, '2')}
            />
          </>
        </Tabs>
      </FormControl>
    </React.Fragment>
  );
};

SignupAgents.propTypes = {
  zohoData: PropTypes.object,
  Healthsherpa_Agency_Code: PropTypes.string
};

// react meme SignupAgents that listens to Healthsherpa_Agency_Code
export default React.memo(SignupAgents, (prevProps, nextProps) => {
  return (
    prevProps.Healthsherpa_Agency_Code === nextProps.Healthsherpa_Agency_Code
  );
});

// export default React.memo(SignupAgents);
