import React from 'react';
import style from './menuLink.module.scss';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import icons from 'constants/icons';
import { useDispatch } from 'react-redux';
import { toggleSidebar } from 'state/actions/site';

function MenuLink({
  icon = '',
  text,
  className,
  active,
  to = '/',
  external,
  ...linkProps
}) {
  const Icon = icons[icon] || icons.home;
  const dispatch = useDispatch();

  const handleToggleSideBar = () => {
    if (window.innerWidth <= 768) dispatch(toggleSidebar(false));
    else dispatch(toggleSidebar('toggle'));
  };

  return (
    <>
      {external ? (
        <a
          title={text}
          className={`${style.menuLink} ${
            active ? style.active : undefined
          } ${className}`}
          href={to}
          {...linkProps}
        >
          <Icon />
          <span>{text}</span>
        </a>
      ) : (
        <Link
          title={text}
          className={`${style.menuLink} ${
            active ? style.active : ''
          } ${className}`}
          to={to}
          onClick={handleToggleSideBar}
          {...linkProps}
        >
          <Icon />
          <span>{text}</span>
        </Link>
      )}
    </>
  );
}

MenuLink.propTypes = {
  icon: propTypes.string,
  text: propTypes.string,
  active: propTypes.bool,
  external: propTypes.bool,
  to: propTypes.string,
  className: propTypes.string
};

export default React.memo(MenuLink);
