//AUTHENTICATIONS
export const LOG_IN = 'LOG_IN';
export const LOG_OUT = 'LOG_OUT';
export const IS_LOGGING_IN = 'IS_LOGGING_IN';
//SITE
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const IS_LOADING_GLOBAL = 'IS_LOADING_GLOBAL';
export const IS_LOADED_GLOBAL = 'IS_LOADED_GLOBAL';
export const SHOW_ALERT = 'SHOW_ALERT';
export const SHOW_ALERT_MODAL = 'SHOW_ALERT_MODAL';
export const HIDE_ALERT = 'HIDE_ALERT';
export const HIDE_ALERT_MODAL = 'HIDE_ALERT_MODAL';
export const CLEAN_SITE = 'CLEAN_SITE';
//DASHBOARD
export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';
export const DELETE_NOTE = 'DELETE_NOTE';
export const UPDATE_NOTE = 'UPDATE_NOTE';
export const CREATE_NOTE = 'CREATE_NOTE';
export const UPDATE_ACTION_ITEM = 'UPDATE_ACTION_ITEM';
export const CLEAN_DASHBOARD = 'CLEAN_DASHBOARD';
//USER
export const GET_USER = 'GET_USER';
export const SET_USER_DATA = 'SET_USER_DATA';
export const CLEAN_USER = 'CLEAN_USER';
export const REFRESH_USER = 'REFRESH_USER';
export const IS_SENDING_INFORMATION = 'IS_SENDING_INFORMATION';
//APPOINTMENTS
export const UPDATE_LOADING_CARD_STATE = 'UPDATE_LOADING_CARD_STATE';
export const GET_CARRIERS = 'GET_CARRIERS';
export const SET_CARRIERS_ARRAY = 'SET_CARRIERS_ARRAY';
export const SET_STATEMENTS_CARRIERS_ARRAY = 'SET_STATEMENTS_CARRIERS_ARRAY';
export const GET_APPOINTED_CARRIERS = 'GET_APPOINTED_CARRIERS';
export const GET_REQUESTED_APPOINTMENTS = 'GET_REQUESTED_APPOINTMENTS';
export const CLEAN_APPOINTMENTS = 'CLEAN_APPOINTMENTS';
export const SET_VIEWING_AS = 'SET_VIEWING_AS';
export const RESET_VIEWING_AS = 'RESET_VIEWING_AS';
export const IS_SENDING_REQUEST_APPOINTMENTS =
  'IS_SENDING_REQUEST_APPOINTMENTS';
export const SET_REQUESTED_CARRIER_NAME = 'SET_REQUESTED_CARRIER_NAME';
export const IS_UPDATING_REQUEST_APPOINTMENTS =
  'IS_UPDATING_REQUEST_APPOINTMENTS';
export const GET_STATEMENT_PERIODS = 'GET_STATEMENT_PERIODS';
//MARKETING ANNOUNCEMENTS
export const GET_MARKETING_ANNOUNCEMENTS = 'GET_MARKETING_ANNOUNCEMENTS';
export const CLEAN_MARKETING_ANNOUNCEMENTS = 'CLEAN_MARKETING_ANNOUNCEMENTS';
//COMMISSIONS
export const GET_COMMISSIONS = 'GET_COMMISSIONS';
export const CLEAN_COMMISSIONS = 'CLEAN_COMMISSIONS';
export const IS_DOWNLOADING_REPORT = 'IS_DOWNLOADING_REPORT';
//LEADS
export const GET_LEADS = 'GET_LEADS';
export const UPDATE_LEAD = 'UPDATE_LEAD';
export const CLEAN_LEADS = 'CLEAN_LEADS';
//BONUS
export const GET_BONUS = 'GET_BONUS';
export const CLEAN_BONUS = 'CLEAN_BONUS';
//NOTIFICATIONS
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const CLEAN_NOTIFICATIONS = 'CLEAN_NOTIFICATIONS';
//AGENCY MANAGEMENT
export const SET_AGENCIES_TREE = 'SET_AGENCIES_TREE';
export const SET_AGENCY_DATA = 'SET_AGENCY_DATA';
export const RESET_AGENCY_DATA = 'RESET_AGENCY_DATA';
export const IS_UPDATING_PREFERENCES = 'IS_UPDATING_PREFERENCES';
export const IS_SENDING_ONBOARDING_FORM = 'IS_SENDING_ONBOARDING_FORM';
export const SET_MULTIPLE_AGENCIES_DATA = 'SET_MULTIPLE_AGENCIES_DATA';
export const RESET_MULTIPLE_AGENCIES_DATA = 'RESET_MULTIPLE_AGENCIES_DATA';

export const SET_AGENCY_DASHBOARD_AGENTS_DATA =
  'SET_AGENCY_DASHBOARD_AGENTS_DATA';
export const RESET_AGENCY_DASHBOARD_AGENTS_DATA =
  'RESET_AGENCY_DASHBOARD_AGENTS_DATA';
export const SET_AGENTS_APPOINTMENTS_COUNT = 'SET_AGENTS_APPOINTMENTS_COUNT';
export const SET_COMISSIONS_TAB_AGENTS = 'SET_COMISSIONS_TAB_AGENTS';

//FAQS
export const GET_FAQS = 'GET_FAQS';
//Carrier FAQs
export const GET_CARRIER_FAQS = 'GET_CARRIER_FAQS';
export const CLEAR_CARRIER_FAQS = 'CLEAR_CARRIER_FAQS';
export const SET_CARRIER_FAQS_LOADER = 'SET_CARRIER_FAQS_LOADER';

