//BRAND oneill
import logo from 'assets/images/brand/logo.png';
import fullLogo from 'assets/images/brand/fullLogo.svg';
import fullLogoGrayScale from 'assets/images/brand/fullLogoGrayScale.svg';

//BRAND sunshine
import logo_sunshine from 'assets/images/brand_sunshine/logo.png';
import fullLogo_sunshine from 'assets/images/brand_sunshine/logo.png';
import fullLogoGrayScale_sunshine from 'assets/images/brand_sunshine/logo.png';

//SVG
import agentInfo from 'assets/images/svg/agentInfo.svg';

// Get company variable
import getCompanyVariables from 'helpers/getCompanyVariables';

const company = getCompanyVariables();

let images = {};
if (company === 'oneill') {
  images = {
    brand: {
      logo: logo,
      fullLogo: fullLogo,
      fullLogoGrayScale: fullLogoGrayScale,
      placeHolder: 'https://firebasestorage.googleapis.com/v0/b/o-neill-marketing-portal.appspot.com/o/Employee%20Profile%20Pics%2Fuser-thumbnail(20).png?alt=media&token=a857dcc3-79f8-4783-9a95-ccfd9fcdd709'
    },
    svg: {
      agentInfo: agentInfo
    }
  };
}
if (company === 'sunshine') {
  images = {
    brand: {
      logo: logo_sunshine,
      fullLogo: fullLogo_sunshine,
      fullLogoGrayScale: fullLogoGrayScale_sunshine,
      placeHolder: 'https://firebasestorage.googleapis.com/v0/b/o-neill-marketing-portal.appspot.com/o/Employee%20Profile%20Pics%2Fuser-thumbnail(20).png?alt=media&token=a857dcc3-79f8-4783-9a95-ccfd9fcdd709'
    },
    svg: {
      agentInfo: agentInfo
    }
  };
}

export default images;
