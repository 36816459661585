import { connect } from 'react-redux';
import AgencyDashboard from './AgencyDashboard';
import {
  downloadAppointmentsReport,
  getSelectedAgenciesAgentsAndAppointmentCount,
  getSpecificAgencyData,
  getAgenciesTree,
  refreshUser
} from 'state/actions/actions';
import withLocalLoader from 'components/HOCs/withLocalLoader';

function mapStateToProps({ agencyManagement }) {
  return {
    agencyData: agencyManagement.agencyData,
    agenciesTree: agencyManagement.agenciesTree,
    agencyDashboardAgents: agencyManagement.agencyDashboardAgents,
    Confirmed_Appointments_Count: agencyManagement.Confirmed_Appointments_Count
  };
}

export default connect(mapStateToProps, {
  refreshUser,
  getSpecificAgencyData,
  downloadAppointmentsReport,
  getSelectedAgenciesAgentsAndAppointmentCount,
  getAgenciesTree
})(withLocalLoader(AgencyDashboard, true, true, 'Agency Management'));
