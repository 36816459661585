import React from 'react';
import Sidebar from '../Sidebar/Sidebar';
import propTypes from 'prop-types';
import style from './mainLayout.module.scss';
import PageHeader from '../PageHeader/PageHeader';
import Footer from '../Footer/Footer';

function MainLayout({
  children,
  fullHeader,
  noFooter,
  activePage,
  localLoader
}) {
  return (
    <div className={`${style.mainLayout} ${localLoader ? style.noScroll : ''}`}>
      <Sidebar activePage={activePage} />
      <div
        className={`${style.bodyContainer} ${!fullHeader && style.noHeader} ${
          noFooter && style.noFooter
        }`}
      >
        <PageHeader fullHeader={fullHeader} />
        {!noFooter && <Footer />}
        {children}
      </div>
    </div>
  );
}

MainLayout.propTypes = {
  children: propTypes.array,
  fullHeader: propTypes.bool,
  noFooter: propTypes.bool,
  activePage: propTypes.string,
  localLoader: propTypes.bool
};

export default MainLayout;
