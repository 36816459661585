import React, { useState, useEffect, useRef } from 'react';
import style from './carousel.module.scss';
import propTypes from 'prop-types';
import companyName from '../../constants/companyNames';

function Carousel({ timer = 4000, data = [{}] }) {
  const timeOutRef = useRef(null);
  const [activeItem, setActiveItem] = useState(0);

  useEffect(() => {
    clearTimeout(timeOutRef.current);
    timeOutRef.current = setTimeout(() => {
      buttonsCarousel();
    }, timer);

    return () => clearTimeout(timeOutRef.current);
  }, [activeItem, data]);

  function buttonsCarousel(next = true) {
    if (next) {
      activeItem + 1 === data.length
        ? setActiveItem(0)
        : setActiveItem(prev => prev + 1);
    } else {
      activeItem - 1 < 0
        ? setActiveItem(data.length - 1)
        : setActiveItem(prev => prev - 1);
    }
  }
  return (
    <a
      className={style.carousel}
      href={data[activeItem]?.url}
      target='_blank'
      rel='noreferrer'
    >
      <img
        src={data[activeItem].image}
        alt=''
        style={{ objectFit: data[activeItem].objectFit || 'cover' }}
      />
      <div className={data[activeItem].showFade ? style.gradient : style.whiteText}>
        <span>announcements</span>
        <div className={`${style.texts} ${!data[activeItem].showFade ? style.whiteText : ''}`}>
          <span>{data[activeItem].title}</span>
          <p>{data[activeItem].body}</p>
        </div>
        <div className={style.indicators}>
          {data.map((indicator, i) => (
            <span
              className={i === activeItem ? style.active : undefined}
              key={`${indicator.title}${i}`}
              onClick={() => setActiveItem(i)}
            ></span>
          ))}
        </div>
      </div>
    </a>
  );
}

Carousel.propTypes = {
  timer: propTypes.number,
  data: propTypes.array
};

export default Carousel;
