import React, { useEffect } from 'react';
import withLocalLoader from 'components/HOCs/withLocalLoader';
import propTypes from 'prop-types';

function OMFrame({ className, setLocalLoader }) {


  useEffect(() => {
    setLocalLoader(false);
  }, []);

  return (
<div className={className}>
  <iframe
    loading="lazy"
    src="https://createaclickablemap.com/map.php?id=92927&amp;maplocation=&amp;online=true"
    width="100%"
    height="1000"
    style={{ border: 'none' }}
  ></iframe>
</div>

  );
}

OMFrame.propTypes = {
  className: propTypes.string,
  setLocalLoader: propTypes.func
};

export default withLocalLoader(
    OMFrame,
  true,
  true,
  'OMOfferings',
  'OMFrame'
);
