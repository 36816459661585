import MainLayout from 'components/layout/MainLayout/MainLayout';
import Loader from 'components/Loader/Loader';
import NotificationBar from 'components/NotificationBar/NotificationBar';
import React from 'react';
import { useState } from 'react';

//This component sets the active page in the sidebar
// and fetch the initial data from zoho
function withLocalLoader(
  Component,
  fullHeader,
  noFooter,
  activePage,
  subPage = ''
) {
  const WithLocalLoader = ({ ...componentProps }) => {
    const [localLoader, setLocalLoader] = useState(true);
    return (
      <>
        <MainLayout
          fullHeader={fullHeader}
          noFooter={noFooter}
          activePage={activePage}
          localLoader={localLoader}
        >
          <NotificationBar currentPage={activePage} subPage={subPage} />
          {localLoader && <Loader />}
          <Component
            {...componentProps}
            localLoader={localLoader}
            setLocalLoader={setLocalLoader}
          />
        </MainLayout>
      </>
    );
  };

  return WithLocalLoader;
}

export default withLocalLoader;
