import React, { useState } from 'react';
import SmallArrow from 'components/svg/icons/SmallArrow';
import style from './dropdown.module.scss';
import propTypes from 'prop-types';

function Dropdown({ mainComponent, children, selected }) {
  const [active, setActive] = useState(false);

  return (
    <div
      className={`${style.dropdown} ${active ? style.active : null} ${
        selected ? style.selected : null
      }`}
    >
      <div className={style.header} onClick={() => setActive(prev => !prev)}>
        {mainComponent}
        <SmallArrow />
      </div>
      <div className={style.body}>
        {/* {components.length > 1 &&
          components.map((component, i) => {
            if (i !== 0) return components[i];
          })} */}
        {children}
      </div>
    </div>
  );
}

Dropdown.propTypes = {
  mainComponent: propTypes.element,
  children: propTypes.node,
  selected: propTypes.bool
};

export default Dropdown;
