import React from 'react';
import LoginFormContainer from 'components/LoginFormContainer/LoginFormContainer';
import ChangePasswordForm from './ChangePasswordForm/ChangePasswordForm';
import SecondaryPage from 'components/SecondaryPage/SecondaryPage';

function ChangePassword() {
  return (
    <SecondaryPage>
      <LoginFormContainer>
        <ChangePasswordForm />
      </LoginFormContainer>
    </SecondaryPage>
  );
}

export default ChangePassword;
