import React, { useEffect } from 'react';
import withLocalLoader from 'components/HOCs/withLocalLoader';
import { useSelector } from 'react-redux';
import propTypes from 'prop-types';

function PersonalFolder({ className, setLocalLoader }) {
  const folder = useSelector(
    state => state.user.zohoData.Agent_Folder_Shared_URL
  );

  useEffect(() => {
    setLocalLoader(false);
  }, []);

  return (
    <div className={className}>
      <iframe
        src={folder}
        scrolling='no'
        frameBorder='0'
        allowFullScreen={true}
        width='100%'
      />
    </div>
  );
}

PersonalFolder.propTypes = {
  className: propTypes.string,
  setLocalLoader: propTypes.func
};

export default withLocalLoader(
  PersonalFolder,
  true,
  true,
  'Files',
  'Personal Folder'
);
