import Button from 'components/Button/Button';
import React from 'react';
import style from './leads.module.scss';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { AttachMoney } from '@mui/icons-material';

function TableContainerLeads({ children, purchaseMoreLeads }) {
  const leadsRemaining = useSelector(
    state => state.user.zohoData.remainingLeads
  );
  return (
    <div className={style.tableContainer}>
      <div className={style.notice}>
        <Button
          size='small'
          text='Buy more leads'
          type='primaryDarkened'
          onClick={purchaseMoreLeads}
          icon={<AttachMoney />}
        />
        <p>You have {leadsRemaining} remaining leads</p>
      </div>
      {children}
    </div>
  );
}

TableContainerLeads.propTypes = {
  children: propTypes.element,
  purchaseMoreLeads: propTypes.func
};

export default TableContainerLeads;
