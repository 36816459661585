import React from 'react';
import style from './Statuses.module.scss';

const Statuses = () => {
  return (
    <div className={style.statuses}>
      <div className={style.statesContainer}>
      <div className={style.rows}>
        <span>States:</span>
        <div className={style.status}>
          <div className={style.confirmed}></div>
          <span>Confirmed</span>
        </div>
        <div className={style.status}>
          <div className={style.pending}></div>
          <span>Pending</span>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Statuses;
