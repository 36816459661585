import React, { useEffect } from 'react';
import withLocalLoader from 'components/HOCs/withLocalLoader';
import propTypes from 'prop-types';

function ACAChart2024Frame({ className, setLocalLoader }) {


  useEffect(() => {
    setLocalLoader(false);
  }, []);

  return (
    <div className={className}>
      <iframe
        src={'https://www.flipbookpdf.net/web/site/27c87daa4504d0140599e3592a63ede30df9b633FBP22323004.pdf.html'}
        scrolling='no'
        frameBorder='0'
        allowFullScreen={true}
        width='100%'
      />
    </div>
  );
}

ACAChart2024Frame.propTypes = {
  className: propTypes.string,
  setLocalLoader: propTypes.func
};

export default withLocalLoader(
  ACAChart2024Frame,
  true,
  true,
  'ACA2024',
  'ACAChart2024Frame'
);
