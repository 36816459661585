import React from 'react';
import PropTypes from 'prop-types';
import style from './DateComponent.module.scss';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import parse from 'html-react-parser';

function DateComponent({ title, description, endDate, url }) {
  const dateObject = new Date(endDate);
  const date = dateObject.toLocaleDateString('en-US');
  const time = dateObject.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });

  let parsedUrl = null; // Inicializamos parsedUrl

  if (url && url.trim()) {
    parsedUrl = parse(url); // Asignamos valor si url es válida
  }

  return (
    <div className={style.dateComponent}>
      <div className={style.header}>
        <CircleRoundedIcon className={style.icon} />
        <div className={style.title}>{title}</div>
      </div>
      {/*<div className={style.datetime}>
          <span>{date}</span> <span>{time}</span>
        </div>*/}
      <div className={style.description}>{description}</div>
      {parsedUrl && <div className={style.URL}>{parsedUrl}</div>}
    </div>
  );
}

DateComponent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.any,
  endDate: PropTypes.string
};

export default DateComponent;
