import endpoints from 'constants/endpoints';
import { CREATE_NOTE, DELETE_NOTE, UPDATE_NOTE } from 'constants/types';
import { safePostCall } from 'helpers/firebaseSetup';
import { siteLoaded, siteLoading } from './site';

export const updatePortalNote = (closeModal, note) => dispatch => {
  dispatch(siteLoading());
  safePostCall(endpoints.awsProxy.safePost, {
    ...note,
    create: false,
    delete: false,
    endpoint: endpoints.zoho.updatePortalNote
  })
    .then(() => {
      //Refresh redux state with the updated note
      dispatch({ type: UPDATE_NOTE, payload: note });
      closeModal();
      dispatch(siteLoaded());
    })
    .catch(error => {
      console.log(error);
      dispatch(siteLoaded());
    });
};

export const createPortalNote =
  (closeModal, noteData) => (dispatch, getState) => {
    dispatch(siteLoading());
    const userId = getState().user.zohoId;
    safePostCall(endpoints.awsProxy.safePost, {
      ...noteData,
      create: true,
      delete: false,
      userId,
      endpoint: endpoints.zoho.updatePortalNote
    }).then(noteResponse => {
      //Gets and Sets the id of the newly created note
      noteResponse = JSON.parse(noteResponse.data.details.output);
      dispatch({
        type: CREATE_NOTE,
        payload: { ...noteData, id: noteResponse.id }
      });
      closeModal();
      dispatch(siteLoaded());
    });
  };

export const deletePortalNote = (closeModal, id) => dispatch => {
  dispatch(siteLoading());
  safePostCall(endpoints.awsProxy.safePost, {
    id,
    create: false,
    delete: true,
    endpoint: endpoints.zoho.updatePortalNote
  }).then(() => {
    dispatch({ type: DELETE_NOTE, payload: id });
    closeModal();
    dispatch(siteLoaded());
  });
};
