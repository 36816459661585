import { connect } from 'react-redux';
import AgentInfoView from './AgentInfoView';
import { refreshUser, showAlert } from 'state/actions/actions';
import withLocalLoader from 'components/HOCs/withLocalLoader';
import { changeUserProfilePic } from 'state/actions/actions';

const mapStateToProps = ({ user }) => ({
  zohoData: user.zohoData
});

export default connect(mapStateToProps, {
  refreshUser,
  showAlert,
  changeUserProfilePic
})(withLocalLoader(AgentInfoView, true, true, 'Agent Info'));
