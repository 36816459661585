import React, { useState } from 'react';
//HELPERS
import style from './pageHeader.module.scss';
import { LogOut, resetViewingAsAgent } from 'state/actions/actions';
//COMPONENTS
import MenuLink from '../Sidebar/MenuLink/MenuLink';
import icons from 'constants/icons';
//EXTERNAL
import { useDispatch } from 'react-redux';
import propTypes from 'prop-types';
import OutsideAlerter from 'hooks/useClickOutside';
import images from 'constants/images';
import { useSelector } from 'react-redux';
import { ArrowDropDown, Visibility, VisibilityOff } from '@mui/icons-material';
import ToggleSideBar from '../ToggleSideBar/ToggleSideBar';
import Button from 'components/Button/Button';
import companyName from 'constants/companyNames';

function PageHeader({ fullHeader }) {
  const dispatch = useDispatch();
  const [dropdown, setDropdown] = useState(false);
  const zohoData = useSelector(state => state.user.zohoData);
  const userState = useSelector(state => state.user);
  const Icon = icons['question'];

  const handleExitViewAsAgentMode = () => {
    dispatch(resetViewingAsAgent(userState.mainZohoId));
    location.href = '/';
  };
  return (
    <div
      className={`${style.pageHeader} ${fullHeader && style.fullHeader} ${
        dropdown && style.active
      }`}
    >
      <div className={style.welcomLabel}>
        <div className={style.toggleSideBar}>
          <ToggleSideBar />
        </div>
        <div>
          {userState.viewAsAgent ? (
            <span className={style.viewAsAgent}>
              <Visibility /> {'Viewing as agent'}
            </span>
          ) : (
            <>
              <span>{companyName.fullName} Portal</span>
              <br />
            </>
          )}

          {userState.viewAsAgent ? (
            <span
              className={style.viewAsAgentName}
            >{`Agent: ${zohoData.First_Name} ${zohoData.Last_Name}`}</span>
          ) : (
            <span>Hi {zohoData.First_Name}, welcome back!</span>
          )}
        </div>
      </div>
      {userState.viewAsAgent ? (
        <Button
          text={'Leave'}
          size={'xsmall'}
          type={'danger'}
          outlined
          icon={<VisibilityOff />}
          onClick={handleExitViewAsAgentMode}
        />
      ) : (
        <div
          className={style.menuContainer}
          onClick={() => setDropdown(prev => !prev)}
        >
          {/*<a
            href={'https://oneillmarketing.net/frequently-asked-questions-faq/'}
            rel={'noreferrer'}
            target={'_blank'}
            style={{
              width: '30px',
              height: '20px',
              marginRight: '0px',
              color: '#99999A',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            FAQ
             <Icon />
          </a>*/}
          <img src={zohoData.agentPhoto || images.brand.logo} alt='' />
          <div className={style.nameContainer}>
            <span className={style.name}>{zohoData.First_Name}</span>
            <span className={style.permissions}>
              {zohoData.Position_at_Agency
                ? zohoData.Position_at_Agency
                : 'Agent'}
            </span>
          </div>
          <ArrowDropDown fontSize='small' />
          <OutsideAlerter onClick={() => setDropdown(false)}>
            <div className={style.dropdownMenu}>
              <MenuLink
                icon='agentInfo'
                text='My account'
                className={style.dropdownLink}
                to='/agentInfo'
              />
              <MenuLink
                icon='resetPassword'
                text='Change password'
                className={style.dropdownLink}
                to='/changePassword'
              />
              {/* <MenuLink
              icon='info'
              text='Help & Support'
              className={style.dropdownLink}
            /> */}
              <MenuLink
                icon='logOut'
                text='Logout'
                className={style.dropdownLink}
                onClick={() => dispatch(LogOut())}
              />
            </div>
          </OutsideAlerter>
        </div>
      )}
    </div>
  );
}

PageHeader.propTypes = {
  fullHeader: propTypes.bool
};

export default PageHeader;
