import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/Card/Card';
import Button from 'components/Button/Button';
import style from './ownerCard.module.scss';
import images from 'constants/images';
import { companyRoles } from 'constants/enum';
import getCompanyVariables from 'helpers/getCompanyVariables';


function OwnerCard({ owner = {}, loading, user = {}, generalBookingUrl }) {
  const company = getCompanyVariables();
  const [isEmailLong, setIsEmailLong] = useState(false);
  const emailRef = useRef(null);

  useEffect(() => {
    const emailLengthThreshold = 30;
    if (owner.Email) {
      const isLong = owner.Email.length >= emailLengthThreshold;
      setIsEmailLong(isLong);
    }
  }, [owner]);
  

  const generalOwner = !owner
    ? false
    : owner?.id === companyRoles.superAdminId ||
      owner?.role !== companyRoles.internalAgentConsultant;

  const handleOpenUrl = url => {
    window.open(url, '_blank');
  };

  return (
    <Card
      fill
      title={'Agent/Agency Consultant'}
      type={'default'}
      withHr={true}
      loading={loading}
    >
      <div className={style.img}>
        <img
          src={generalOwner ? images.brand.placeHolder : owner.imgURL}
          placeholder={images.brand.placeHolder}
        ></img>
        {generalOwner ? (
          <span>{companyRoles.name}</span>
        ) : (
          <span>{owner.Name}</span>
        )}
        {generalOwner ? (
          <span>{companyRoles.phone}</span>
        ) : owner.Phone ? (
          <span>{owner.Phone}</span>
        ) : (
          <span>No phone available</span>
        )}
        {generalOwner ? (
          <span className={`${style.email} ${isEmailLong ? style.longEmail : ''}`} >{companyRoles.email}</span>
        ) : owner.Email ? (
          <span ref={emailRef} className={`${style.email} ${isEmailLong ? style.longEmail : ''}`}>
            {owner.Email}
          </span>
        ) : (
          <span>No email available</span>
        )}
        {company === 'oneill'?
        <Button
          text="Schedule a Meeting"
          size="medium"
          type="primaryDarkened"
          className={isEmailLong ? style.longEmailButton : ''}
          onClick={() =>
            handleOpenUrl(`${generalOwner ? generalBookingUrl : owner.zohoBooking}`)
          }
        />:null}
      </div>
    </Card>
  );
}

OwnerCard.propTypes = {
  owner: PropTypes.object,
  loading: PropTypes.bool,
  user: PropTypes.object,
  generalBookingUrl: PropTypes.string,
};

export default OwnerCard;
