import React from 'react';
import Routes from 'routes/Routes';
import 'styles/main.scss';
import { Provider } from 'react-redux';
import store from 'state/store/store';
import Alert from 'components/Alert/Alert';
import createFirebaseApp from 'helpers/firebaseSetup';
import enableViewingAsAgent from 'helpers/enableViewingAsAgent';
import AlertModal from 'components/AlertModal/AlertModal';

import { ThemeProvider, createMuiTheme } from '@mui/material/styles';
// import { makeStyles } from '@mui/styles';

// const theme = createMuiTheme();

// const useStyles = makeStyles((theme) => {
//   root: {
//     // some CSS that accesses the theme
//   }
// });

function App() {
  createFirebaseApp();
  enableViewingAsAgent();

  return (
    <Provider store={store}>
      <Routes />
      <Alert />
      <AlertModal />
    </Provider>
  );
}

export default App;
