import React from 'react';
import style from './input.module.scss';
import propTypes from 'prop-types';

const Input = React.forwardRef(function InputWithRef(
  { label, error, className, lightLabel, inLine, noSpacing,fixedWidth, ...inputProps },
  ref
) {
  return (
      <div
        className={`${style.inputContainer} ${error && style.error} ${
          lightLabel && style.lightLabel
        } ${inLine && style.inLine} ${noSpacing && style.noSpacing} ${fixedWidth && style.fixedWidth} ${className}`}
      >
      <p>{label}</p>
      <input {...inputProps} ref={ref} />
      <span>{error}</span>
    </div>
    // <TextField
    //   ref={ref}
    //   error={error ? true : false}
    //   id='outlined-error-helper-text'
    //   label={label}
    //   defaultValue={inputProps.value}
    //   helperText={error}
    //   {...inputProps}
    // />
  );
});

Input.propTypes = {
  label: propTypes.string,
  error: propTypes.string,
  className: propTypes.string,
  lightLabel: propTypes.bool,
  inLine: propTypes.bool,
  noSpacing: propTypes.bool,
  fixedWidth:propTypes.bool
};

export default Input;
